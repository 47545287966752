import React from 'react'

import { Icons, IconTypes } from './Icon.helpers'

export type IconProps = {
  name: IconTypes
}

const Icon = ({ name }: IconProps) => {
  const SelectedIcon = Icons[name]
  return <SelectedIcon />
}

export default Icon
