import { Resolvers } from '@apollo/client'

import { postCheckBrowserId } from '../api'

const BrowserIDResolvers: Resolvers = {
  Mutation: {
    postBrowserId: async (_, { useClientApi }) => {
      await postCheckBrowserId(useClientApi)
    },
  },
}

export default BrowserIDResolvers
