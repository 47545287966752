import messages from '../intl/messagesAlerts.ts'
import { Locales } from '../types'
import { FormatErrorOpts } from './errorHandler.types'

export const errorMessages = messages[Locales.GB].alerts

export const formatErrorMessage = (message: string, options: FormatErrorOpts) => {
  const { id, statusCode, description } = options

  id && (message = message.replace('{errorCode}', id))
  statusCode && (message = message.replace('{statusCode}', statusCode))
  description && (message = message.replace('{description}', description.trim()))

  return message
}
