import { createContext, ReactNode, useContext, useRef } from 'react'
import { useLocation } from 'react-router-dom'

export type NavigationContextType = {
  entryRoute?: string
}

export const NavigationContext = createContext<NavigationContextType>({})
export const NavigationContextConsumer = NavigationContext.Consumer

export const useLocationStateRef = () => {
  const { state } = useLocation()
  // store location state in a ref so that it store the value when a client first
  // enters a page or flow, and navigating within a flow will not change the ref
  const locationStateRef = useRef<NavigationContextType | null>(state)

  return locationStateRef
}

export const NavigationContextProvider = ({ children }: { children: ReactNode }) => {
  const locationStateRef = useLocationStateRef()

  return (
    <NavigationContext.Provider value={{ entryRoute: locationStateRef.current?.entryRoute }}>
      {children}
    </NavigationContext.Provider>
  )
}

export const useNavigationContext = () => useContext<NavigationContextType>(NavigationContext)
