import { GraphQLError } from 'graphql'
import { isEmpty } from 'lodash'

import {
  createLedgerPayment,
  createRecurringPayment,
  deleteLedger,
  editLedgerPayment,
  fetchClient,
  fetchClients,
  getGoCardlessAccountStatus,
  getGoCardlessAuthCallback,
  getGoCardlessAuthURL,
  getLedgerFilters,
  getLedgers,
  getLedgersByCaseId,
  hasGoCardlessMandate,
  initiateGoCardlessPayment,
  OrganisationLoader,
  PropertyLoader,
  updateLedger,
  updatePayment,
  updateRecurringLedgerPayment,
  UserLoader,
  writeOffLedger,
} from '../api'
import { LedgerSourceHydrationType, Organisation, Resolvers, User } from '../generated/resolvers'

const AccountingResolver: Resolvers = {
  Query: {
    getLedgersByCaseId: (_, input) => getLedgersByCaseId(input),
    getLedgerFilters: (_, input) => getLedgerFilters(input),
    getLedgers: (_, input) => getLedgers(input),
    getGoCardlessAuthURL: () => getGoCardlessAuthURL(),
    getGoCardlessAccountStatus: () => getGoCardlessAccountStatus(),
    hasGoCardlessMandate: (_, input) => hasGoCardlessMandate(input),
  },
  Mutation: {
    editLedgerPayment: (_, input) => editLedgerPayment(input),
    createLedgerPayment: (_, input) => createLedgerPayment(input),
    updateRecurringLedgerPayment: (_, input) => updateRecurringLedgerPayment(input),
    updateLedgerPayment: (_, input) => updateLedger(input),
    writeOffLedger: (_, input) => writeOffLedger(input),
    createRecurringPayment: (_, input) => createRecurringPayment(input),
    deleteLedger: (_, { id }) => deleteLedger(id),
    updatePayment: (_, input) => updatePayment(input),
    initiateGoCardlessPayment: (_, input) => initiateGoCardlessPayment(input),
    goCardlessAuthCallback: (_, input) => getGoCardlessAuthCallback(input),
  },
  LedgerFilters: {
    advisors: async ({ advisorIds }) => {
      if (!advisorIds) {
        return null
      }

      const result = await UserLoader.loadMany(advisorIds.map((advisor) => advisor.id))

      const users = result.reduce((acc, curr) => {
        if (curr instanceof GraphQLError) {
          console.error(curr)
        } else {
          return [...acc, curr]
        }

        return acc
      }, [] as User[])

      return users
    },
    clubs: async ({ clubIds }) => {
      if (!clubIds) {
        return null
      }

      const result = await OrganisationLoader.loadMany(clubIds.map((club) => club.id))

      const organisations = result.reduce((acc, curr) => {
        if (curr instanceof GraphQLError) {
          console.error(curr)
        } else {
          return [...acc, curr]
        }

        return acc
      }, [] as Organisation[])

      return organisations
    },
  },
  Ledger: {
    advisor: async ({ advisorId }) => {
      if (advisorId) {
        const user = await UserLoader.load(advisorId)

        if (user instanceof GraphQLError) {
          console.error(user)
        } else {
          return user
        }
      }

      return null
    },
    clients: async ({ clientIds }) => {
      if (clientIds) {
        return (await fetchClients({ client_ids: clientIds })) ?? null
      }

      return null
    },
    club: async ({ clubId }) => {
      if (!clubId) {
        return null
      }

      const club = await OrganisationLoader.load(clubId)

      if (club instanceof GraphQLError) {
        throw club
      }

      return club
    },
    property: async ({ propertyId }) => {
      const property = propertyId ? await PropertyLoader.load(propertyId) : null

      if (property instanceof GraphQLError) {
        throw property
      }

      return property || null
    },
  },
  OneOffLedger: {
    clients: async ({ clientIds }) => {
      if (clientIds) {
        return (await fetchClients({ client_ids: clientIds })) ?? null
      }

      return null
    },
  },
  RecurringLedger: {
    clients: async ({ clientIds }) => {
      if (clientIds) {
        return (await fetchClients({ client_ids: clientIds })) ?? null
      }

      return null
    },
  },
  LedgerPaymentSourceId: {
    details: async ({ id, hydrationType }) => {
      if (isEmpty(id)) {
        return null
      }

      if (hydrationType === LedgerSourceHydrationType.Client) {
        const client = await fetchClient({ client_ids: [id] })
        return client ? { __typename: 'ClientVersion', ...client } : null
      }

      if (hydrationType === LedgerSourceHydrationType.User) {
        const user = await UserLoader.load(id)
        if (user instanceof GraphQLError) {
          throw user
        }

        return user ? { __typename: 'User', ...user } : null
      }

      if (hydrationType === LedgerSourceHydrationType.Organisation) {
        const organisation = await OrganisationLoader.load(id)

        if (organisation instanceof GraphQLError) {
          throw organisation
        }

        return organisation ? { __typename: 'Organisation', ...organisation } : null
      }

      return null
    },
  },
  LedgerPaymentFiltersSourceId: {
    details: async ({ id, hydrationType }) => {
      if (isEmpty(id)) {
        return null
      }

      if (hydrationType === LedgerSourceHydrationType.User) {
        const user = await UserLoader.load(id)

        if (user instanceof GraphQLError) {
          throw user
        }

        return { __typename: 'User', ...user }
      }

      if (hydrationType === LedgerSourceHydrationType.Organisation) {
        const organisation = await OrganisationLoader.load(id)

        if (organisation instanceof GraphQLError) {
          throw organisation
        }

        return { __typename: 'Organisation', ...organisation }
      }

      return null
    },
  },
}

export default AccountingResolver
