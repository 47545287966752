import { Box, styled } from '@mui/material'

import { caseSidebarDrawWidth } from '../../theme/theme.mui'
import { PageLayoutProps } from '.'

export const PageLayoutRoot = styled(Box, {
  name: 'PageLayout',
  slot: 'Root',
  overridesResolver: (_props, styles) => styles.root,
  shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'rightDrawerOpen' && prop !== 'shouldTransition',
})<PageLayoutProps & { shouldTransition?: boolean; rightDrawerOpen?: boolean }>(({
  theme,
  variant = 'one-column',
  rightDrawerOpen,
  shouldTransition = true,
}) => {
  const sidebarWidth = theme.mixins?.sidebar?.width ? theme.mixins?.sidebar?.width.toString() : '240px'
  let width = '100%'

  if (variant === 'two-column') {
    width = `calc(100% - ${sidebarWidth})`
  } else if (variant === 'two-column-with-sidebar') {
    width = `calc(100% - ${sidebarWidth} - 30px)`
  }

  return {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: variant === 'two-column' || variant === 'two-column-with-sidebar' ? sidebarWidth : 'auto',
    flex: 1,
    width,
    maxHeight: '100vh',
    overflowY: 'scroll',
    ...(shouldTransition
      ? {
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }
      : {}),
    [theme.breakpoints.up(1450)]: {
      ...(variant === 'two-column-with-sidebar' && {
        width: `calc(100% - ${sidebarWidth} - ${rightDrawerOpen ? `${caseSidebarDrawWidth}px` : '30px'})`,
      }),
    },
  }
})
