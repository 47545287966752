import { getCode, getName, getNames, overwrite } from 'country-list'

export const UK = { code: 'GB', name: 'United Kingdom' }

const Kosovo = { code: 'XK', name: 'Kosovo' }

overwrite([UK])

const countryNames = getNames().sort((a, b) => a.localeCompare(b))

export const getCountryNames = () => countryNames.concat(Kosovo.name).sort()

export const getCountryCodeForName = (name: string) => {
  if (name === Kosovo.name) return Kosovo.code
  return getCode(name)
}

export const getCountryName = (code: string) => {
  if (code === Kosovo.code) return Kosovo.name
  return getName(code)
}

export const getCountryCodeForDemonym = (name: string): string => {
  if (nationalities.has(name.toLowerCase())) {
    return <string>nationalities.get(name.toLowerCase())
  }
  return ''
}

export const getCountryCodeFromNameOrDemonym = (name: string) => {
  const nameString = Object.values(name).join('').toString()
  let out: string | undefined = getCountryCodeForDemonym(nameString)

  if (out == '') {
    out = getCountryCodeForName(nameString)
  }
  if (!out) {
    return nameString
  }
  return out
}

const nationalities = new Map(
  Object.entries({
    english: 'GB',
    welsh: 'GB',
    scottish: 'GB',
    'northern irish': 'GB',
    aruban: 'AW',
    afghan: 'AF',
    angolan: 'AO',
    anguillian: 'AI',
    ålandish: 'AX',
    albanian: 'AL',
    andorran: 'AD',
    emirati: 'AE',
    argentine: 'AR',
    armenian: 'AM',
    'american samoan': 'AS',
    antarctican: 'AQ',
    french: 'FR',
    antiguan: 'AG',
    barbudan: 'AG',
    australian: 'AU',
    austrian: 'AT',
    azerbaijani: 'AZ',
    burundian: 'BI',
    belgian: 'BE',
    beninese: 'BJ',
    burkinabe: 'BF',
    bangladeshi: 'BD',
    bulgarian: 'BG',
    bahraini: 'BH',
    bahamian: 'BS',
    bosnian: 'BA',
    herzegovinian: 'BA',
    'saint barthélemy islander': 'BL',
    'saint helenian': 'SH',
    belarusian: 'BY',
    belizean: 'BZ',
    bermudian: 'BM',
    bolivian: 'BO',
    dutch: 'NL',
    brazilian: 'BR',
    barbadian: 'BB',
    bruneian: 'BN',
    bhutanese: 'BT',
    motswana: 'BW',
    'central african': 'CF',
    canadian: 'CA',
    'cocos islander': 'CC',
    swiss: 'CH',
    chilean: 'CL',
    chinese: 'CN',
    ivorian: 'CI',
    cameroonian: 'CM',
    congolese: 'CG',
    'cook islander': 'CK',
    colombian: 'CO',
    comoran: 'KM',
    'cape verdian': 'CV',
    'costa rican': 'CR',
    cuban: 'CU',
    curaçaoan: 'CW',
    'christmas islander': 'CX',
    caymanian: 'KY',
    cypriot: 'CY',
    czech: 'CZ',
    german: 'DE',
    djibouti: 'DJ',
    dominican: 'DO',
    danish: 'DK',
    algerian: 'DZ',
    ecuadorean: 'EC',
    egyptian: 'EG',
    eritrean: 'ER',
    sahrawi: 'EH',
    spanish: 'ES',
    estonian: 'EE',
    ethiopian: 'ET',
    finnish: 'FI',
    fijian: 'FJ',
    'falkland islander': 'FK',
    faroese: 'FO',
    micronesian: 'FM',
    gabonese: 'GA',
    british: 'GB',
    georgian: 'GE',
    'channel islander': 'JE',
    ghanaian: 'GH',
    gibraltar: 'GI',
    guinean: 'GN',
    guadeloupian: 'GP',
    gambian: 'GM',
    'guinea-bissauan': 'GW',
    'equatorial guinean': 'GQ',
    greek: 'GR',
    grenadian: 'GD',
    greenlandic: 'GL',
    guatemalan: 'GT',
    guianan: 'GF',
    guamanian: 'GU',
    guyanese: 'GY',
    'hong konger': 'HK',
    'heard and mcdonald islander': 'HM',
    honduran: 'HN',
    croatian: 'HR',
    haitian: 'HT',
    hungarian: 'HU',
    indonesian: 'ID',
    manx: 'IM',
    indian: 'IO',
    irish: 'IE',
    iranian: 'IR',
    iraqi: 'IQ',
    icelander: 'IS',
    israeli: 'IL',
    italian: 'IT',
    jamaican: 'JM',
    jordanian: 'JO',
    japanese: 'JP',
    kazakhstani: 'KZ',
    kenyan: 'KE',
    kirghiz: 'KG',
    cambodian: 'KH',
    'i-kiribati': 'KI',
    'kittitian or nevisian': 'KN',
    'south korean': 'KR',
    kosovar: 'XK',
    kuwaiti: 'KW',
    laotian: 'LA',
    lebanese: 'LB',
    liberian: 'LR',
    libyan: 'LY',
    'saint lucian': 'LC',
    liechtensteiner: 'LI',
    'sri lankan': 'LK',
    mosotho: 'LS',
    lithuanian: 'LT',
    luxembourger: 'LU',
    latvian: 'LV',
    macanese: 'MO',
    'saint martin islander': 'MF',
    moroccan: 'MA',
    monegasque: 'MC',
    moldovan: 'MD',
    malagasy: 'MG',
    maldivan: 'MV',
    mexican: 'MX',
    marshallese: 'MH',
    macedonian: 'MK',
    malian: 'ML',
    maltese: 'MT',
    burmese: 'MM',
    montenegrin: 'ME',
    mongolian: 'MN',
    american: 'US',
    mozambican: 'MZ',
    mauritanian: 'MR',
    montserratian: 'MS',
    martinican: 'MQ',
    mauritian: 'MU',
    malawian: 'MW',
    malaysian: 'MY',
    mahoran: 'YT',
    namibian: 'NA',
    'new caledonian': 'NC',
    nigerien: 'NE',
    'norfolk islander': 'NF',
    nigerian: 'NG',
    nicaraguan: 'NI',
    niuean: 'NU',
    norwegian: 'SJ',
    nepalese: 'NP',
    nauruan: 'NR',
    'new zealander': 'NZ',
    omani: 'OM',
    pakistani: 'PK',
    panamanian: 'PA',
    'pitcairn islander': 'PN',
    peruvian: 'PE',
    filipino: 'PH',
    palauan: 'PW',
    'papua new guinean': 'PG',
    polish: 'PL',
    'puerto rican': 'PR',
    'north korean': 'KP',
    portuguese: 'PT',
    paraguayan: 'PY',
    palestinian: 'PS',
    'french polynesian': 'PF',
    qatari: 'QA',
    réunionese: 'RE',
    romanian: 'RO',
    russian: 'RU',
    rwandan: 'RW',
    'saudi arabian': 'SA',
    sudanese: 'SD',
    senegalese: 'SN',
    singaporean: 'SG',
    'south georgian south sandwich islander': 'GS',
    'solomon islander': 'SB',
    'sierra leonean': 'SL',
    salvadoran: 'SV',
    sammarinese: 'SM',
    somali: 'SO',
    'saint-pierrais': 'PM',
    miquelonnais: 'PM',
    serbian: 'RS',
    'south sudanese': 'SS',
    'sao tomean': 'ST',
    surinamer: 'SR',
    slovak: 'SK',
    slovene: 'SI',
    swedish: 'SE',
    swazi: 'SZ',
    'st. maartener': 'SX',
    seychellois: 'SC',
    syrian: 'SY',
    'turks and caicos islander': 'TC',
    chadian: 'TD',
    togolese: 'TG',
    thai: 'TH',
    tadzhik: 'TJ',
    tokelauan: 'TK',
    turkmen: 'TM',
    'east timorese': 'TL',
    tongan: 'TO',
    trinidadian: 'TT',
    tunisian: 'TN',
    turkish: 'TR',
    tuvaluan: 'TV',
    taiwanese: 'TW',
    tanzanian: 'TZ',
    ugandan: 'UG',
    ukrainian: 'UA',
    'american islander': 'UM',
    uruguayan: 'UY',
    uzbekistani: 'UZ',
    vatican: 'VA',
    'saint vincentian': 'VC',
    venezuelan: 'VE',
    'virgin islander': 'VI',
    vietnamese: 'VN',
    'ni-vanuatu': 'VU',
    'wallis and futuna islander': 'WF',
    samoan: 'WS',
    yemeni: 'YE',
    'south african': 'ZA',
    zambian: 'ZM',
    zimbabwean: 'ZW',
  }),
)
