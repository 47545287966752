import { tagsDiscovery } from '../api/tags'
import { Resolvers } from '../generated/resolvers'

const Tag: Resolvers = {
  Query: {
    tagsDiscovery,
  },
}

export default Tag
