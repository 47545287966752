import { AxiosError, AxiosResponse } from 'axios'
import DataLoader from 'dataloader'

import { ProtectionProduct, ProtectionProductUpdateInput } from '../generated/resolvers'
import request from '../requesters/default'
import {
  CdmAddCaseProtectionProductRequest,
  CdmAddCaseProtectionProductResponse,
  CdmCreateProtectionProductRequest,
  CdmCreateProtectionProductResponse,
  CdmDeleteCaseProtectionProductResponse,
  CdmGetProtectionProductRequest,
  CdmGetProtectionProductResponse,
  CdmGetProtectionProductVersionResponse,
  CdmUpdateProtectionProductResponse,
} from '../service/luther/model'
import { createBatchLoaderFn } from '../utils/dataloader'

// GetProtectionProduct
// https://crm.dev.acreplatforms.co.uk/api_documentation/redoc.html#operation/GetProtectionProduct
export const getProtectionProduct = async (params?: CdmGetProtectionProductRequest) => {
  const response = await request.get<CdmGetProtectionProductResponse>('/protection_product', { params })
  return response?.data
}

// CreateProtectionProduct
// https://crm.dev.acreplatforms.co.uk/api_documentation/redoc.html#operation/CreateProtectionProduct
export const createProtectionProduct = async (body: CdmCreateProtectionProductRequest) => {
  const response = await request.post<CdmCreateProtectionProductResponse>('/protection_product', body)

  if (response?.data && body.protection_product.protection_id) {
    protectionVersionLoader.clear({
      protection_id: body.protection_product.protection_id,
      version: body.protection_product.version,
    })
  }
  return response?.data
}

// AddCaseProtectionProduct
// https://crm.dev.acreplatforms.co.uk/api_documentation/redoc.html#operation/AddCaseProtectionProduct
export const addCaseProtectionProduct = async (protectionId: string, body: CdmAddCaseProtectionProductRequest) => {
  const response = await request.post<CdmAddCaseProtectionProductResponse>(
    `/protection_product/${protectionId}/case`,
    body,
  )
  if (response?.data && protectionId) {
    protectionVersionLoader.clear({
      protection_id: protectionId,
    })
  }

  return response?.data
}

// DeleteCaseProtectionProduct
// https://crm.dev.acreplatforms.co.uk/api_documentation/redoc.html#operation/DeleteCaseProtectionProduct
export const deleteCaseProtectionProduct = async (protectionId: string, caseId: string) => {
  const response: AxiosResponse<CdmDeleteCaseProtectionProductResponse> = await request.delete(
    `/protection_product/${protectionId}/case/${caseId}`,
  )
  if (response?.data && protectionId) {
    protectionVersionLoader.clear({
      protection_id: protectionId,
    })
  }

  return response?.data
}

// UpdateProtectionProduct
// https://crm.dev.acreplatforms.co.uk/api_documentation/redoc.html#operation/UpdateProtectionProduct
export const updateProtectionProduct = async (protectionId: string, body: ProtectionProductUpdateInput) => {
  const response = await request.patch<CdmUpdateProtectionProductResponse>(`/protection_product/${protectionId}`, body)

  if (response?.data && protectionId) {
    protectionVersionLoader.clear({
      protection_id: protectionId,
    })
  }
  return response?.data.protection_product as ProtectionProduct
}

const fetchProtectionAtVersionBatchFn = createBatchLoaderFn<
  { protection_id: string; version?: number },
  CdmGetProtectionProductResponse
>(({ version, protection_id }) => `/protection_product/${protection_id}/version/${version}`, {
  forwardParams: false,
})

export const createProtectionProductVersionLoader = () =>
  new DataLoader(
    async (params: { protection_id: string; version?: number }[]) => {
      const responses = await fetchProtectionAtVersionBatchFn(params)

      return params.map((param) => {
        const responseOrError = responses.find(
          (response) =>
            (response as CdmGetProtectionProductVersionResponse).protection_product?.protection_id ===
            param.protection_id,
        )

        return (
          (responseOrError as CdmGetProtectionProductVersionResponse)?.protection_product ||
          (responseOrError as AxiosError<any>)
        )
      })
    },
    {
      cacheKeyFn: JSON.stringify,
    },
  )

export const protectionVersionLoader = createProtectionProductVersionLoader()

export const fetchProtectionProductByVersion = async (params: { protection_id: string; version: number }) => {
  const protectionProduct = await protectionVersionLoader.load(params)

  if (protectionProduct instanceof Error) {
    console.error(protectionProduct)
    return null
  }

  return protectionProduct || null
}
