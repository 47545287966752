import { fetchOnfidoAuthToken, initiateCreditCheckCallback, initiateOnfidoCallback } from '../api_client_portal'
import { Resolvers } from '../generated/resolvers'

const Onfido: Resolvers = {
  Mutation: {
    onfidoToken: async () => await fetchOnfidoAuthToken(),
    onfidoCallback: async () => await initiateOnfidoCallback(),
    creditCheckCallback: async () => await initiateCreditCheckCallback(),
  },
}

export default Onfido
