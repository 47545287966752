import { GraphQLError } from 'graphql'

import { ZendeskReportProblemInput } from '../generated/resolvers'
import request from '../requesters/default'

export const createReportProblem = async (input?: ZendeskReportProblemInput | null) => {
  if (!input) {
    throw new GraphQLError('Support ticket not created - no Payload found', {
      extensions: {
        code: 'ZENDESK_TICKET_WITHOUT_PAYLOAD',
      },
    })
  }
  const response = await request.post(`/authentication/zendesk/new-ticket`, input)

  if (response?.status !== 201) {
    throw new GraphQLError('Support ticket not created', {
      extensions: {
        code: 'ZENDESK_TICKET_NOT_CREATED',
      },
    })
  }

  return Boolean(response?.status === 201)
}
