import { orgAndIntroducerAndAdvisorFiltersDiscovery } from '../api/org_introducer_advisor_filters'
import { Resolvers } from '../generated/resolvers'

const OrgAndIntroducerAndAdvisorFilter: Resolvers = {
  Query: {
    orgAndIntroducerAndAdvisorFiltersDiscovery,
  },
}

export default OrgAndIntroducerAndAdvisorFilter
