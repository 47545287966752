import { useCallback } from 'react'
import { MessageDescriptor, useIntl } from 'react-intl'

const useFormatMessage = () => {
  const { formatMessage } = useIntl()
  const _formatMessage = useCallback(
    (id: MessageDescriptor['id'], values?: Record<string, string | number | boolean | null | undefined | Date>) =>
      formatMessage({ id }, values),
    [formatMessage],
  )
  return _formatMessage
}

export default useFormatMessage
