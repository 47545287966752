import {
  addGeneralInsuranceProductCase,
  checkAuthGi,
  createGeneralInsuranceProduct,
  fetchQuote,
  fetchQuotes,
  getGeneralInsuranceProduct,
  removeGeneralInsuranceProduct,
  updateGeneralInsuranceProduct,
} from '../../api_general_insurance'
import { Resolvers } from '../../generated/resolvers'
import { CdmCreateGeneralInsuranceProductRequest } from '../../service/luther/model'

const resolvers: Resolvers = {
  Query: {
    generalInsuranceQuote: async (_, { case_id, quote_id }) => fetchQuote(case_id, quote_id),
    generalInsuranceQuotes: async (_, { input }) => fetchQuotes(input),
    generalInsuranceProducts: (_, { input }) => getGeneralInsuranceProduct(input),
    isGeneralInsuranceAuthValid: async (_, { input }) => {
      const res = await checkAuthGi(input)
      return res?.credentials_valid
    },
  },
  Mutation: {
    createGeneralInsuranceProduct: (_, { input }) =>
      createGeneralInsuranceProduct(input as CdmCreateGeneralInsuranceProductRequest),
    updateGeneralInsuranceProduct: (_, { id, input }) =>
      updateGeneralInsuranceProduct(id, input as CdmCreateGeneralInsuranceProductRequest),
    addGeneralInsuranceProductCase: (_, { input }) => addGeneralInsuranceProductCase(input),
    removeGeneralInsuranceProduct: async (_, { general_insurance_product_id, case_id }) => {
      const res = await removeGeneralInsuranceProduct(general_insurance_product_id, case_id)
      const { general_insurance_product } = res
      return general_insurance_product.general_insurance_product_id
    },
  },
}

export default resolvers
