import { notifyClientFetchSr } from '../api/checks'
import { Resolvers } from '../generated/resolvers'

const SuitabilityResolvers: Resolvers = {
  Mutation: {
    sendClientSrNotification: async (_, { input }) => {
      return await notifyClientFetchSr(input)
    },
  },
}

export default SuitabilityResolvers
