import { PropertyVersion } from '../generated/resolvers'

export const removeDuplicateProperties = (properties: PropertyVersion[]) => {
  return properties.reduce(
    (acc, property) => (acc.find((nestedProperty) => nestedProperty.id === property.id) ? acc : [...acc, property]),
    [] as PropertyVersion[],
  )
}

export const propertyCreatedAtComparator = (propertyA: PropertyVersion, propertyB: PropertyVersion) => {
  const dateA = new Date(propertyA.created_at!)
  const dateB = new Date(propertyB.created_at!)
  return dateA.getTime() - dateB.getTime()
}
