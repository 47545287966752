import { AuthProvider } from '../generated/resolvers'
import request from '../requesters/default'
import { CdmAuthenticationProvider } from '../service/luther/model'

export const formatAuthProvider = ({
  authentication_provider_id,
  authentication_name,
  authentication_mechanism,
  redirect_url,
  disabled,
}: CdmAuthenticationProvider): AuthProvider => ({
  id: authentication_provider_id!,
  name: authentication_name,
  // @ts-ignore - The parent AuthMechanism type is different, so we cannot interface it fully
  mechanism: authentication_mechanism,
  redirect_url,
  active: !disabled,
})

export const fetchAuthProviders = async () => {
  const response = await request.get<{
    authentication_providers: Array<CdmAuthenticationProvider>
  }>('/auth_provider?authentication_provider_details=true')

  return response.data.authentication_providers.map((provider) => formatAuthProvider(provider))
}
