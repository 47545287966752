import { MortgageAndCase } from '../generated/resolvers'
import { mortgageLoader } from '../loaders/mortgage'
import request from '../requesters/default'
import { UUID } from '../types'

export const fetchMortgagesForCase = async (case_id: UUID) => {
  return await mortgageLoader.load({ filter_case_id: case_id })
}

export const addMortgageToCase = async (input: MortgageAndCase) => {
  if (!input.case_id || !input.mortgage_id) {
    throw new Error('Case ID & Mortgage ID are required')
  }

  const response = await request.post<MortgageAndCase>(`/mortgage/${input.mortgage_id}/case`, input)

  mortgageLoader.clear({ filter_case_id: input.case_id })

  return response.data
}
