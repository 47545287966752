import { format } from 'date-fns'

import { ErcPeriodType, Maybe } from '../../../generated/resolvers'
import { addPeriodFixedEndDate } from '../../addPeriodFixedEndDate'
import { SourcingMortgageState } from './mapToLutherMortgage.fixtures'

type ERCType = Maybe<ErcPeriodType[]> | undefined

export const mapErcs = (ercs: SourcingMortgageState['erc']): ERCType => {
  const res = ercs?.map((val) => {
    const endDate = val['erc.endDate']
    const periodSpecifiedInLengthOfMonths = val['erc.endMonth'] != null && val['erc.startMonth'] != null

    return {
      erc_charge: val['erc.percentage'] && Math.round(val['erc.percentage'] * 100000),
      ...(endDate
        ? { period_fixed_end_date: format(new Date(endDate), 'yyyy-MM-dd') }
        : {
            ...(periodSpecifiedInLengthOfMonths
              ? {
                  period_fixed_length_months:
                    // @ts-ignore
                    val['erc.endMonth'] - val['erc.startMonth'] + 1,
                }
              : {}),
          }),
    }
  })
  return addPeriodFixedEndDate(res) as ERCType
}
