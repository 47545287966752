import { createContext } from 'react'
import { GeoJSON } from 'geojson'

import { LandRegistryFullResponse } from './LandRegistryTitleContext'

export type PropertyResponse = {
  searches?: PropertySearches
  property?: Property
}

export type PropertySearches = {
  commons?: CommonsResponse
  mining?: MiningResponse
  defra?: DefraResponse
  crime_stats?: CrimeResponse
  designated?: DesignatedLandResponse
  flood?: FloodDataResponse
  geo?: GeoResponse
  housePrices?: HousePriceResponse
  infrastructure_projects?: InfrastructureResponse
  internal_drainage?: InternalDrainageResponse
  polluters?: PollutersResponse
  radon?: RadonResponse
  title?: TitleResponse
  ofcom?: OfcomResponse
  schools?: SchoolsResponse
  llc?: LocalLandChargesResponse
  listed_buildings?: ListedBuildingsResponse
  boundaries?: BoundaryResponse
  epc?: EPCResponse
}

export type AddressWrapper = Array<Address>

export type Address = {
  id: string
  summaryline: string
  locationsummary: string
  count: number
  type: string
}

export type Property = {
  property_id: number
  location: string
  classification_code: string
  last_updated: Date
  address_single_line: string
  organisation: string
  sub_building: string
  building_name: string
  building_number: string
  street_name: string
  locality: string
  town_name: string
  post_town: string
  island: string
  postcode: string
  gss_code: string
  identifiers: Identifier[]
  source: string
  Lat: number
  Long: number
}

type Identifier = {
  type: string
  value: string
}

export type SearchResponse = {
  narrative: string[]
  risk: string
}
type BoundaryResponse = {
  boundaries: {
    [key: string]: string
  }
} & SearchResponse
type CommonsResponse = {} & SearchResponse
type MiningResponse = { score: string } & SearchResponse
type DefraResponse = { detail?: DefraEntry[] } & SearchResponse
type CrimeResponse = { crimes: { [key: string]: number } } & SearchResponse
type DesignatedLandResponse = {} & SearchResponse
export type FloodDataResponse = {} & SearchResponse
type HousePriceResponse = {
  sales_local_area: Sales
  sales_of_this_property: Sale[]
} & SearchResponse
type TitleResponse = {
  title: Title[]
  fullResponse: LandRegistryFullResponse[]
} & SearchResponse
type OfcomResponse = { broadband: BroadbandResponse; mobile: MobileResponse } & SearchResponse
type SchoolsResponse = { schools: School[] } & SearchResponse
export type InfrastructureResponse = {} & SearchResponse
type InternalDrainageResponse = {} & SearchResponse
type PollutersResponse = { detail?: PolluterDetail } & SearchResponse
export type RadonResponse = { score: number } & SearchResponse
export type GeoResponse = {} & SearchResponse
type LocalLandChargesResponse = { land_charges: LandCharge[] } & SearchResponse
type ListedBuildingsResponse = { listed_buildings: ListedBuilding[] } & SearchResponse
type EPCResponse = { cert: EPCCertificate; recommendations: Recommendation[] } & SearchResponse

export type epcFieldDefinitionName =
  | 'lmk_key'
  | 'address1'
  | 'address2'
  | 'address3'
  | 'postcode'
  | 'building_reference_number'
  | 'current_energy_rating'
  | 'potential_energy_rating'
  | 'current_energy_efficiency'
  | 'potential_energy_efficiency'
  | 'property_type'
  | 'built_form'
  | 'inspection_date'
  | 'local_authority'
  | 'constituency'
  | 'county'
  | 'lodgement_date'
  | 'transaction_type'
  | 'environment_impact_current'
  | 'environment_impact_potential'
  | 'energy_consumption_current'
  | 'energy_consumption_potential'
  | 'co2_emissions_current'
  | 'co2_emiss_curr_per_floor_area'
  | 'co2_emissions_potential'
  | 'lighting_cost_current'
  | 'lighting_cost_potential'
  | 'heating_cost_current'
  | 'heating_cost_potential'
  | 'hot_water_cost_current'
  | 'hot_water_cost_potential'
  | 'total_floor_area'
  | 'energy_tariff'
  | 'mains_gas_flag'
  | 'floor_level'
  | 'flat_top_storey'
  | 'flat_storey_count'
  | 'main_heating_controls'
  | 'multi_glaze_proportion'
  | 'glazed_type'
  | 'glazed_area'
  | 'extension_count'
  | 'number_habitable_rooms'
  | 'number_heated_rooms'
  | 'low_energy_lighting'
  | 'number_open_fireplaces'
  | 'hotwater_description'
  | 'hot_water_energy_eff'
  | 'hot_water_env_eff'
  | 'floor_description'
  | 'floor_energy_eff'
  | 'floor_env_eff'
  | 'windows_description'
  | 'windows_energy_eff'
  | 'windows_env_eff'
  | 'walls_description'
  | 'walls_energy_eff'
  | 'walls_env_eff'
  | 'secondheat_description'
  | 'sheating_energy_eff'
  | 'sheating_env_eff'
  | 'roof_description'
  | 'roof_energy_eff'
  | 'roof_env_eff'
  | 'mainheat_description'
  | 'mainheat_energy_eff'
  | 'mainheat_env_eff'
  | 'mainheatcont_description'
  | 'mainheatc_energy_eff'
  | 'mainheatc_env_eff'
  | 'lighting_description'
  | 'lighting_energy_eff'
  | 'lighting_env_eff'
  | 'main_fuel'
  | 'wind_turbine_count'
  | 'heat_loss_corridoor'
  | 'unheated_corridor_length'
  | 'floor_height'
  | 'photo_supply'
  | 'solar_water_heating_flag'
  | 'mechanical_ventilation'
  | 'address'
  | 'local_authority_label'
  | 'constituency_label'
  | 'posttown'
  | 'construction_age_band'
  | 'lodgement_datetime'
  | 'tenure'
  | 'fixed_lighting_outlets_count'
  | 'low_energy_fixed_light_count'

export type EPCCertificate = {
  [key: string]: string
}

export type Recommendation = {
  'improvement-item'?: string
  'indicative-cost'?: string
  'improvement-summary-text'?: string
  'improvement-descr-text'?: string
  'improvement-id-text'?: string
}

export type ListedBuilding = {
  id: string
  name: string
  grade: string
  listing_date: string
  amended_date: string
  url: string
  location: number[]
}

export type LandCharge = {
  ChargeExtent: GeoJSON
  Location?: LandChargeAddress
} & { [key: string]: string | Array<String> }

type LandChargeAddress = {
  Postcode: string
  line1: string
  line2: string
}

type School = {
  admissions_policy: string
  distance: number
  gender: string
  is_post_16: true
  is_primary: false
  is_secondary: false
  lat_long: number[]
  link: string
  local_authority: string
  name: string
  religion: string
  status: string
  type: string
  urn: number
}

type BroadbandResponse = {
  AddressShortDescription: string
  MaxBbPredictedDown: number
  MaxBbPredictedUp: number
  MaxPredictedDown: number
  MaxPredictedUp: number
  MaxSfbbPredictedDown: number
  MaxSfbbPredictedUp: number
  MaxUfbbPredictedDown: number
  MaxUfbbPredictedUp: number
  PostCode: string
  UPRN: number
}

type MobileResponse = {
  AddressShortDescription: string
  EEDataIndoor: number
  EEDataIndoorNo4g: 0
  EEDataOutdoor: number
  EEDataOutdoorNo4g: 3
  EEVoiceIndoor: number
  EEVoiceIndoorNo4g: 0
  EEVoiceOutdoor: number
  EEVoiceOutdoorNo4g: 3
  H3DataIndoor: 3
  H3DataIndoorNo4g: 2
  H3DataOutdoor: 4
  H3DataOutdoorNo4g: 3
  H3VoiceIndoor: 2
  H3VoiceIndoorNo4g: 2
  H3VoiceOutdoor: 3
  H3VoiceOutdoorNo4g: 3
  PostCode: string
  TFDataIndoor: number
  TFDataIndoorNo4g: 4
  TFDataOutdoor: number
  TFDataOutdoorNo4g: 3
  TFVoiceIndoor: number
  TFVoiceIndoorNo4g: 4
  TFVoiceOutdoor: number
  TFVoiceOutdoorNo4g: 3
  UPRN: number
  VODataIndoor: number
  VODataIndoorNo4g: 3
  VODataOutdoor: number
  VODataOutdoorNo4g: 3
  VOVoiceIndoor: number
  VOVoiceIndoorNo4g: 3
  VOVoiceOutdoor: number
  VOVoiceOutdoorNo4g: 3
}

type DefraEntry = {
  Location: number[]
  Address: string
  Company: string
  PostCode: string
  Tier: string
  Type: string
  Distance: number
}

type PolluterDetail = Array<Polluter>
type Polluter = {
  distance: number
  location: number[]
  location_osgb: number[]
  operator: string
  pollutants: Pollutant[]
  sector: string
  site: string
}

type Pollutant = {
  amount: number
  pollutant: string
  unit: string
}

type Title = {
  title_number: string
  jurisdiction: string
  location: string
  estate_interest: string
  class_of_title: string
  status: string
}

type Sales = {
  all_sales_this_postcode: Quarter[]
  matched_sales_this_sector: Quarter[]
  all_sales_this_sector: Quarter[]
  postcode: string
  sector: string
}

export type Sale = {
  id: string
  new_build: boolean
  property_type: string
  tenure: string
  transfer_category: string
  transfer_date: string
  price: number
}

export type Quarter = {
  average_price: number
  count: number
  quarter: number
  year: number
}
export const PropertyContext = createContext<PropertyResponse>({} as PropertyResponse)
