import { ChAutocompleteResponse, ChCompany } from '../generated/resolvers'
import request from '../requesters/companiesHouse'

export const companyAutocomplete = async (q: string) => {
  const response = await request.get<ChAutocompleteResponse>(`/complete?q=${encodeURIComponent(q)}`)
  return response?.data
}

export const companyDetail = async (companyId: string) => {
  const response = await request.get<ChCompany>(`/detail?id=${companyId}`)
  return response?.data
}
