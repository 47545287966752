import config from '@acre/config'

export const flagsFromConfig = () => {
  if (config.GLOBAL_FEATURE_FLAGS) {
    return config.GLOBAL_FEATURE_FLAGS.split(',').map((x: string) => [x, true] as [string, boolean])
  }
  return []
}

const configFlags: Map<string, boolean> = new Map(flagsFromConfig())

export default configFlags
