export const enum SourcingRateBandType {
  ClassFixed = 'Fixed',
  ClassVariable = 'Variable',
  ClassTracker = 'Tracker',
  ClassStepped = 'Stepped',
  ClassLibor = 'Libor',
  ClassCapped = 'Capped',
  ClassDiscount = 'Discounted',
}

export const enum SourcingRatePeriodBasis {
  Years = 'Years',
  Term = 'Term',
  Months = 'Months',
}

export const enum SourcingBasisRateType {
  Base = 'Base',
  SVR = 'SVR',
  Libor = 'Libor',
  Sonia = 'Sonia',
}

export type SourcingMortgageState = {
  id?: string
  network?: string
  doctype?: string
  btlFlag?: boolean
  lender?: string
  CCJsAccepted?: string
  stepped?: boolean
  type?: string
  previousLenderReferenceRequired?: boolean
  notesAdverseCreditBankruptciesIVAs?: string[]
  notesAdverseCreditCCJsDefaults?: string[]
  portable?: string
  builderGiftedDeposits?: string
  overPaymentsConditions?: string[]
  notesAdverseCredit?: string[]
  notesAdverseCreditArrears?: string[]
  remortgageReason?: string[]
  initialInterestRate?: number
  selfEmployedMinYears?: number
  vendorGiftedDeposits?: string
  notesNote?: string[]
  range?: string
  flatConstructionTypes?: string[]
  notesRateChangeDetails?: string[]
  minTermVendorToHaveOwnedPropertyForMonths?: number[]
  offset?: boolean
  isleOfMan?: string
  flatTenures?: string[]
  northernIreland?: string
  maximumRemortgageUplift?: number
  selfEmployedAccepted?: string
  landlordsReferenceRequired?: boolean
  leaseholdMinExcessYears?: number
  allowExistingBorrowerRemortgage?: boolean
  newBuildHouseConsideredInd?: string
  defaultAccepted?: string
  minEmploymentPeriodMonths?: number
  notesLinkedSavingsAccount?: string[]
  employerProbationAllowed?: string
  productName?: string
  maxTerm?: number
  listedBuildingsAccepted?: string
  flatTypesAccepted?: string[]
  bankStatementRequired?: number
  isExclusive?: boolean
  leaseholdMinYears?: number
  houseTenures?: string[]
  bankruptcyAccepted?: string
  expatriateAccepted?: string
  notesPortableDescription?: string[]
  bOEBaseRate20yrHigh?: number
  overpayments?: string
  notesLinkedCurrentAccount?: string[]
  residenceTypes?: string[]
  notesOverPayments?: string[]
  paymentHolidays?: string
  underpayments?: string
  notesAdditionalBorrowing?: string[]
  newBuildConsideredInd?: string
  maxLTV?: number
  mortgageReason?: string[]
  liborBaseRate20yrHigh?: number
  maxKitchens?: number
  proofOfResidencyRequired?: string
  notesApplication?: string[]
  selfEmployedAverageYearsTaken?: number
  existingBorrower?: boolean
  fastTrack?: boolean[]
  agriculturalTieAccepted?: string
  redemptionOverhang?: boolean
  houseConstructionTypes?: string[]
  selfEmployedAcceptDecliningProfit?: string
  repossessionAccepted?: string
  selfBuildConsidered?: string
  notesInterestRateNotes?: string[]
  payslipsRequiredAmount?: number
  mortgageType?: string[]
  guarantorsAccepted?: string
  IVAAccepted?: string
  partTimeAccepted?: string
  notesAdverseCreditRepossessions?: string[]
  propertiesRequiringRefurbishmentAccepted?: string
  baseRate?: number
  lifePolicyToBeAssigned?: string
  productCode?: string
  minPropertyValue?: number
  wales?: string
  maxPropertyValue?: number
  minContinuousEmploymentPeriodMonths?: number
  providerContactAddress1?: string[]
  england?: string
  purpose?: string[]
  noCompulsoryInsuranceRequired?: boolean
  arrearsAccepted?: string
  addFeesAboveMaxLTV?: string
  channelIslands?: string
  newBuildFlatConsideredInd?: string
  active?: boolean
  direct?: string
  foreignCitizensAccepted?: string
  p60Required?: boolean
  houseTypes?: string[]
  arrangementFeeAmount?: number
  scotland?: string
  deductOverPaymentAllowance?: boolean[]
  exLocalAuthorityAccepted?: string
  name?: string
  marketPricePercentage?: number
  sharedOwnershipMinShareOfProperty?: number
  sharedOwnershipMaxShareToValue?: number
  sharedEquityMaxShareToValue?: number
  rightToBuy?: string
  sharedOwnership?: string
  helpToBuy?: string
  discountPricePercentage?: number
  sharedEquityMinShareOfProperty?: number
  minAge?: number
  sharedOwnershipOnly?: boolean
  sharedEquity?: string
  hasCashback?: boolean
  freeValuation?: boolean
  freeLegals?: boolean
  nonConformingLimits?: string
  noArrangementFee?: boolean
  noBookingFee?: boolean
  _version_?: number
  _root_?: string
  incomeMultiple?: Array<{
    id?: string
    doctype?: string
    'incomeMultiple.maxIncome'?: number
    'incomeMultiple.minLTV'?: number
    'incomeMultiple.maxLTV'?: number
    'incomeMultiple.maxMultiple'?: number
    'incomeMultiple.type'?: string
    'incomeMultiple.minIncome'?: number
    _nest_parent_?: string
    _version_?: number
    _root_?: string
  }>
  erc?: Array<SourcingErc>
  providerFee?: Array<ProviderFee>
  incomeType?: Array<{
    id?: string
    doctype?: string
    'incomeType.type'?: string
    'incomeType.accept'?: string
    'incomeType.percentage'?: number
    _nest_parent_?: string
    _version_?: number
    _root_?: string
  }>
  incentive?: Array<{
    id?: string
    doctype?: string
    'incentive.type'?: string
    'incentive.amount'?: number
    'incentive.maxAmount'?: number
    'incentive.method'?: string
    _nest_parent_?: string
    _version_?: number
    _root_?: string
  }>
  rateBand?: Array<{
    id?: string
    doctype?: string
    'rateBand.ratePeriodBasis'?: SourcingRatePeriodBasis | string
    'rateBand.index'?: number
    'rateBand.band'?: number
    'rateBand.type'?: SourcingRateBandType
    'rateBand.basisRateType'?: SourcingBasisRateType
    'rateBand.rateVariance'?: number
    'rateBand.ratePeriod'?: number
    'rateBand.endDate'?: string
    _nest_parent_?: string
    _version_?: number
    _root_?: string
  }>
  loanLimit?: Array<{
    id?: string
    doctype?: string
    'loanLimit.minAmount'?: number
    'loanLimit.repaymentMethod'?: string
    'loanLimit.maxAmount'?: number
    'loanLimit.purpose'?: string
    'loanLimit.maxLtv'?: number
    'loanLimit.repaymentVehicleAccepted'?: string[]
    _nest_parent_?: string
    _version_?: number
    _root_?: string
  }>
  procFee?: Array<{
    id?: string
    doctype?: string
    'procFee.network'?: string
    'procFee.percentage'?: number
    'procFee.grosspercentage'?: number
    'procFee.name'?: string
    'procFee.netpercentage'?: number
    _nest_parent_?: string
    _version_?: number
    _root_?: string
  }>
  ltvBand?: {
    id?: string
    doctype?: string
    'ltvBand.minLTV'?: number
    'ltvBand.maxLTV'?: number
    'ltvBand.purpose'?: string
    'ltvBand.minAmount'?: number
    'ltvBand.maxAmount'?: number
    _nest_parent_?: string
    _version_?: number
    _root_?: string
  }
}

type SourcingErc = {
  id?: string
  doctype?: string
  'erc.percentage'?: number
  'erc.basis'?: string
  'erc.startMonth'?: number
  'erc.endMonth'?: number
  'erc.endDate'?: string
  _nest_parent_?: string
  _version_?: number
  _root_?: string
}

export type ProviderFee = {
  'providerFee.payableTo'?: string
  'providerFee.amount'?: number
  'providerFee.minAmount'?: number
  'providerFee.maxAmount'?: number
  'providerFee.percentage'?: number
  'providerFee.timing'?: ProviderFeeTiming
  'providerFee.refunded'?: boolean
  id?: string
  doctype?: string
  'providerFee.method'?: string
  'providerFee.type'?: ProviderFeeType
  _nest_parent_?: string
  _version_?: number
  _root_?: string
}

export enum ProviderFeeType {
  AdministrationFee = 'Administration Fee',
  ApplicationFee = 'Application Fee',
  ArrangementFee = 'Arrangement Fee',
  AssessmentFee = 'Assessment Fee',
  BookingFee = 'Booking Fee',
  BrokerFee = 'Broker Fee',
  ChapsTelegraphicTransferFee = 'CHAPS/Telegraphic Transfer Fee',
  CompletionFee = 'Completion Fee',
  DeedsReleaseFee = 'Deeds Release Fee',
  DisbursementFee = 'Disbursement Fee',
  EarlyRepaymentCharge = 'Early Repayment Charge',
  HigherLendingCharge = 'Higher Lending Charge',
  HomebuyersFee = 'Homebuyers Fee',
  InsuranceAdministration = 'Insurance Administration',
  LandRegistryFee = 'Land Registry Fee',
  LegalFee = 'Legal Fees',
  LenderConveyancingFee = 'Lender Conveyancing Fee',
  MasterBrokerFee = 'Master Broker Fee',
  MortgageAccountFee = 'Mortgage Account Fee',
  OtherFee = 'Other',
  PackagerFee = 'Packager Fee',
  ProductTransferFee = 'Product Transfer Fee',
  ReInspectionFee = 'Re Inspection Fee',
  RedemptionAdminFee = 'Redemption Admin Fee',
  TitleDeedsAdminFee = 'Title Deeds Admin Fee',
  ValuationFee = 'Valuation Fee',
}

export const enum ProviderFeeTiming {
  OnRedemption = 'On Redemption',
  OnCompletion = 'On Completion',
  UpFront = 'Up Front',
  OnEarlyRedemption = 'On Early Redemption',
}
