import { Locales } from '../types.ts'

export const messagesAlerts = {
  [Locales.GB]: {
    alerts: {
      generic: 'Something went wrong. Code: {errorCode}. If problem persists please contact support.',
      serviceUnavailable:
        'Service unavailable: {description}. Code: {errorCode}. If problem persists please contact support.',
      infrastructure: 'System error. Code: {errorCode}. If problem persists please contact support.',
      business: '{description}.\nIf problem persists please contact support.',
      client: 'Something went wrong. If problem persists please contact support',
      login: 'Please sign in to view this page.',
      unauthorised: 'You do not have the correct permissions to perform this action.',
    },
  },
}

export default messagesAlerts
