import { fetchAuthProviders } from '../api'
import { Resolvers } from '../generated/resolvers'

const AuthProviderResolver: Resolvers = {
  Query: {
    authenticationProviders: () => fetchAuthProviders(),
  },
}

export default AuthProviderResolver
