import { format } from 'date-fns'

import { Maybe, MortgageClass, RateTiersType, RateTracker } from '../../../generated/resolvers'
import { addPeriodFixedEndDate } from '../../addPeriodFixedEndDate'
import {
  SourcingBasisRateType,
  SourcingMortgageState,
  SourcingRateBandType,
  SourcingRatePeriodBasis,
} from './mapToLutherMortgage.fixtures'

export const mapRateTiers = (rateTiers: SourcingMortgageState['rateBand']): Maybe<RateTiersType[]> | undefined => {
  const sortedrateTiers = rateTiers && sortSourcingRateTiers([...rateTiers])
  const length = sortedrateTiers?.length

  const res = sortedrateTiers?.map((tier, index) => {
    const period_fixed_length_months = (() => {
      // prioritise end date over lenght months
      if (tier['rateBand.endDate'] || !tier['rateBand.ratePeriod']) {
        return undefined
      }

      switch (tier['rateBand.ratePeriodBasis']) {
        case SourcingRatePeriodBasis.Months:
          return tier['rateBand.ratePeriod']
        case SourcingRatePeriodBasis.Years:
          return tier['rateBand.ratePeriod'] * 12
        default:
          return null
      }
    })()

    return {
      rate: tier['rateBand.band'] && Math.round(tier['rateBand.band'] * 100000),
      rate_type: mapRateBand(tier['rateBand.type']),
      period_fixed_end_date: tier['rateBand.endDate'] && format(new Date(tier['rateBand.endDate']), 'yyyy-MM-dd'),
      period_fixed_length_months: period_fixed_length_months || null,
      rate_tracked: mapRateTracking(tier['rateBand.basisRateType']),
      // the last item in the list will always be until end of term
      // relying on SourcingRatePeriodBasis.Term is not possible, since it's not always set
      until_end_of_term: index + 1 === length,
    }
  })

  return addPeriodFixedEndDate(res) as RateTiersType[]
}

const sortSourcingRateTiers = (rateTiers: SourcingMortgageState['rateBand']) =>
  rateTiers?.sort((a, b) => {
    if (a['rateBand.index'] == null || b['rateBand.index'] == null) {
      return 0
    }

    if (a['rateBand.index'] < b['rateBand.index']) {
      return -1
    }

    if (a['rateBand.index'] > b['rateBand.index']) {
      return 1
    }

    return 0
  })

const mapRateTracking = (rateType?: SourcingBasisRateType) => {
  const dict = {
    [SourcingBasisRateType.Base]: RateTracker.BoeBaseRate,
    [SourcingBasisRateType.SVR]: RateTracker.LenderSvr,
    [SourcingBasisRateType.Libor]: RateTracker.Libor_1M,
    [SourcingBasisRateType.Sonia]: RateTracker.Sonia_1M,
  }

  const res = rateType && dict[rateType]
  return res ?? RateTracker.LenderSvr
}

const mapRateBand = (rateBandType?: SourcingRateBandType) => {
  const rateBandDict = {
    [SourcingRateBandType.ClassVariable]: MortgageClass.ClassVariable,
    [SourcingRateBandType.ClassFixed]: MortgageClass.ClassFixed,
    [SourcingRateBandType.ClassDiscount]: MortgageClass.ClassDiscount,
    [SourcingRateBandType.ClassCapped]: MortgageClass.ClassCapped,
    [SourcingRateBandType.ClassLibor]: MortgageClass.ClassLibor,
    [SourcingRateBandType.ClassTracker]: MortgageClass.ClassTracker,
    [SourcingRateBandType.ClassStepped]: MortgageClass.ClassStepped,
  }

  const res = rateBandType && rateBandDict[rateBandType]
  return res ?? MortgageClass.ClassVariable
}
