import React, { useState } from 'react'

//This contexts exists purely for ui reasons to toggle different tests on the screen
// depending if errors happen on magic link set or cookie set up

type LoginErrorsContextProviderType = {
  children: React.ReactNode
}

export type LoginErrorsContextType = [
  {
    magicLinkError: boolean
    cookieSetUpError: boolean
    noIdd: boolean
  },
  React.Dispatch<
    React.SetStateAction<{
      magicLinkError: boolean
      cookieSetUpError: boolean
      noIdd: boolean
    }>
  >,
]

const LoginErrorsContext = React.createContext<LoginErrorsContextType>([
  { magicLinkError: false, cookieSetUpError: false, noIdd: false },
  () => {},
])

const LoginErrorsProvider = ({ children }: LoginErrorsContextProviderType) => {
  const [loginErrorsState, setLoginErrors] = useState({ magicLinkError: false, cookieSetUpError: false, noIdd: false })
  return (
    <LoginErrorsContext.Provider value={[loginErrorsState, setLoginErrors]}>{children}</LoginErrorsContext.Provider>
  )
}

export { LoginErrorsContext, LoginErrorsProvider }
