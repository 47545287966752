import configs from '@acre/config'

import { get } from '../api/search'
import { Resolvers } from '../generated/resolvers'

const { SEARCH_API_URL } = configs

const SearchResolver: Resolvers = {
  Query: {
    search: async (_parent, { q, owning_org_id, show_client }) => {
      const searchParams = show_client ? 'search?q=' + q + '&show=CLIENT' : 'search?q=' + q
      const params = owning_org_id ? searchParams + '&owning_org_ids=' + owning_org_id : searchParams
      return (await get(SEARCH_API_URL + params, {})) || null
    },
  },
}

export default SearchResolver
