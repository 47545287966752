import { fetchClient, fetchClientByVersion } from '../api/client'
import { fetchMortgage, fetchMortgageByVersion } from '../api/mortgage'
import { fetchProperty, fetchPropertyByVersion } from '../api/property'
import { fetchProtectionProductByVersion, getProtectionProduct } from '../api/protection_product'
import {
  CaseStatus,
  ClientVersion,
  Mortgage,
  PropertyVersion,
  ProtectionProduct,
  TransitionClientVersion,
  TransitionMortgageVersion,
  TransitionPropertyVersion,
  TransitionProtectionVersion,
} from '../generated/resolvers'

export type TransitionChildEntityVersion =
  | TransitionClientVersion
  | TransitionPropertyVersion
  | TransitionMortgageVersion
  | TransitionProtectionVersion

type ChildEntityVersionsByType = {
  property: PropertyVersion
  mortgage: Mortgage
  client: ClientVersion
  protection: ProtectionProduct
}

type ArrElement<ArrType> = ArrType extends readonly (infer ElementType)[] ? ElementType : never

type ChildEntityNames = 'property' | 'mortgage' | 'client' | 'protection'

const clientEntityMap = new Map([
  [
    'property',
    {
      fetch: fetchProperty,
      fetchByVersion: fetchPropertyByVersion,
      idProp: 'property_id',
    },
  ],
  [
    'mortgage',
    {
      fetch: (id: string) => fetchMortgage({ filter_case_id: id }),
      fetchByVersion: fetchMortgageByVersion,
      idProp: 'mortgage_id',
    },
  ],
  [
    'client',
    {
      fetch: (id: string) => fetchClient({ client_ids: [id] }),
      fetchByVersion: fetchClientByVersion,
      idProp: 'client_id',
    },
  ],
  [
    'protection',
    {
      fetch: (id: string) => getProtectionProduct({ protection_product_details: true, filter_case_ids: [id] }),
      fetchByVersion: fetchProtectionProductByVersion,
      idProp: 'protection_id',
    },
  ],
])

export const fetchVersionedCaseChildEntities = async <
  TransitionVersion extends TransitionChildEntityVersion,
  EntityVersion extends ArrElement<TransitionVersion['entity_versions']>,
  EntityName extends ChildEntityNames,
  EntityID extends string,
  EntityType = ChildEntityVersionsByType[EntityName],
  Result = EntityType[],
>(
  entityType: EntityName,
  {
    status,
    entityId,
    transitionVersions,
  }: {
    status?: CaseStatus
    entityId?: EntityID
    transitionVersions?: TransitionVersion[] | null
  },
) => {
  const meta = clientEntityMap.get(entityType)

  if (!meta) {
    return null
  }

  const { fetch, fetchByVersion } = meta
  const idProp = meta.idProp as keyof EntityVersion

  if ((status === CaseStatus.Complete || status === CaseStatus.NotProceeding) && transitionVersions?.length) {
    const childEntityVersions = getChildEntityVersionOnCaseClosed(status, transitionVersions)

    if (childEntityVersions?.entity_versions) {
      // Workaround for typescript sucking with array unions
      const entityVersions = childEntityVersions.entity_versions as EntityVersion[]

      if (!fetchByVersion) {
        return null
      }

      if (entityId) {
        const childEntity = entityVersions.find((item) => String(item[idProp]) === entityId)

        if (childEntity) {
          const entity = await fetchByVersion({
            [idProp]: String(childEntity[idProp]),
            version: childEntity.version.toString(),
          } as never)

          return entity ? ([entity] as unknown as Result) : null
        }
      }

      const promises = entityVersions.map((item) =>
        fetchByVersion({ [idProp]: String(item[idProp]), version: item.version.toString() } as never),
      )

      const entities = await Promise.all(promises)

      return entities as unknown as Result
    }

    return null
  }

  if (!fetch) {
    return null
  }

  if (entityId) {
    const entity = await fetch(entityId)

    return entity ? ([entity] as unknown as Result) : null
  }

  // TODO: fetch all entities

  return null
}

export const getChildEntityVersionOnCaseClosed = <TransitionVersion extends TransitionChildEntityVersion>(
  caseStatus: CaseStatus.Complete | CaseStatus.NotProceeding,
  transitionEntityVersions: TransitionVersion[],
) => {
  const closedEntityVersions = transitionEntityVersions.filter((item) => item.transition_to === caseStatus)

  return closedEntityVersions?.length
    ? closedEntityVersions.reduce(
        (acc, item) => {
          if (acc) {
            return item.created_at > acc.created_at ? item : acc
          }
          return item
        },
        undefined as TransitionVersion | undefined,
      )
    : undefined
}
