import { companyAutocomplete, companyDetail } from '../../api_companies_house'
import { Resolvers } from '../../generated/resolvers'

const CompaniesHouseResolver: Resolvers = {
  Query: {
    companyAutocomplete: async (_, params) => await companyAutocomplete(params.input),
    getCompanyDetail: async (_, params) => await companyDetail(params.companyId),
  },
}

export default CompaniesHouseResolver
