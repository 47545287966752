import React from 'react'
import { styled } from '@mui/material'

import { HeaderStyleProps, HeadingTagName } from './Header.types'
import { headingStyles } from './Header.styles'

export const H1 = styled('h1', {
  shouldForwardProp: (prop) => !['styledAs'].includes(prop as keyof HeaderStyleProps),
})<HeaderStyleProps>(({ theme, styledAs, variant }) => ({
  margin: 0,
  color: variant === 'grey' ? theme.colours.baseLight : theme.palette.text.primary,
  ...(styledAs ? headingStyles(theme)[styledAs] : headingStyles(theme).h1),
}))

export const H2 = styled('h2', {
  shouldForwardProp: (prop) => !['styledAs'].includes(prop as keyof HeaderStyleProps),
})<HeaderStyleProps>(({ theme, styledAs, variant }) => ({
  margin: 0,
  color: variant === 'grey' ? theme.colours.baseLight : theme.palette.text.primary,
  ...(styledAs ? headingStyles(theme)[styledAs] : headingStyles(theme).h2),
}))

export const H3 = styled('h3', {
  shouldForwardProp: (prop) => !['styledAs'].includes(prop as keyof HeaderStyleProps),
})<HeaderStyleProps>(({ theme, styledAs, variant }) => ({
  margin: 0,
  color: variant === 'grey' ? theme.colours.baseLight : theme.palette.text.primary,
  ...(styledAs ? headingStyles(theme)[styledAs] : headingStyles(theme).h3),
}))

export const H4 = styled('h4', {
  shouldForwardProp: (prop) => !['styledAs'].includes(prop as keyof HeaderStyleProps),
})<HeaderStyleProps>(({ theme, styledAs, variant }) => ({
  margin: 0,
  color: variant === 'grey' ? theme.colours.baseLight : theme.palette.text.primary,
  ...(styledAs ? headingStyles(theme)[styledAs] : headingStyles(theme).h4),
}))

export const H5 = styled('h5', {
  shouldForwardProp: (prop) => !['styledAs'].includes(prop as keyof HeaderStyleProps),
})<HeaderStyleProps>(({ theme, styledAs, variant }) => ({
  margin: 0,
  color: variant === 'grey' ? theme.colours.baseLight : theme.palette.text.primary,
  ...(styledAs ? headingStyles(theme)[styledAs] : headingStyles(theme).h5),
}))

export const H6 = styled('h6', {
  shouldForwardProp: (prop) => !['styledAs'].includes(prop as keyof HeaderStyleProps),
})<HeaderStyleProps>(({ theme, styledAs, variant }) => ({
  margin: 0,
  color: variant === 'grey' ? theme.colours.baseLight : theme.palette.text.primary,
  ...(styledAs ? headingStyles(theme)[styledAs] : headingStyles(theme).h6),
}))

const componentMap: Record<HeadingTagName, React.ElementType<HeaderStyleProps>> = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
}

export type HeaderProps = {
  level: HeadingTagName
} & HeaderStyleProps

const Header = ({ level, ...props }: HeaderProps) => {
  const Tag = componentMap[level]

  return <Tag {...props} />
}

export default Header
