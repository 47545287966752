import { NonIndexRouteObject, PathRouteProps, Route } from 'react-router-dom'

interface MultiRoutesComponentProps extends PathRouteProps {
  paths: string[]
}

interface MultiRoutesProps extends NonIndexRouteObject {
  paths: string[]
}

const MultiRoutes = ({ paths, ...rest }: MultiRoutesComponentProps) => (
  <>
    {paths.map((path) => (
      <Route key={path} path={path} {...rest} />
    ))}
  </>
)

export const multiRoutes = ({ paths, ...rest }: MultiRoutesProps) => paths.map((path) => ({ path, ...rest }))

export default MultiRoutes
