import { isFuture, isPast } from 'date-fns'
import { isEmpty, isNil } from 'lodash'

import {
  addProperty,
  ClientPropertyLoader,
  fetchCase,
  fetchClient,
  fetchProperty,
  PropertyFieldUpdates,
  updateCase,
  updateProperty,
} from '../../api'
import fetchAddressLookup from '../../api/address_lookup'
import { fetchMortgages, updateMortgage } from '../../api/mortgage'
import {
  ClientIncome,
  Maybe,
  MortgageStatus,
  PropertyInput,
  PropertySubType,
  PropertyType,
  PropertyVersion,
} from '../../generated/resolvers'
import { ClientAddress, ProtectionRequirements } from '../../main'
import { isNonNaturalClient } from '../../utils'
import { GraphqlException } from '../util'

export const updatePropertyRegisteredOwners = async (propertyId: string, clientIds: string[]) => {
  const property = await fetchProperty(propertyId)
  if (property?.details) {
    const updatedClientIdsForExistingProperty = [...(property.details.registered_owners_details || []), ...clientIds]
    await updateProperty({ registered_owners_details: updatedClientIdsForExistingProperty }, propertyId)
    ClientPropertyLoader.clearAll()
  }
}

export const addClientAndUpdateCase = async (newClientId: string, caseId: string) => {
  if (!newClientId || !caseId) {
    throw new GraphqlException('Client was not created')
  }
  const fetchedCase = await fetchCase(caseId)

  const isClientIncluded = fetchedCase.details.client_ids?.includes(newClientId)
  if (isClientIncluded) {
    throw new GraphqlException('Client has already been added to the case')
  }
  if (fetchedCase?.details?.client_ids) {
    const caseMortgages = await fetchMortgages({
      filter_case_id: fetchedCase.id,
    })

    const selectedAndProposedMortgages = caseMortgages?.filter(
      ({ status }) =>
        status === MortgageStatus.StatusSelected ||
        status === MortgageStatus.StatusProposed ||
        status === MortgageStatus.StatusLenderProposed,
    )

    if (selectedAndProposedMortgages) {
      selectedAndProposedMortgages.forEach(async (mortgageDetails) => {
        if (!mortgageDetails) {
          return
        }
        await updateMortgage(mortgageDetails.mortgage_id, {
          client_ids: mortgageDetails.client_ids ? [...mortgageDetails.client_ids, newClientId] : [newClientId],
        })
      })
    }

    if (fetchedCase.details?.preference_related_property_sale) {
      const {
        details: { preference_related_property_sale },
      } = fetchedCase

      preference_related_property_sale &&
        updatePropertyRegisteredOwners(preference_related_property_sale, [...newClientId])
    }

    const newClient = await fetchClient({ client_ids: [newClientId] })

    const isNewClientACompany = newClient?.details && isNonNaturalClient(newClient.details)

    const clientIds: string[] = (() => {
      // If we are adding a company to case, we need to ensure that company's directors are not already on that case
      // if they are we remove them from case (as they will be brought to the case via the relationship attribute)
      //  otherwise we'd get duplicates with the same director stored on both case and the relationship to the company
      if (isNewClientACompany) {
        const clientsRelatedToCompany = (newClient.details.relationships || []).map(
          ({ relationship_reference }) => relationship_reference,
        )
        return [...fetchedCase.details.client_ids.filter((id) => clientsRelatedToCompany.includes(id)), newClientId]
      }
      return [...fetchedCase.details.client_ids, newClientId]
    })()

    await updateCase(
      {
        client_ids: clientIds,
        shared_budget: true,
      },
      caseId,
    )
    return newClient
  }

  return (await fetchClient({ client_ids: [newClientId] })) ?? null
}

export const isEmploymentCurrent = (income: ClientIncome): boolean => {
  if (isNil(income?.is_current)) {
    const employmentIsCurrent =
      income?.employment &&
      !isEmpty(income?.employment) &&
      (!income?.employment?.employment_end_date || isFuture(new Date(income.employment.employment_end_date)))

    const selfEmploymentIsCurrent =
      income?.self_employment &&
      !isEmpty(income?.self_employment) &&
      (!income?.self_employment?.your_role_in_business_end_date ||
        isFuture(new Date(income.self_employment.your_role_in_business_end_date)))

    const isRetired = (income?.date_of_retirement && isPast(new Date(income.date_of_retirement))) || income?.is_retired
    return Boolean(employmentIsCurrent || selfEmploymentIsCurrent || isRetired)
  }
  return income?.is_current
}

export const formatClientProperty = (
  clientId: string,
  clientAddress: ClientAddress,
  propertyId?: Maybe<string>,
  fieldUpdates?: Maybe<PropertyFieldUpdates>,
) => {
  const {
    property_sub_type,
    property_tenure,
    property_type,
    year_built,
    floor_number,
    storeys,
    bedrooms,
    price_paid,
    current_energy_rating,
  } = fieldUpdates || {}

  const { address, property_location } = clientAddress

  const variables: PropertyInput = {
    address: address,
    property_location: property_location,
    registered_owners_details: [clientId],
    acre_property_id: propertyId ?? null,
    property_tenure,
    sub_type: property_sub_type as PropertySubType,
    property_type: property_type as PropertyType,
    year_property_built: year_built,
    floor_of_flat: floor_number,
    num_floors_in_block: storeys,
    number_of_bedrooms: bedrooms,
    property_price: `${(price_paid || 0) * 100}`,
    current_energy_rating,
  }

  return variables
}

export const addNewClientProperty = async (
  client_id: string,
  updatedClientAddress: ClientAddress,
  property_id?: Maybe<string>,
) => {
  // Before creating new property lookup the address to gather more information
  let field_updates: Maybe<PropertyFieldUpdates> = {}
  if (property_id) {
    const addressLookUp = await fetchAddressLookup(3, property_id)
    if (!addressLookUp?.[0]?.field_updates) return

    field_updates = addressLookUp?.[0]?.field_updates
  }

  await addProperty(formatClientProperty(client_id, updatedClientAddress, property_id, field_updates))
}

export const loadExistingClientProperties = async (clientId: string, clientIds?: Maybe<string[]>) => {
  if (isEmpty(clientIds)) return await ClientPropertyLoader.load(clientId)

  let existingClientProperties: Maybe<PropertyVersion[]> = []

  const clientsProperties = await ClientPropertyLoader.loadMany(clientIds!)

  if (!isEmpty(clientsProperties)) {
    clientsProperties.forEach((properties) => {
      if (properties) {
        existingClientProperties?.push.apply(existingClientProperties, properties as PropertyVersion[])
      }
    })
  }

  return existingClientProperties
}

/**
 * Removes clients from the given protection requirements that match the specified client ID.
 *
 * @param {ProtectionRequirements} requirements - The protection requirements FROM THE CASE object to modify.
 * @param {string} clientId - The client ID to remove from the requirements.
 * @return {ProtectionRequirements} - The modified protection requirements object without the requirements of  the specified client removed.
 */
export const removeClientsRequirementsOnTheCase = (
  requirements: ProtectionRequirements,
  clientId: string,
): ProtectionRequirements => {
  const filterClientIds = <T extends { client_ids?: Maybe<string>[] | null }>(array?: Maybe<T[]>): Maybe<T[]> => {
    if (array) {
      return array.filter((req) => !req.client_ids?.includes(clientId))
    }
    return array
  }

  return {
    ...requirements,
    family_income_benefits: filterClientIds(requirements.family_income_benefits),
    income_protection: filterClientIds(requirements.income_protection),
    life_and_critical_illness: filterClientIds(requirements.life_and_critical_illness),
    private_medical_insurance: filterClientIds(requirements.private_medical_insurance),
    whole_of_life: filterClientIds(requirements.whole_of_life),
  }
}
