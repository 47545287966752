import { AxiosError } from 'axios'
import { GraphQLError } from 'graphql'
import { omit } from 'lodash'

import request from '../requesters/default'

export const getFirstUsedKey = <T extends Record<string, any>>(obj: T, keys: (keyof T)[]) => {
  const key = keys.reduce((acc, curr) => {
    if (obj[curr]) {
      return curr
    }

    return acc
  }, keys[0])

  return key
}

export const spreadParameters = <InputParams extends Record<string, any>>(
  params: InputParams,
  key: keyof InputParams = 'id',
) => {
  const { [key]: keys, ...rest } = params

  return keys.map((_key: string) => ({ [key]: _key, ...rest }))
}

export const reduceParameters = <InputParams extends Record<string, any>>({
  params,
  keys: keysProp,
  defaultParams,
}: {
  params: InputParams[]
  keys?: string[]
  defaultParams?: Record<string, any>
}) => {
  const keys = keysProp ?? ['id']

  const key = getFirstUsedKey(params[0], keys)

  const initalObject = {
    [key]: [] as string[],
  }

  const sharedParams = omit(params[0], keys)

  const reducedParams = params.reduce((acc, param) => {
    keys.forEach((key) => {
      if (param[key]) {
        acc[key].push(param[key])
      }
    })

    return { ...defaultParams, ...acc } as InputParams
  }, initalObject)

  return { ...reducedParams, ...sharedParams }
}

export const createBatchLoaderFnWithReducer =
  <InputParams extends Record<string, any>, ResponseType extends any>(
    url: string,
    options?: { defaultParams?: Partial<InputParams>; reduceKeys?: string[] },
  ) =>
  async (params: Record<string, any>[]) => {
    const reducedParams = reduceParameters({
      params,
      keys: options?.reduceKeys ?? ['id'],
      defaultParams: options?.defaultParams,
    })

    const result = await request.get<ResponseType>(url, { params: reducedParams })

    return result.data
  }

export const createBatchLoaderFn =
  <InputParams extends Record<string, any>, ResponseType extends any>(
    url: string | ((params: InputParams) => string),
    options?: {
      defaultParams?: Partial<InputParams>
      // formatData?: (data: ResponseType) => Formatted
      forwardParams?: boolean
    },
  ) =>
  async (params: InputParams[]) => {
    const requests = params.map(async (params) => {
      const parsedUrl = typeof url === 'string' ? url : url(params as InputParams)

      try {
        const response = await request.get<ResponseType>(parsedUrl, {
          params: { ...options?.defaultParams, ...(options?.forwardParams !== false && params) },
        })

        return response.data
      } catch (error: unknown) {
        return error as AxiosError
      }
    })

    return await Promise.all(requests)

    // return responses.map((response) =>
    //   typeof options?.formatData === 'function' ? options.formatData(response.data) : response.data
    // )
  }

export const processLoaderResults = <T = unknown>(results: (T | GraphQLError)[]) => {
  return results.flat().reduce((acc, result) => {
    if (result instanceof GraphQLError) {
      throw result
    } else if (result !== null && result !== undefined) {
      acc.push(result as NonNullable<T>)
    }

    return acc
  }, [] as NonNullable<T>[])
}

export const shouldUseLoader = (params: any, keys: Set<string>) =>
  Object.keys(params).some((key) => keys.has(key) && (params[key] as string[]).length > 0)
