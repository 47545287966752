import { formatFeeAsAcre } from '../../api/common.helpers'
import { Case, CaseDetailsFlag, CaseVersion, MortgageReason, NewFee } from '../../generated/resolvers'
import { withoutFields } from '../../resolvers/util'
import { CdmCase, CdmCaseDetailsFlag } from '../../service/luther'

export const formatCaseFlags = (caseFlags: CdmCaseDetailsFlag[]): CaseDetailsFlag[] =>
  caseFlags.map(
    (caseFlag) =>
      ({
        ...caseFlag,
        reviews: caseFlag.reviews ? caseFlag.reviews : [],
      }) as CaseDetailsFlag,
  )

// TODO FRON-1040 move formatting to resolvers
export const formatCase = (data: CdmCase): CaseVersion => {
  // remove all undefined properties
  return {
    id: String(data.case_id),
    created_at: String(data.created_at),
    version_created_at: data.version_created_at,
    version: data.version,
    ...withoutFields(data, ['case_id']),
    details: {
      id: String(data.case_id),
      ...(data.details?.btl_has_lived ? { btl_has_lived: data.details?.btl_has_lived } : {}),
      ...(data.details?.btl_will_lived !== undefined ? { btl_will_lived: data.details?.btl_will_lived } : {}),
      ...(data.details?.btl_is_operating_a_business
        ? {
            btl_is_operating_a_business: data.details?.btl_is_operating_a_business,
          }
        : {}),
      ...data.details,
      preference_mortgage_reason: data.details?.preference_mortgage_reason as MortgageReason,
      owner_user_id: data.details?.owner_user_id,
      regulated_by: data.details?.regulated_by,
      clients: [],
      clientsUser: [],
      documents: [],
      fees: data.details?.fees ? (data.details?.fees.map(formatFeeAsAcre) as NewFee[]) : [],
      flags: data.details?.flags ? formatCaseFlags(data.details?.flags) : [],
      property_portfolio: [],
    } as Case,
  }
}
