import {
  createPermissionAssignment,
  createPermissionAssignmentBulk,
  fetchPermissionAssignments,
  fetchPermissions,
  PermissionResolutionLoader,
  updatePermissionAssignment,
  updatePermissionAssignmentBulk,
} from '../api/permission'
import { PermissionResolution, PermissionResolutionInput, Resolvers } from '../generated/resolvers'

const PermissionResolver: Resolvers = {
  Query: {
    permissionResolution: async (_parent, { input }) => {
      const permissionResolutionRequests: PermissionResolutionInput[] = input || []

      if (!permissionResolutionRequests || !permissionResolutionRequests.length) {
        return null
      }
      const permissions: PermissionResolution[] = await Promise.all(
        permissionResolutionRequests.map(async (permissionResolutionRequest: PermissionResolutionInput) => {
          const permission: PermissionResolution =
            (await PermissionResolutionLoader.load(permissionResolutionRequest)) || {}
          return { ...permission, ...permissionResolutionRequest }
        }),
      )
      return permissions
    },
    permission: async (_, params) =>
      await fetchPermissions({
        ids: params.ids || [],
        names: params.names || [],
        show_allowlist: params.show_allowlist,
      }),
    permissionAssignments: async (_, params) =>
      await fetchPermissionAssignments({ filter_user_id: params.userId || '', filter_group_id: params.groupId || '' }),
  },
  Mutation: {
    createPermissionAssignment: async (_, params) => await createPermissionAssignment(params.input),
    createPermissionAssignmentBulk: async (_, params) => await createPermissionAssignmentBulk(params.input),
    updatePermissionAssignment: async (_, params) => await updatePermissionAssignment(params.id, params.input),
    updatePermissionAssignmentBulk: async (_, params) => await updatePermissionAssignmentBulk(params.input),
    updatePermissionsList: async (_, { assignmentsToCreate, assignmentsToDisable, assignmentsToEnable, userId }) => {
      if (assignmentsToEnable?.length) {
        await Promise.all(
          assignmentsToEnable?.map((assignment) => {
            const { permission_assignment_id, user_id, permission_id } = assignment
            return (
              permission_assignment_id &&
              updatePermissionAssignment(permission_assignment_id, { user_id, permission_id, disabled: false })
            )
          }),
        )
      }

      if (assignmentsToDisable?.length) {
        await Promise.all(
          assignmentsToDisable?.map((assignment) => {
            const { permission_assignment_id, user_id, permission_id } = assignment
            return (
              permission_assignment_id &&
              updatePermissionAssignment(permission_assignment_id, { user_id, permission_id, disabled: true })
            )
          }),
        )
      }

      if (assignmentsToCreate?.length) {
        await Promise.all(assignmentsToCreate.map(createPermissionAssignment))
      }

      const allUpdatedPermissions = await fetchPermissionAssignments({ filter_user_id: userId })

      return allUpdatedPermissions
    },
  },
}

export default PermissionResolver
