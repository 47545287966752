import { useTheme } from '@mui/material'

const useToolbarInfo = () => {
  const theme = useTheme()

  const height =
    typeof theme.mixins.toolbar.minHeight === 'string'
      ? theme.mixins.toolbar.minHeight
      : `${theme.mixins.toolbar.minHeight}px`

  const shouldRenderGhost = height?.match(/\d+/)?.[0] !== '0'

  return { height, shouldRenderGhost }
}

export default useToolbarInfo
