import { AxiosResponse } from 'axios'

import configs from '@acre/config'

import {
  CreditReportResponse,
  KbaAttempt,
  KbaInput,
  KbaSubmissionResponse,
  SrNotificationInput,
  SrNotificationResponse,
} from '../generated/resolvers'
import request from '../requesters/default'

const { API1_URL, CLIENT_PORTAL_API_URL, CLIENT_PORTAL_OATHKEEPER_API_URL } = configs

// KBA
export const getKbaQuestionnaire = async (id: string, useClientApi?: boolean | null) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  let baseURL
  if (useOathKeeper === 'true') {
    baseURL = `${CLIENT_PORTAL_OATHKEEPER_API_URL}/checks-service`
  } else if (useClientApi) {
    baseURL = `${CLIENT_PORTAL_API_URL}/checks-service`
  } else {
    baseURL = `${API1_URL}/acre/checks-service`
  }

  const response: AxiosResponse<KbaAttempt> = await request.get<KbaAttempt>('/kba', {
    baseURL: baseURL,
    params: { client_id: id },
  })

  return response.data
}

export const submitKba = async (id: string, input: KbaInput, useClientApi?: boolean | null) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  let baseURL
  if (useOathKeeper === 'true') {
    baseURL = `${CLIENT_PORTAL_OATHKEEPER_API_URL}/checks-service`
  } else if (useClientApi) {
    baseURL = `${CLIENT_PORTAL_API_URL}/checks-service`
  } else {
    baseURL = `${API1_URL}/acre/checks-service`
  }

  const response = await request.post<KbaSubmissionResponse>('/kba', input, {
    baseURL: baseURL,
    params: { client_id: id },
  })

  return response.data
}

// Credit Report
export const getCreditReport = async (useClientApi?: boolean | null, clientId?: string | null) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  let baseURL
  if (useOathKeeper === 'true') {
    baseURL = `${CLIENT_PORTAL_OATHKEEPER_API_URL}/checks-service`
  } else if (useClientApi) {
    baseURL = `${CLIENT_PORTAL_API_URL}/checks-service`
  } else {
    baseURL = `${API1_URL}/acre/checks-service`
  }


  const creditEndpoint = useClientApi ? '/credit' : `/credit?client_id=${clientId}`
  const response: AxiosResponse<CreditReportResponse> = await request.get<CreditReportResponse>(creditEndpoint, {
    baseURL: baseURL,
  })

  return response.data
}

// Suitability Report
// Send client email notification to sign their SR
export const notifyClientFetchSr = async (input: SrNotificationInput) => {
  const baseURL = `${API1_URL}/acre/checks-service`
  const response = await request.post<SrNotificationResponse>('/notify_client_fetch_sr', input, {
    baseURL: baseURL,
  })

  return response.data
}
