import { checkUserExists, fetchAuthUrls } from '../api'
import { fetchMagicLinkDetails, setMagicLinkCookie } from '../api_client_portal'
import { Resolvers } from '../generated/resolvers'

const AuthURLResolvers: Resolvers = {
  Query: {
    authUrls: (_parent, { email }) => fetchAuthUrls(email),
    userExistsWithEmail: (_parent, { email }) => checkUserExists(email),
  },
  Mutation: {
    authenticationClientUser: (_, { email }) => fetchMagicLinkDetails(email),
    magicLinkCookie: (_, { token, clientId }) => setMagicLinkCookie(token, clientId),
  },
}

export default AuthURLResolvers
