import { GraphQLError } from 'graphql'

import envConfig from '@acre/config'

import {
  CaseVersion,
  CreateMortgageInput,
  Maybe,
  Mortgage,
  MortgageAndCaseInput,
  MortgageInput,
  MortgageProductCode,
  MortgageReason,
  MortgageStatus,
  PropertyVersion,
  TermUnit,
  UpdateMortgagesInput,
} from '../generated/resolvers'
import { mortgageLoader, mortgageVersionLoader } from '../loaders/mortgage'
import request from '../requesters/default'
import {
  getEndDate,
  getPropertyValue,
  getProposedStartDate,
  getTermDateForRemortgage,
  GraphqlException,
} from '../resolvers/util'
import {
  CdmCreateMortgageResponse,
  CdmDeleteMortgageCaseResponse,
  CdmGetMortgageRequest,
  CdmGetMortgageResponse,
  CdmUpdateMortgageResponse,
} from '../service/luther/model'
import { UUID } from '../types'
import { getFirstUsedKey, processLoaderResults, shouldUseLoader, spreadParameters } from '../utils/dataloader'
import { mapToLutherMortgageProduct, SourcingMortgageState } from '../utils/schemaMapping/mapToLutherMortgageProduct'
import { formatMortgage } from '../utils/schemaMapping/mortgage'
import { CaseLoader, fetchCase, updateCase } from './case'
import { addMortgageToCase } from './mortgage_case'
import { addMortgageToProduct } from './mortgage_product'
import { createMortgageProduct, updateMortgageProductHelper } from './mortgage_product'
import { fetchProperty } from './property'

// Please note we never want to batch by `filter_by_client_ids
// because when multiplt client ids are passed into that param
// the api would only return the mortgages where both clients exist on that mortgage
// and would not return the mortgages that clients have individually
const batchKeys = new Set<keyof CdmGetMortgageRequest>(['mortgage_ids', 'filter_ext_ids'])

export const fetchMortgage = async (params: CdmGetMortgageRequest) => {
  const mortgage = await mortgageLoader.load(params)

  if (mortgage instanceof GraphQLError) {
    throw mortgage
  }

  return mortgage ? formatMortgage(mortgage) : null
}

export const fetchMortgages = async (params: CdmGetMortgageRequest) => {
  if (shouldUseLoader(params, batchKeys)) {
    const batchKey = getFirstUsedKey(params, [...batchKeys])

    const batchParams = params[batchKey]

    if (Array.isArray(batchParams) && batchParams.length > 1) {
      const mortgagesFromLoader = await mortgageLoader.loadMany(spreadParameters(params, batchKey))

      const clean = processLoaderResults(mortgagesFromLoader)

      return clean.map(formatMortgage)
    }

    const mortgage = await mortgageLoader.load(params)

    if (mortgage instanceof GraphQLError) {
      throw mortgage
    }

    if (Array.isArray(mortgage)) {
      return mortgage.map(formatMortgage)
    }

    return mortgage ? [formatMortgage(mortgage)] : null
  }

  const response = await request.get<CdmGetMortgageResponse>('/mortgage', {
    params: { mortgage_details: true, ...params },
  })

  return response.data.mortgages?.map(formatMortgage) ?? []
}

export const fetchMortgageByVersion = async (params: { mortgage_id: string; version: number }) => {
  const mortgage = await mortgageVersionLoader.load(params)

  if (mortgage instanceof Error) {
    console.error(mortgage)
    return null
  }

  return mortgage ? formatMortgage(mortgage) : null
}

export const createMortgage = async (input: MortgageInput) => {
  const response = await request.post<CdmCreateMortgageResponse>('/mortgage', input)

  input.case_ids?.forEach((id) => id && mortgageLoader.clear({ filter_case_id: id }))
  input.property_secured_ids?.forEach((id) => mortgageLoader.clear({ filter_property_secured_id: id }))

  return response.data.mortgage ? formatMortgage(response.data.mortgage) : null
}

export const updateMortgage = async (mortgage_id: UUID, input: MortgageInput) => {
  const response = await request.patch<CdmUpdateMortgageResponse>(`/mortgage/${mortgage_id}`, input)

  mortgageLoader.clearAll()

  return response.data.mortgage ? formatMortgage(response.data.mortgage) : null
}

export const deleteMortgage = async (mortgageId: string, caseId: string) => {
  const response = await request.delete<CdmDeleteMortgageCaseResponse>(`/mortgage/${mortgageId}/case/${caseId}`)

  mortgageLoader.clearAll()

  return response.data
}

export const getProductListByProductCodeAndLender = async (
  isBtl: boolean,
  productCode?: string | null,
  lender?: string | null,
) => {
  const lenderQuery = lender ? `&fq=lender:${lender}` : ''
  const response = await request.get<{ response: { docs: Array<MortgageProductCode> } }>(
    `/acre/local-sourcing/source?is_btl=${isBtl}&network=pms&q=doctype:mortgageproduct%20AND%20productCode:${productCode}*${lenderQuery}&fl=productCode,id,name,lender&rows=20`,
    {
      baseURL: envConfig.API1_URL,
    },
  )

  return response?.data?.response?.docs || []
}

export const sourceProductBySourcingProductId = async (
  isBtl: boolean,
  productId?: string | null,
  loanAmount?: Maybe<string>,
  valuationAmount?: Maybe<string>,
  isCurrent?: Maybe<boolean>,
) => {
  const response = await request.get<{ response: { docs: Array<SourcingMortgageState> } }>(
    `/acre/local-sourcing/source?&is_btl=${isBtl}&network=pms&q=id:${productId}&fl=*,[child%20limit=100]`,
    {
      baseURL: envConfig.API1_URL,
    },
  )

  if (!response?.data?.response?.docs?.[0]) {
    throw new GraphqlException(
      'Product details could not be retrieved by product code. Please enter the details manually.',
    )
  }

  return mapToLutherMortgageProduct(response.data.response.docs[0], loanAmount, valuationAmount, isCurrent)
}

export const updateMortgageHelper = async (id: string, input: MortgageInput) => {
  let mortgage: Mortgage | null = null
  const term = input.term || 0
  const termUnit = input.term_unit
  const status = input.status
  const startDate = input.mortgage_start_date

  if (status === MortgageStatus.StatusLenderProposed) {
    input.lender_proposed = true
  }

  //only update the end date if the status is current or lender proposed as these are the only manual edit case.
  if (status === MortgageStatus.StatusCurrent || status === MortgageStatus.StatusLenderProposed) {
    // This is a transition under mortgage_end_date is calculated by BE.
    if (startDate && term && termUnit) {
      input.mortgage_end_date = getEndDate(startDate, Number(term), termUnit as TermUnit)
    }

    mortgage = await updateMortgage(id, input)

    return {
      ...mortgage,
      mortgage_id: String(mortgage?.id),
      id: String(mortgage?.id),
    }
  }

  //if mortgage status is repaid or selected the start and end date does not need to be calculated
  //It would have already been calculated at the create stage.
  mortgage = await updateMortgage(id, input)

  return mortgage
}

export const createMortgageHelper = async (input: CreateMortgageInput) => {
  const { clientIds, caseId, mortgageInput, mortgageProductInput } = input
  // Set defaults/fallbacks for term and status
  const status = mortgageInput.status || MortgageStatus.InvalidStatus
  const termUnit = mortgageInput.term_unit || TermUnit.InvalidTermUnit
  let term = mortgageInput.term || 0

  let property: PropertyVersion | null = null
  let mortgageCase: CaseVersion | null = null
  let preferenceTargetProperty: string | null = null
  if (caseId) {
    mortgageCase = await fetchCase(caseId)
  }

  //check if the case is remortgage and the sourced product is status proposed
  if (
    (mortgageCase?.details.preference_mortgage_reason === MortgageReason.ReasonRemortgage ||
      mortgageCase?.details.preference_mortgage_reason === MortgageReason.ReasonBtlRemortgage) &&
    !(mortgageCase?.details.preference_term && mortgageCase?.details.preference_term > 0)
  ) {
    //if existing mortgage exists get the current mortgage
    let newMortgageTerm = getTermDateForRemortgage(
      mortgageProductInput.early_repayment_charge_periods || [],
      term,
      mortgageProductInput.initial_rate_period || '',
    )

    const updateCaseResponse = await updateCase({ preference_term: newMortgageTerm }, caseId!)
    if (updateCaseResponse) {
      CaseLoader.clear(caseId!).prime(caseId!, updateCaseResponse)
    }
  }

  // If property exists on the case, we'll fetch it so that we can get its value
  preferenceTargetProperty = mortgageCase?.details.preference_target_property || ''
  if (mortgageInput.property_secured_ids && mortgageInput.property_secured_ids[0]) {
    property = await fetchProperty(mortgageInput.property_secured_ids[0])
  } else if (preferenceTargetProperty) {
    property = await fetchProperty(preferenceTargetProperty)
  }

  const isCurrentMortgage = status === MortgageStatus.StatusCurrent
  const startDate = isCurrentMortgage ? mortgageInput.mortgage_start_date : getProposedStartDate(status)
  mortgageInput.mortgage_start_date = startDate
  mortgageInput.mortgage_end_date = getEndDate(startDate, term, termUnit)

  const targetPropertyValue = mortgageCase?.details.preference_target_property_value || ''
  const propertyValue = getPropertyValue(property, status, targetPropertyValue)
  mortgageInput.property_value = propertyValue

  // If the mortgage we're trying to add has a status of 'Current', then we need to link it to the case's target property
  // If it has a status if 'Proposed' or 'Lender proposed', we need to link it to the related sale property
  const targetId = mortgageCase?.details.preference_target_property || ''
  const saleId = mortgageCase?.details.preference_related_property_sale || ''
  if (shouldLinkToTarget(mortgageInput, mortgageCase)) mortgageInput.property_secured_ids?.push(targetId)
  if (shouldLinkToRelatedSale(mortgageInput, mortgageCase)) mortgageInput.property_secured_ids?.push(saleId)

  if (mortgageInput.status === MortgageStatus.StatusLenderProposed) {
    mortgageInput.lender_proposed = true
  }

  // Create the mortgage and mortgage product
  const mortgageProduct = mortgageProductInput && (await createMortgageProduct(mortgageProductInput))
  const mortgage = await createMortgage({ ...mortgageInput, client_ids: clientIds })

  if (!mortgage) {
    return null
  }

  // Link mortgage and product
  if (mortgageProduct?.product_id) {
    const addMortgageToProductInput = { mortgage_id: mortgage.id, product_id: mortgageProduct.product_id }
    await addMortgageToProduct(addMortgageToProductInput)
  }

  // Optionally link mortgage and case
  if (caseId) {
    const addMortgageToCaseInput: MortgageAndCaseInput = { mortgage_id: mortgage.id, case_id: caseId }
    await addMortgageToCase(addMortgageToCaseInput)
  }

  // We need to specify the 'id' and 'product_id' explicitly because, initially,
  // the mortgage has no associated product, therefore spreading it with '...'
  // won't provide the 'product_id'
  return {
    ...mortgage,
    id: mortgage.mortgage_id,
    mortgage_product_id: mortgageProduct?.product_id,
  }
}

export const shouldLinkToTarget = (
  { status, property_secured_ids }: MortgageInput,
  caseDetails: CaseVersion | null,
) => {
  const propertyTargetId = caseDetails?.details.preference_target_property || ''

  const hasTargetProperty = !!propertyTargetId
  const isProposed = status === MortgageStatus.StatusLenderProposed || status === MortgageStatus.StatusProposed
  const isLinkedToTarget = hasTargetProperty && !!(property_secured_ids || []).includes(propertyTargetId)

  return isProposed && hasTargetProperty && !isLinkedToTarget
}

export const shouldLinkToRelatedSale = (
  { status, property_secured_ids }: MortgageInput,
  caseDetails: CaseVersion | null,
) => {
  const propertyRelatedSaleId = caseDetails?.details.preference_related_property_sale || ''

  const hasTargetProperty = !!propertyRelatedSaleId
  const isCurrent = status === MortgageStatus.StatusCurrent
  const isLinkedToRelatedSale = hasTargetProperty && !!(property_secured_ids || []).includes(propertyRelatedSaleId)

  return isCurrent && hasTargetProperty && !isLinkedToRelatedSale
}

export const updateMortgages = async (input: UpdateMortgagesInput[] | null | undefined) => {
  let mortgages: Mortgage[] = []

  if (input) {
    // Currently BE chokes if calls happen async which could leave the entities in an inconsistent state
    // Someday when BE is optimised this needs to be promise.all
    for (let mortgage of input) {
      if (mortgage && mortgage?.mortgageId) {
        if (mortgage?.mortgageInput) {
          const updatedMortgage = await updateMortgage(mortgage.mortgageId, mortgage.mortgageInput)

          if (updatedMortgage) {
            mortgages.push(updatedMortgage)
          }
        }

        if (mortgage?.mortgageProductInput && mortgage?.mortgageProductId && mortgage?.mortgageId) {
          await updateMortgageProductHelper(
            mortgage.mortgageId,
            mortgage.mortgageProductId,
            mortgage.mortgageProductInput,
            mortgage.mortgageInput,
          )
        }
      }
    }
  }

  return mortgages
}
