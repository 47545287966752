import { deleteMortgage, fetchMortgages, updateMortgage } from '../../api/mortgage'
import { Maybe, MortgageAndCase } from '../../generated/resolvers'

const deleteMortgageHelper = async (mortgageId: string, caseId: string, isPropertyPortfolio?: Maybe<boolean>) => {
  // Mortgage must be disassociated from the property it's attached to before deleting it from the case
  // Portfolio properties do not need to be deleted from the case as they are not associated with the case
  const updatedMortgage = await updateMortgage(mortgageId, {
    property_secured_ids: null,
  })

  const mortgages = await fetchMortgages({ filter_case_id: caseId })

  // Make sure that the deleted mortgage gets also removed from the assumes_continuing array
  // on other mortgages
  if (mortgages && mortgages.length > 0) {
    // Currently BE chokes if calls happen async which could leave the entities in an inconsistent state
    // Someday when BE is optimised this needs to be promise.all
    for (let mortgage of mortgages) {
      // TS IGNORE, because GetMortgageResponseParent is not updated (and significantly more work is required to update it)
      // @ts-ignore
      if (mortgage.mortgage_id && mortgage.assumes_continuing?.includes(mortgageId)) {
        await updateMortgage(mortgage.mortgage_id, {
          // @ts-ignore
          assumes_continuing: mortgage.assumes_continuing.filter((id) => id !== mortgageId),
        })
      }
    }
  }

  if (isPropertyPortfolio) {
    return updatedMortgage as MortgageAndCase
  } else {
    return (await deleteMortgage(mortgageId, caseId)) as MortgageAndCase
  }
}

export default deleteMortgageHelper
