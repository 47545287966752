import DataLoader from 'dataloader'
import { GraphQLError } from 'graphql'

import { PropertyInput } from '../generated/resolvers'
import request from '../requesters/clientPortal'
import oathKeeper from '../requesters/oathKeeper'
import {
  CdmCreatePropertyResponse,
  CdmGetPropertyRequest,
  CdmGetPropertyResponse,
  CdmUpdatePropertyResponse,
} from '../service/luther/model'
import { UUID } from '../types'
import { formatProperty } from '../utils/schemaMapping/property'

export const addCaseUserProperty = async (input: PropertyInput) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.post<CdmCreatePropertyResponse>(`/property`, {
    property: {
      details: input,
    },
  })

  return response.data.property ? formatProperty(response.data.property) : null
}

export const fetchPropertyBatchFn = async (ids: UUID[]) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.get<CdmGetPropertyResponse>('/property', {
    params: {
      property_ids: ids,
      property_details: true,
      show_protected: true,
    },
  })

  const properties = response.data.properties

  return properties?.map(formatProperty) || null
}

export const PropertyLoaderCp = new DataLoader(async (ids: string[]) => {
  const properties = await fetchPropertyBatchFn(ids)

  if (!properties) {
    return ids.map(
      (id) =>
        new GraphQLError(`Property not found for id: ${id}`, {
          extensions: {
            code: 'PROPERTY_NOT_FOUND',
            status: 404,
            id,
          },
        }),
    )
  }

  return ids.map(
    (id) =>
      properties.find((property) => property.id === id) ||
      new GraphQLError(`Property not found for id: ${id}`, {
        extensions: {
          code: 'PROPERTY_NOT_FOUND',
          status: 404,
          id,
        },
      }),
  )
})

export const fetchPropertyCP = async (id: string) => {
  const result = await PropertyLoaderCp.load(id)

  if (result instanceof GraphQLError) {
    throw result
  }

  return result
}

export const fetchClientProperties = async (clientId: UUID) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.get<CdmGetPropertyResponse>('/property', {
    params: {
      property_details: true,
      filter_registered_owner_client_id: clientId,
    } as CdmGetPropertyRequest,
  })

  return response.data.properties ? response.data.properties.map((property) => formatProperty(property)) : null
}

export const ClientPropertyLoaderCp = new DataLoader(async (ids: string[]) =>
  Promise.all(ids.map((id) => fetchClientProperties(id))),
)

export const updatePropertyCP = async (input: PropertyInput, id: string) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.patch<CdmUpdatePropertyResponse>(`/property/${id}`, {
    details: input,
  })

  if (!response.data.property) {
    return null
  }

  const formattedProperty = formatProperty(response.data.property)
  PropertyLoaderCp.clear(id).prime(id, formattedProperty)

  if (input.registered_owners_details) {
    input.registered_owners_details.map(async (clientId) => {
      const existingProperties = await ClientPropertyLoaderCp.load(clientId)

      let updatedProperties = null
      if (existingProperties) {
        updatedProperties = existingProperties.map((property) => {
          if (property.property_id === formattedProperty.id) {
            return formattedProperty
          } else {
            return property
          }
        })
      }
      ClientPropertyLoaderCp.clear(clientId).prime(clientId, updatedProperties)
    })
  } else {
    ClientPropertyLoaderCp.clearAll()
  }

  return formattedProperty
}

// GetProperty
// https://crm.dev.acreplatforms.co.uk/api_documentation/redoc.html#operation/GetProperty
export const getPropertyCP = async (params: CdmGetPropertyRequest) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.get<CdmGetPropertyResponse>('/property', { params })
  return response?.data
}

export const addPropertyCp = async (input: PropertyInput) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.post<CdmCreatePropertyResponse>(`/property`, {
    property: {
      details: input,
    },
  })

  const { property } = response.data

  if (!property) {
    return null
  }

  if (property?.details?.registered_owners_details) {
    property.details.registered_owners_details.map(async (clientId) => {
      const alreadyLoadedProperties = await ClientPropertyLoaderCp.load(clientId)
      const allProperties =
        alreadyLoadedProperties && !alreadyLoadedProperties.find((prop) => prop.property_id === property.property_id)
          ? [...alreadyLoadedProperties, formatProperty(property)]
          : [formatProperty(property)]
      ClientPropertyLoaderCp.clear(clientId).prime(clientId, allProperties)
    })
  }

  if (property.property_id) {
    PropertyLoaderCp.clear(property.property_id).prime(property.property_id, formatProperty(property))
  }

  return formatProperty(property)
}
