import {
  EmailTemplate,
  EmailTemplateInput,
  OrganisationTemplateInput,
  Template,
  TemplateType,
} from '../generated/resolvers'
import request from '../requesters/default'
import { CdmGetTemplateResponse } from '../service/luther'
import { UUID } from '../types'
import { formatTemplates } from '../utils/schemaMapping/template'

// TODO: FRON-1266 when returns parent type matches the schema then introduce loader as per bellow
// export const TemplatesLoader = new DataLoader((ids: string[]) => {
//   return fetchTemplates(ids, {})
// })

export const fetchTemplatesForOrg = async (id: UUID | null | undefined) => {
  const response = await request.get<CdmGetTemplateResponse>('/template', {
    params: {
      filter_organisation_id: id,
      template_details: true,
    },
  })

  return response.data.templates ? formatTemplates(response.data.templates) : null
}

export const fetchTemplates = async (template_ids: UUID[]) => {
  const response = await request.get<CdmGetTemplateResponse>('/template', {
    params: {
      template_ids,
      template_details: true,
    },
  })

  return response.data.templates ? formatTemplates(response.data.templates) : null
}

export const postClientInviteTemplate = async (body: EmailTemplateInput) => {
  const response = await request.post<EmailTemplate>('/email_template', body)
  return response?.data
}

export const fetchTemplatesOfType = async (id: UUID | null | undefined, filter_name: TemplateType) => {
  const response = await request.get<{ templates: Template[] }>('/template', {
    params: {
      filter_organisation_id: id,
      filter_name,
      template_details: true,
    },
  })
  // @ts-ignore We will not resolve organisation here because it comes further down the tree
  return formatTemplates(response.data.templates)
}

export const createOrganisationTemplate = async (input: OrganisationTemplateInput) => {
  const response = await request.post<{ template: Template & { template_id: string } }>(`/template`, input)
  return { ...response.data.template, id: response.data.template.template_id }
}
