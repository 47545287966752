import React, { CSSProperties, PropsWithChildren } from 'react'
import { Box } from '@mui/material'

export type BlockProps = PropsWithChildren<{
  small?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  medium?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  large?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  flex?: Boolean
  alignItems?: CSSProperties['alignItems']
  justifyContent?: CSSProperties['justifyContent']
  flexDirection?: CSSProperties['flexDirection']
}>

const Block = ({
  small = 12,
  medium = 12,
  large = 12,
  children,
  flex,
  alignItems,
  justifyContent,
  flexDirection,
}: BlockProps) => {
  const flexProps = flex && { alignItems, justifyContent, flexDirection, display: 'flex' }
  return <Box sx={{ width: [small / 12, medium / 12, large / 12], px: [1, 2, 3], ...flexProps }}>{children}</Box>
}

export default Block
