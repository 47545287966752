import request from '../requesters/default'
import oathKeeper from '../requesters/oathKeeper'
import { ADDRESS_LOOKUP_API_URL } from '../resolvers/util'
import { normaliseAddressFind, PWCAddressFindSuggestions } from '../utils/normalisers/normaliseAddressFind '

// IMPORTANT: This does not charge on every request
const fetchAddressLookupList = async (searchFragment: string, pathFilter: string) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.get<PWCAddressFindSuggestions[]>('/find', {
    baseURL: ADDRESS_LOOKUP_API_URL,
    params: {
      pathfilter: pathFilter,
      query: searchFragment,
      format: 'json',
    },
  })

  const jsonResponse = response.data
  const hasResults = jsonResponse.length > 0
  return hasResults ? normaliseAddressFind(jsonResponse) : []
}

export default fetchAddressLookupList
