import { isNil } from 'lodash'

import { CanBeAddedToLoan, Maybe, MortgageProductFee } from '../../../generated/resolvers'
import { currentMortgageProductFeeTypes } from '../../currentMortgageProductFeeTypes'
import { mapFeeTypeToCanBeAddedToLoan, NonDeprecatedMortgageProductFeeType } from '../mapFeeTypeToCanBeAddedToLoan'
import { mapFeeTypeToFeeTiming } from '../mapFeeTypeToFeeTiming'
import { deleteRedundantValuationFees } from './deleteRedundantValuationFees'
import { getAmount } from './getAmount'
import { mapToLutherFeeTiming } from './mapToLutherFeeTiming'
import { mapToLutherFeeType } from './mapToLutherFeeType'
import { SourcingMortgageState } from './mapToLutherMortgage.fixtures'

export const mapFees = ({
  fees,
  loanAmount,
  valuationAmount,
  isCurrent,
}: {
  fees: SourcingMortgageState['providerFee']
  loanAmount?: Maybe<number>
  valuationAmount?: Maybe<number>
  isCurrent?: Maybe<boolean>
}): MortgageProductFee[] | undefined => {
  const compressedFees = deleteRedundantValuationFees(fees, valuationAmount, loanAmount)

  return compressedFees.reduce((acc, fee) => {
    // Fee can either be an amount or a percentage (of the loan amount)
    const feeAmount = fee['providerFee.amount']
    const feePercentage = fee['providerFee.percentage']
    const feeType = fee['providerFee.type']
    const feeTiming = fee['providerFee.timing']

    const convertedFeeAmount = getAmount({ amount: feeAmount, percentage: feePercentage, loanAmount })

    if (isNil(feeType) || isNil(convertedFeeAmount)) {
      return acc
    }

    const mappedFeeType = mapToLutherFeeType[feeType]
    if (!mappedFeeType) {
      return acc
    }

    // If the product we're fetchin info for is a current product,
    // then only return the fees of fee type that are relevant to a current mortgage product
    if (isCurrent && !currentMortgageProductFeeTypes.includes(mappedFeeType)) {
      return acc
    }

    return [
      ...acc,
      {
        // *100 to convert to pence
        amount: Math.floor(convertedFeeAmount * 100).toString(),
        fee_type: mappedFeeType,
        timing: feeTiming
          ? mapToLutherFeeTiming[feeTiming]
          : mapFeeTypeToFeeTiming[mappedFeeType as NonDeprecatedMortgageProductFeeType],
        can_be_added_to_loan: isCurrent
          ? CanBeAddedToLoan.PaySeparately
          : mapFeeTypeToCanBeAddedToLoan[mappedFeeType as NonDeprecatedMortgageProductFeeType],
      },
    ]
  }, [] as MortgageProductFee[])
}
