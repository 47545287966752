import { Organisation } from '../generated/resolvers'
import request from '../requesters/clientPortal'
import oathKeeper from '../requesters/oathKeeper'
import { CdmGetOrganisationResponse } from '../service/luther'
import { formatOrganisationResponse } from '../utils/schemaMapping/organisations'

export const fetchOrganisationByExtIdCP = async (id: string): Promise<Organisation | null> => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.get<CdmGetOrganisationResponse>('/organisation', {
    params: { organisation_ext_identifiers: id },
  })

  const organisation = response.data.organisations?.[0]

  if (organisation) {
    return formatOrganisationResponse(organisation)
  }

  return null
}

export const fetchOrganisationsAdministeredByCP = async (administeredBy: string) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.get<CdmGetOrganisationResponse>('/organisation', {
    params: {
      filter_administered_by_org_id: administeredBy,
      page_size: 1000,
    },
  })

  if (response.data.organisations && response.data.organisations.length > 0) {
    return response.data.organisations.map(formatOrganisationResponse)
  }

  return []
}
