import { Address } from '../generated/resolvers'
import request from '../requesters/clientPortal'
import oathKeeper from '../requesters/oathKeeper'
import { CountryCode } from '../types'

// For more information about what this api returns
// https://developers.alliescomputing.com/postcoder-web-api/address-lookup/international-addresses
// e.g. Empty address fields are not returned in the response, so all fields should be treated as optional
type AddressToNormalise = {
  addressline1: string
  addressline2: string
  addressline3: string
  summaryline: string
  organisation: string
  buildingname: string
  premise: string
  street: string
  dependentlocality: string
  posttown: string
  postcode: string
  county: string
  country: string
  field_updates?: PropertyFieldUpdates
  property: PropertyStub
}

export type PropertyFieldUpdates = {
  property_tenure?: string
  property_type?: string
  property_sub_type?: string
  price_paid?: number
  year_built?: number
  property_location?: string
  bedrooms?: number
  floor_number?: number
  storeys?: number
}

type PropertyStub = {
  property_id: number
}

export type AddressAndFieldUpdates = Address & {
  field_updates?: PropertyFieldUpdates
  property_id?: number
}

const normaliseAddress = ({
  addressline1,
  addressline2,
  addressline3,
  posttown,
  postcode,
  country,
  county,
  field_updates,
  property,
}: AddressToNormalise): AddressAndFieldUpdates => {
  return {
    line_1: addressline1 || '',
    line_2: addressline2 || '',
    line_3: addressline3 || '',
    town: posttown || '',
    postcode: postcode || '',
    country: country || '',
    county: county || '',
    field_updates: field_updates || {},
    property_id: property.property_id || 0,
  }
}

export const normaliseAddressLookup = (
  srcObject: AddressToNormalise[],
  countryCode: CountryCode = 'GB',
): AddressAndFieldUpdates[] => {
  return srcObject.map((item) => normaliseAddress({ ...item, country: countryCode }))
}

// IMPORTANT: This charges on every request
const fetchAddressLookupCP = async (lines: number = 3, searchFragment: string, addressId: string) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const requester = useOathKeeper === 'true' ? oathKeeper : request
  const response = await requester.get<AddressToNormalise[]>(
    `/propertydata/retrieve?id=${addressId}&query=${searchFragment}&format=json&lines=${lines}`,
  )
  const jsonResponse = response.data
  const hasResults = jsonResponse.length > 0
  return hasResults ? normaliseAddressLookup(jsonResponse) : []
}

export default fetchAddressLookupCP
