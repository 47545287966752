import configs from '@acre/config'
const { API1_URL } = configs
import { NotificationToken } from '../generated/resolvers'
import request from '../requesters/default'

export const fetchAuthToken = async () => {
  const response = await request.get<NotificationToken>(`/notifications/ws/auth`, {
    baseURL: `${API1_URL}/acre`,
  })

  return response.data
}
