import React from 'react'
import { Container, ContainerProps } from '@mui/material'
import { styled } from '@mui/material/styles'

import type { PageLayoutVariant } from './PageLayout.types'

export interface PageLayoutContainerRootProps extends Omit<ContainerProps, 'variant'> {
  variant?: PageLayoutVariant
  rightDrawerOpen?: boolean
}

// Page layout container is used across the entire app - update with caution!
const PageLayoutContainerRoot = styled(Container, {
  name: 'PageLayout',
  slot: 'Container',
  overridesResolver: (_props, styles) => styles.container,
  shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'rightDrawerOpen',
})<PageLayoutContainerRootProps>(({ theme, variant, disableGutters }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  minHeight: `calc(100vh - ${theme.spacing(18)})`,
  marginTop: theme.spacing(4),

  [theme.breakpoints.up('xs')]: {
    paddingLeft: disableGutters ? 0 : theme.spacing(4),
    paddingRight: disableGutters ? 0 : theme.spacing(4),
  },

  [theme.breakpoints.up('xl')]: {
    paddingLeft: disableGutters ? 0 : theme.spacing(8),
    paddingRight: disableGutters ? 0 : theme.spacing(8),
    ...((variant === 'two-column' || variant === 'two-column-with-sidebar') && { marginLeft: 0 }),
  },
}))

const PageLayoutContainer = React.memo(PageLayoutContainerRoot)

export default PageLayoutContainer
