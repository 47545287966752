import { LoadingButtonProps } from '@mui/lab'
import { capitalize } from '@mui/material'
import type { StyledOptions } from '@mui/styled-engine'
import { MuiStyledOptions } from '@mui/system'

// Copied from MuiButton, this maps Button props to theme.components.Button.styleOverrides
//
// For more information on overridesResolver, see:
// Official Docs: https://mui.com/system/styled
// Useful Docs: https://www.notion.so/acre/Using-styled-e856ef0c20b445fda28f7964414d7cc0#deb271e37d7a47e593202049cd6e2a2e
const overridesResolver: MuiStyledOptions['overridesResolver'] = (props, styles) => {
  return [
    styles.root,
    styles[props.variant],
    styles[`${props.variant}${capitalize(props.color || 'inherit')}`],
    styles[`size${capitalize(props.size)}`],
    styles[`${props.variant}Size${capitalize(props.size)}`],
    props.color === 'inherit' && styles.colorInherit,
    props.disableElevation && styles.disableElevation,
    props.fullWidth && styles.fullWidth,
  ]
}

export const getButtonStyledOptions = (name: string): StyledOptions & MuiStyledOptions => ({
  name,
  slot: 'Root',
  overridesResolver,
  shouldForwardProp: (prop) => prop !== 'indicator' && prop !== 'selectionFollowsFocus' && prop !== 'textColor',
})

export const getLoadingIndicatorSize = (props: LoadingButtonProps) => {
  if (props.variant === 'text' || props.variant === 'link') {
    return '1.2em'
  }

  if (props.size === 'small') {
    return '1.2em'
  }

  return '1.6em'
}
