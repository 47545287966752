import { createCalendarEvent, getFreeBusy } from '../api/communications'
import { Resolvers } from '../generated/resolvers'

const NylasResolver: Resolvers = {
  Query: {
    getFreeBusyTimes: async (_, { start, end, emails }) => {
      return await getFreeBusy(start, end, emails)
    },
  },
  Mutation: {
    createCalendarEvent: async (_, { input }) => await createCalendarEvent(input),
  },
}

export default NylasResolver
