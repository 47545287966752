import { CaseDetailsFlagFlagType, InputMaybe } from '../main'
import request from '../requesters/default'

export const addManualFlagToCase = async (
  case_id: InputMaybe<string>,
  flag_type: InputMaybe<CaseDetailsFlagFlagType>,
): Promise<any> => {
  const payload = { case_id, flag_type }

  const response = await request.post(`/case/${case_id}/flag`, payload)
  return response.data
}
