import { ApolloClient, fromPromise, NormalizedCacheObject, Observable, ServerError } from '@apollo/client'
import { NetworkError } from '@apollo/client/errors'
import { ErrorResponse } from '@apollo/client/link/error'
import { ServerParseError } from '@apollo/client/link/http/parseAndCheckHttpResponse'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { GraphQLError } from 'graphql'
import Cookies from 'js-cookie'

import { ErrorProvider, Locales, messagesAlerts } from '@acre/utils'
import { LutherException } from '@acre/graphql'
import envConfig from '@acre/config'

import debug from '../utils/debug/debug'

const errorMessages = messagesAlerts[Locales.GB].alerts

const log = debug('login:authRedirectErrorLink')
//
// Redirects the user to the login screen if they get a 401
//
const authRedirectErrorLink = (function () {
  // Placeholder for refresh
  let refreshRequest: Observable<AxiosResponse<any>> | null = null

  return (
    { response, forward, graphQLErrors, networkError, operation }: ErrorResponse,
    client: ApolloClient<NormalizedCacheObject>,
  ) => {
    const errors = graphQLErrors?.length ? graphQLErrors : ([networkError] as const)
    if (errors.length && errors[0] === undefined) return

    const has401Error = (errors as GraphQLError[] | NetworkError[]).some((error) =>
      isForbiddenError(error, networkError ? 'network' : 'graphql'),
    )
    const useOathKeeper = sessionStorage.getItem('useOathKeeper') === 'true'
    const initialLocation = window.location.pathname
    const isLoginRoute = !!initialLocation.match(/(?:ory-|client_)?login/) || initialLocation === '/'
    const isRefreshRoute = initialLocation === '/v1/user/refresh'
    log(
      `useOathKeeper: ${useOathKeeper}, initialLocation: ${initialLocation}, isLoginRoute: ${isLoginRoute}, isRefreshRoute: ${isRefreshRoute}, has401Error: ${has401Error}`,
    )

    // We want to avoid showing the signin prompt if the user
    // lands on either the login page or /
    if (has401Error && !isLoginRoute && !isRefreshRoute) {
      // https://github.com/apollographql/apollo-link/issues/646
      // Use `fromPromise` and `flatMap` to ensure it actually waits
      // for the request to finish and retries
      if (!useOathKeeper) {
        if (refreshRequest === null) {
          const refreshUrl = envConfig.CLIENT_PORTAL_API_URL + '/auth/refresh'
          log('Creating refresh request to ', refreshUrl)
          refreshRequest = fromPromise(
            axios({
              url: refreshUrl,
              method: 'POST',
              withCredentials: true,
              validateStatus: () => true, // Always resolve
            }),
          )
        }

        return refreshRequest.flatMap((value) => {
          refreshRequest = null
          if (value.status === 200) {
            log('Refreshed token')
            return forward(operation)
          } else {
            log('Authentication failed with status:', value.status)
            const err = new Error(errorMessages.login)
            ErrorProvider.showError(err)
            client.clearStore().then(() => {
              log('Clearing storage and resetting useOathKeeper to ', useOathKeeper)
              sessionStorage.clear()
              sessionStorage.setItem('useOathKeeper', useOathKeeper ? 'true' : 'false')
              Cookies.set('login_redirect', location.pathname)
              window.location.href = envConfig.CLIENT_PORTAL_LOGOUT_URL
            })
            return fromPromise(Promise.reject(err))
          }
        })
      } else {
        sessionStorage.clear()
        sessionStorage.setItem('useOathKeeper', useOathKeeper ? 'true' : 'false')
        Cookies.set('login_redirect', location.pathname)
        window.location.href = `${window.location.origin}/ory-login${initialLocation !== '/' ? '?loggedout=true' : ''}`
        return fromPromise(Promise.reject(errors[0]))
      }
    }

    if (response) {
      response.errors = []
    }
  }
})()

export default authRedirectErrorLink

export const isForbiddenError = <
  T extends 'graphql' | 'network',
  E extends T extends 'graphql' ? GraphQLError : NetworkError,
>(
  err: E,
  type: T,
) => {
  if (type === ('graphql' as const)) {
    const { extensions, originalError } = err as GraphQLError
    if (extensions.status === 401 || (originalError as LutherException)?.statusCode === 401) {
      log('Error is 401 from GraphQLExtensions')
      return true
    }
    if (originalError && Object.prototype.hasOwnProperty.call(originalError, 'response')) {
      log('Error is AxiosError')
      const { response } = originalError as AxiosError
      return response && response.status === 401
    } else if ((originalError as LutherException)?.statusCode === 401) {
      log('originalError is 401')
      return true
    } else if (originalError && originalError.name === 'LutherException') {
      log('originalError is LutherException')
      const { statusCode } = originalError as LutherException
      return statusCode === 401
    }
  } else if (type === ('network' as const)) {
    log('error is NetworkError')
    if ((err as ServerError).statusCode === 401) {
      log('error is ServerError with 401')
      return true
    }
    if ((err as ServerParseError).statusCode === 401) {
      log('error is ServerParseError with 401')
      return true
    }
  }
  return false
}
