import React, { Component, Fragment, ReactElement } from 'react'
import * as Sentry from '@sentry/react'
import { AxiosError } from 'axios'
import { GraphQLError } from 'graphql'

import { ExceptionType, LutherException } from '@acre/graphql'

import { errorMessages, formatErrorMessage } from './errorHandler'
import { chooseSecurityViolationMessage } from './securityViolation'

type Props = {
  children: ReactElement | ReactElement[]
  alertSlot: React.ElementType
  alertId?: string
}

type State = {
  error: string | null
}

export default class ErrorProvider extends Component<Props, State> {
  static showError: (err: GraphQLError | AxiosError | Error) => void
  static showErrors: (err: (Readonly<GraphQLError> | AxiosError | Error)[]) => void
  static hideError: () => void

  static defaultProps = {
    alertId: 'globalError',
  }

  constructor(props: Props) {
    super(props)
    ErrorProvider.showError = this._show
    ErrorProvider.showErrors = this._showMany
    ErrorProvider.hideError = this._hide

    this.state = {
      error: null,
    }
  }

  _show = (err: GraphQLError | AxiosError | Error) => {
    const errorMessage = parseError(err)

    this.setState({ error: errorMessage })
  }

  _showMany = (errors: (GraphQLError | AxiosError | Error)[]) => {
    const collectedErrors = errors.reduce(
      (acc, error) => {
        if (!error.message) {
          return acc
        }

        if (error.message in acc) {
          acc[error.message].count += 1
        } else {
          const parsedError = parseError(error) || 'Unknown error'
          acc[parsedError] = { count: 1, error }
        }

        return acc
      },
      {} as Record<string, { count: number; error: GraphQLError | AxiosError | Error }>,
    )

    const errorMessages = Object.keys(collectedErrors).map((message) => {
      const error = collectedErrors[message]
      return error.count > 1 ? `${message} (${error.count})` : message
    })

    this.setState({ error: errorMessages.join('\r') })
  }

  _hide = () => {
    this.setState({ error: null })
  }

  render() {
    const Alert = this.props.alertSlot

    if (this.state.error) {
      Sentry.captureMessage(`Error alert displayed to user: ${this.state.error}`, 'error')
    }

    return (
      <Fragment>
        {this.props.children}
        {this.state.error && <Alert id={this.props.alertId} onClick={this._hide} label={this.state.error} />}
      </Fragment>
    )
  }
}

const getLutherException = (err: GraphQLError | AxiosError | Error) => {
  if ('extensions' in err && 'exception' in err.extensions) {
    return err.extensions.exception as LutherException
  }

  if ('originalError' in err && err.originalError && 'type' in err.originalError) {
    return err.originalError as LutherException
  }
}

const parseError = (err: GraphQLError | AxiosError | Error) => {
  let errorMessage: string | null = null

  const lutherException = getLutherException(err)

  if (lutherException) {
    const type: ExceptionType = lutherException.type
    switch (type) {
      case ExceptionType.Infrastructure:
        errorMessage = formatErrorMessage(errorMessages.infrastructure, {
          id: lutherException.id,
        })
        break
      case ExceptionType.ServiceNotAvailable:
        errorMessage = formatErrorMessage(errorMessages.serviceUnavailable, {
          id: lutherException.id,
          description: lutherException.description,
        })
        break
      case ExceptionType.SecurityViolation:
        errorMessage = chooseSecurityViolationMessage(
          lutherException.statusCode || 0,
          lutherException.id,
          lutherException.description,
        )
        break
      case ExceptionType.Business:
        errorMessage = formatErrorMessage(errorMessages.business, {
          description: lutherException.description,
        })
        break
      default:
        errorMessage = formatErrorMessage(errorMessages.generic, {
          id: lutherException.id,
        })
    }
  } else if (err instanceof GraphQLError) {
    if (err.message.includes('insufficient permissions')) {
      errorMessage = errorMessages.unauthorised
    } else {
      errorMessage = err.message
    }
  } else if (!Object.values(errorMessages).includes(err.message) && !err.message) {
    errorMessage = errorMessages.client
  } else {
    errorMessage = err.message || null
  }

  return errorMessage
}
