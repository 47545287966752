import DataLoader from 'dataloader'

import { MortgageSourcingInput, MortgageSourcingReason, MortgageType, SourceResponse } from '../generated/resolvers'
import request from '../requesters/default'
import { CdmGetAcreMortgageProductResponse, CdmGetMortgageProductDetailsResponse } from '../service/luther/model'

export const MortgageProductLoader = new DataLoader((ids: string[]) =>
  Promise.all(ids.map((id) => fetchMortgageProductById(id))),
)

// This is used to access additional information for a single product
// yielded by sourcing. These products do not have a product_id, but
// a product_code instead
export const fetchSourcedMortgageProduct = async (
  productCode: string,
  reasonForMortgage: MortgageSourcingReason,
  mortgageType: MortgageType,
) => {
  const response = await request.get<CdmGetMortgageProductDetailsResponse>(`/mortgage_product/source/${productCode}`, {
    params: {
      reason_for_mortgage: reasonForMortgage,
      mortgage_type: mortgageType,
    },
  })
  return response.data
}

// This is used for mortgage products that have either been created
// by the user (existing mortgages, product transfers, etc) or sourced
// mortgage products that have been linked to a case via
// an instantiated mortgage
export const fetchMortgageProductById = async (productId: string) => {
  const response = await request.get<CdmGetAcreMortgageProductResponse>(`/mortgage_product/${productId}`)
  return response.data
}

export const sourceMortgages = async (input: MortgageSourcingInput) => {
  const response = await request.post<SourceResponse>(`/mortgage_product/source`, {
    source_details: input,
  })
  return {
    ...response.data,
    serialised_data: JSON.stringify(response.data),
  }
}
