import { InMemoryCache } from '@apollo/client'

export const cacheConfig = {
  addTypename: true,
  typePolicies: {
    propertiesCp: { keyFields: ['property_id', 'id'] },
    Note: { keyFields: ['note_id'] },
  },
}

export default new InMemoryCache(cacheConfig)
