import { getCreditReport } from '../api/checks'
import { Resolvers } from '../generated/resolvers'

const CreditReportResolvers: Resolvers = {
  Query: {
    creditReport: async (_, { useClientApi, clientId }) => {
      return await getCreditReport(useClientApi, clientId)
    },
  },
}

export default CreditReportResolvers
