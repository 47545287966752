import { MortgageProduct, ProductMatchStatus } from '../../generated/resolvers'
import { CdmGetAcreMortgageProductResponse, CdmMortgageProductDetails } from '../../service/luther'
import { convertFromLutherPercentage, convertPoundsToPence } from '../formatNumber'

export const formatGetAcreMortgageProductResponseParentAsMortgageProductResponse = (
  response: CdmGetAcreMortgageProductResponse,
): MortgageProduct => {
  const {
    product_match_status,
    initial_rate_period_months,
    max_ltv_available,
    buy_to_let_rental_income_calculation_rate,
    max_overpayment_percent_no_erc,
    remort_home_improv_max_ltv,
    remort_debt_con_max_ltv,
    remort_bus_purpose_max_ltv,
    remort_hol_cars_max_ltv,
    ...product_details
  } = response.product_details as Required<CdmMortgageProductDetails>

  return {
    ...product_details,
    id: response.product_id,
    initial_rate_period_months: initial_rate_period_months || null,
    product_match_status:
      product_match_status === ProductMatchStatus.Available
        ? ProductMatchStatus.Available
        : ProductMatchStatus.NearMiss,
    // TODO FRON-1961 Remove these formattings once Luther have fixed it on their end
    max_ltv_available: max_ltv_available,
    remort_home_improv_max_ltv: convertFromLutherPercentage(remort_home_improv_max_ltv),
    remort_debt_con_max_ltv: convertFromLutherPercentage(remort_debt_con_max_ltv),
    remort_bus_purpose_max_ltv: convertFromLutherPercentage(remort_bus_purpose_max_ltv),
    remort_hol_cars_max_ltv: convertFromLutherPercentage(remort_hol_cars_max_ltv),
    buy_to_let_rental_income_calculation_rate: convertFromLutherPercentage(buy_to_let_rental_income_calculation_rate),
    max_overpayment_percent_no_erc: convertFromLutherPercentage(max_overpayment_percent_no_erc),
  } as MortgageProduct
}

export const formatSourcedMortgageProductResponseList = (products: MortgageProduct[]): MortgageProduct[] =>
  products.map((item) => ({
    ...item,
    // TODO FRON-1961 Remove these formatting's once Luther have fixed it on their end
    initial_monthly_payment: Number(convertPoundsToPence(item.initial_monthly_payment?.toString())),
    max_ltv_available: convertFromLutherPercentage(item.max_ltv_available),
  }))

export const formatSourcedMortgageProductResponseDetails = (details: CdmMortgageProductDetails): MortgageProduct =>
  ({
    ...details,
    // TODO FRON-1961 Remove these formattings once Luther have fixed it on their end
    max_ltv_available: convertFromLutherPercentage(details.max_ltv_available),
    remort_home_improv_max_ltv: convertFromLutherPercentage(details.remort_home_improv_max_ltv),
    remort_debt_con_max_ltv: convertFromLutherPercentage(details.remort_debt_con_max_ltv),
    remort_bus_purpose_max_ltv: convertFromLutherPercentage(details.remort_bus_purpose_max_ltv),
    remort_hol_cars_max_ltv: convertFromLutherPercentage(details.remort_hol_cars_max_ltv),
    buy_to_let_rental_income_calculation_rate: convertFromLutherPercentage(
      details.buy_to_let_rental_income_calculation_rate,
    ),
    max_overpayment_percent_no_erc: convertFromLutherPercentage(details.max_overpayment_percent_no_erc),
  }) as MortgageProduct
