import { get, set } from 'lodash'

/**
 * Replace an object with an array that includes that object at a relative path wihin that object
 * @param obj An object
 * @param path Path within object that you want to replace the object with an array
 */
export default (obj: Object, path: string): void => {
  const defaultValue = get(obj, path)

  if (defaultValue) {
    set(obj, path, Array.isArray(defaultValue) ? defaultValue : [defaultValue])
  }
}
