import fetchAddressLookup from '../api/address_lookup'
import fetchAddressLookupList from '../api/address_lookup_list'
import fetchAddressLookupSummary from '../api/address_lookup_summary'
import fetchAddressLookupCP from '../api_client_portal/address_lookup'
import fetchAddressLookupListCP from '../api_client_portal/address_lookup_list'
import fetchAddressLookupSummaryCP from '../api_client_portal/address_lookup_summary'
import { Resolvers } from '../generated/resolvers'

const AddressLookupResolver: Resolvers = {
  Query: {
    // @ts-ignore - Weird types, TODO
    addressLookup: (_, { searchFragment, id }) => fetchAddressLookup(3, id, searchFragment),

    addressLookupSummary: (_, { searchFragment }) => fetchAddressLookupSummary(searchFragment),
    addressLookupList: (_, { searchFragment, pathFilter }) => fetchAddressLookupList(searchFragment, pathFilter),

    // @ts-ignore - Weird types, TODO
    addressLookupCP: (_, { searchFragment, id }) => fetchAddressLookupCP(3, searchFragment, id),
    // @ts-ignore - Weird types, TODO
    addressLookupSummaryCP: (_, { searchFragment }) => fetchAddressLookupSummaryCP(searchFragment),
    // @ts-ignore - Weird types, TODO
    addressLookupListCP: (_, { searchFragment, pathFilter }) => fetchAddressLookupListCP(searchFragment, pathFilter),
  },
}

export default AddressLookupResolver
