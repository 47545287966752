import config from '@acre/config'

import {
  createClientOrganisationRelationship,
  createOrganisationTemplate,
  getDocument,
  getDocumentSummaries,
  postClientInviteTemplate,
  renderTemplate,
} from '../api'
import { ClientOrganisationRelationshipType, Resolvers, TemplateType } from '../generated/resolvers'
import { CdmRenderTemplateRequest } from '../service/luther/model'

const TemplateResolver: Resolvers = {
  Mutation: {
    postClientInvites: async (
      _,
      { emailInput, templateInput, organisationPrivacyPayloadInput, creditReportPayloadInput },
    ) => {
      // filter out client ids that already have client portal idd generated
      let clientsIdsThatNeedIdds: string[] = []

      // filter out client ids that already have org privacy policy generated
      let clientsIdsThatNeedOrgPrivacyPolicy: string[] = []

      if (templateInput?.client_ids) {
        for (const clientId of templateInput.client_ids) {
          const clientDocuments = await getDocumentSummaries({ filter_owning_client_id: clientId })
          const hasExistingCpIdd = clientDocuments?.documents?.some(
            (document) => document.template_type === TemplateType.ClientPortalIdd,
          )
          if (!hasExistingCpIdd) {
            clientsIdsThatNeedIdds.push(clientId)
          }
          const existingOrgPrivacyPolicies = clientDocuments?.documents?.some(
            (document) => document.template_type === TemplateType.OrganisationPrivacyNotice,
          )

          if (!existingOrgPrivacyPolicies) {
            clientsIdsThatNeedOrgPrivacyPolicy.push(clientId)
          }

          // if creditReportPayloadInput was passed Credit Report Idd needs to be created if it doesn't exists
          if (creditReportPayloadInput) {
            let creditReportIDD = null

            const existingCpIdds = clientDocuments?.documents?.filter((document) => {
              return document.template_type === TemplateType.ClientPortalIdd
            })

            const existingIdds = clientDocuments?.documents?.filter((document) => {
              return document.template_type === TemplateType.Idd
            })

            // check existing CP IDDs for the presence of a Credit IDD
            if (existingCpIdds) {
              for (const iddDocument of existingCpIdds) {
                if (!creditReportIDD && iddDocument.document_id) {
                  const iddDetails = await getDocument(iddDocument.document_id, {
                    document_details: true,
                  })
                  // if document has ACRE_SUPPORT_ORG_ID in org ids then it is Credit Report Idd
                  if (iddDetails?.document?.rendered_organisation_ids?.includes(config.ACRE_SUPPORT_ORG_ID)) {
                    creditReportIDD = iddDetails
                  }
                }
              }
            }

            // if no Credit IDD is found in the existing CP IDDs, then check the other existing IDDs
            if (!creditReportIDD && existingIdds) {
              for (const iddDocument of existingIdds) {
                if (!creditReportIDD && iddDocument.document_id) {
                  const iddDetails = await getDocument(iddDocument.document_id, {
                    document_details: true,
                  })
                  // if document has ACRE_SUPPORT_ORG_ID in org ids then it is Credit Report IDD
                  if (iddDetails?.document?.rendered_organisation_ids?.includes(config.ACRE_SUPPORT_ORG_ID)) {
                    creditReportIDD = iddDetails
                  }
                }
              }
            }

            // if an existing Credit IDD is not found, then generate new one via the client org relationship endpoint
            if (!creditReportIDD) {
              const input = {
                client_id: clientId,
                org_id: creditReportPayloadInput.organisation_id,
                relationship_type: ClientOrganisationRelationshipType.ReferredAdvice,
              }
              await createClientOrganisationRelationship(input.client_id, input.org_id!, input)
            }
          }
        }
      }

      // update template payload with filtered ids
      templateInput.client_ids = clientsIdsThatNeedIdds
      // check if templates already been added
      const hasIddsForAllClients = templateInput.client_ids?.length === 0

      // as first step render needed client portal idd template
      const clientPortalIdd = !hasIddsForAllClients && (await renderTemplate(templateInput as CdmRenderTemplateRequest))

      // if client portal idd is rendered successfully or if all idds have already been generated send invite email
      if ((clientPortalIdd && clientPortalIdd.document?.document_id) || hasIddsForAllClients) {
        const clientInvite = await Promise.all(emailInput.map(async (email) => await postClientInviteTemplate(email)))
        // if invites were sent successfully generate missing org privacy policies
        if (clientInvite) {
          // update org payload with filtered ids
          organisationPrivacyPayloadInput.client_ids = clientsIdsThatNeedOrgPrivacyPolicy
          // check if all needed policies already been added
          const hasOrgPrivacyPoliciesForAllClients = organisationPrivacyPayloadInput.client_ids?.length === 0

          //if there are still some missing, render those
          !hasOrgPrivacyPoliciesForAllClients &&
            (await renderTemplate(organisationPrivacyPayloadInput as CdmRenderTemplateRequest))
        }
        return clientInvite
      }
      return null
    },
    postClientInvitesTemplate: async (_, { emailInput }) => {
      return await postClientInviteTemplate(emailInput)
    },
    createOrganisationTemplate: async (_, { input }) => await createOrganisationTemplate(input),
  },
}

export default TemplateResolver
