import { v4 as generateUniqueId } from 'uuid'

import {
  ClientInput,
  ClientVersion,
  Maybe,
  UnearnedIncomeFrequency,
  UnearnedIncomeType,
} from '../../generated/resolvers'
import { CdmClient } from '../../service/luther/model'
import { getCountryCodeFromNameOrDemonym, getCountryName } from '../countries'

// When entity has an id Apollo by default uses that for caching.
// If id does not exists there are silent bugs.
// Prod issue was users seeing correct amount of entities but with same values,
// ie. if 5 Debts exists on client and all 5 ids are id: null, Apollo will return 5 items with data of the 1st item
// To avoid that keyFields policy needs to specify key that can be used in case id is missing.
// In most nested entities we do not have unique value keys so this MR introduces internalHash that is set if id is missing and removed before sending payload to BE. internalHash is not used anywhere and is only needed for cashing.
export const addInternalHash = <T extends { id?: string | never; [key: string]: any }>(arr?: Maybe<T[]>) =>
  arr?.map((item) => ({
    ...(item || {}),
    internalHash: item?.id || generateUniqueId(),
  })) || null

export const removeInternalHash = <T extends { internalHash?: string | null }>(arr?: T[] | undefined | null) =>
  arr
    ? arr.map((item) => {
        if (item?.internalHash) {
          delete item.internalHash
        }
        return item
      })
    : arr

export const formatClient = <T extends CdmClient>(client: T) => {
  const { details } = client

  const nationalities =
    details?.nationalities && details.nationalities.length > 0
      ? details.nationalities.reduce((acc, countryCode) => {
          const countryName = getCountryName(countryCode)
          if (countryName) {
            return [...acc, countryName]
          }
          return acc
        }, [] as string[])
      : null

  const country_of_birth = details?.country_of_birth ? getCountryName(details.country_of_birth) : null

  const unearnedIncome =
    details?.unearned_income &&
    details.unearned_income.map((unearnedIncome) => ({
      ...unearnedIncome,
      frequency:
        unearnedIncome.frequency && unearnedIncome.frequency !== UnearnedIncomeFrequency.InvalidFrequency
          ? unearnedIncome.frequency
          : UnearnedIncomeFrequency.InvalidFrequency,
      type:
        unearnedIncome.type && unearnedIncome.type !== UnearnedIncomeType.InvalidUnearnedIncomeType
          ? unearnedIncome.type
          : UnearnedIncomeType.InvalidUnearnedIncomeType,
      amount: unearnedIncome.amount,
      other_type: unearnedIncome.other_type,
    }))

  const incomeAndEmployment =
    details?.income_and_employment &&
    details.income_and_employment.map((item) => ({
      ...item,
      employment: item.employment && {
        ...item.employment,
        total_guaranteed_annual: item.employment.total_guaranteed_annual,
        total_additional_annual: item.employment.total_additional_annual,
        incomes:
          item.employment.incomes &&
          item.employment.incomes.map((income) => ({
            ...income,
            income_history: income.income_history && income.income_history.map((value) => Number(value.toString())),
          })),
      },
    }))

  return {
    id: client.client_id || '',
    ...client,
    details: {
      ...details,
      accounts: addInternalHash(details?.accounts) ?? [],
      id: client.client_id,
      country_of_birth,
      nationalities,
      retirement_age: details?.retirement_age,
      id_verification_status: details?.id_verification_status,
      debts: addInternalHash(details?.debts),
      deposits: addInternalHash(details?.deposits),
      credit_history_bankruptcy_events: addInternalHash(details?.credit_history_bankruptcy_events),
      credit_history_county_court_judgements: addInternalHash(details?.credit_history_county_court_judgements),
      mortgage_application_refused: addInternalHash(details?.mortgage_application_refused),
      credit_history_debt_management_plans: addInternalHash(details?.credit_history_debt_management_plans),
      credit_history_individual_voluntary_arrangements: addInternalHash(
        details?.credit_history_individual_voluntary_arrangements,
      ),
      previous_names: addInternalHash(details?.previous_names),
      dependants: addInternalHash(details?.dependants),
      addresses: addInternalHash(details?.addresses),
      unearned_income: addInternalHash(unearnedIncome),
      income_and_employment: addInternalHash(incomeAndEmployment),
    },
  } as ClientVersion
}

export const formatClientAsRequestBody = (input: ClientInput): ClientInput => {
  const incomAndEmployment =
    input.income_and_employment &&
    input.income_and_employment.map((item) => ({
      ...item,
      employment: item.employment && {
        ...item.employment,
        incomes:
          item.employment.incomes &&
          item.employment.incomes.map((income) => ({
            ...income,
            income_amount: income.income_amount,
            income_history: income.income_history && income.income_history.map((value) => Number(value.toString())),
          })),
      },
    }))

  return {
    ...input,
    debts: removeInternalHash(input.debts),
    deposits: removeInternalHash(input.deposits),
    unearned_income: removeInternalHash(input.unearned_income),
    credit_history_bankruptcy_events: removeInternalHash(input.credit_history_bankruptcy_events),
    credit_history_county_court_judgements: removeInternalHash(input.credit_history_county_court_judgements),
    mortgage_application_refused: removeInternalHash(input.mortgage_application_refused),
    credit_history_debt_management_plans: removeInternalHash(input.credit_history_debt_management_plans),
    credit_history_individual_voluntary_arrangements: removeInternalHash(
      input.credit_history_individual_voluntary_arrangements,
    ),
    previous_names: removeInternalHash(input.previous_names),
    dependants: removeInternalHash(input.dependants),
    accounts: removeInternalHash(input.accounts),
    addresses: removeInternalHash(input.addresses),
    income_and_employment: removeInternalHash(incomAndEmployment),
  }
}

export const formatNationalities = (srcObject: string[]) => srcObject.map(getCountryCodeFromNameOrDemonym)
