import { Maybe, MortgageClubFee } from '../../../generated/resolvers'
import { SourcingMortgageState } from './mapToLutherMortgage.fixtures'

// mortgage_club_proc_fees
export const mapProcFees = (procFees: SourcingMortgageState['procFee']): Maybe<MortgageClubFee[]> | undefined => {
  return procFees?.map((fee) => ({
    mortgage_club_code: fee['procFee.network'],
    mortgage_club_name: fee['procFee.name'],
    proc_fee: fee['procFee.percentage'],
    net_proc_fee: fee['procFee.netpercentage'],
  }))
}
