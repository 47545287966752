import { checkClientExistsWithEmailOrPhone } from '../api/check_client'
import { Resolvers } from '../generated/resolvers'

const CheckClientResolver: Resolvers = {
  Query: {
    clientExistsWithEmailOrPhone: async (_parent, { client_id, phone_number, email_address }) =>
      checkClientExistsWithEmailOrPhone(client_id, phone_number, email_address),
  },
}

export default CheckClientResolver
