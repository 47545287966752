import React, { PropsWithChildren } from 'react'
import { Box, useTheme } from '@mui/material'
import * as Sentry from '@sentry/react'

import { useFormatMessage } from '@acre/utils'
import acreConfig from '@acre/config'

import ButtonNewest from '../ButtonNewest'
import { H2 } from '../Header'
import { Card, CardContainer, Code, Container, RedCode } from './MobileErrorBoundary.styles'

export type MobileErrorBoundaryProps = PropsWithChildren<{}>

const MobileErrorBoundary = ({ children }: MobileErrorBoundaryProps) => {
  const formatMessage = useFormatMessage()
  const theme = useTheme()

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => {
        return (
          <React.Fragment>
            <Container>
              <CardContainer>
                <Card>
                  <H2>{formatMessage('errors.somethingWentWrong')}</H2>
                  <Box my={theme.spacers.size16}>
                    <Box display="flex" alignItems="center" flexDirection="column">
                      <p>{formatMessage('errors.contactSupport')}</p>
                      <a>support@acresoftware.com</a>
                    </Box>
                  </Box>
                  {acreConfig.SENTRY_ENVIRONMENT !== 'portal.myac.re' && (
                    <>
                      <p>NB The following will not appear in production:</p>
                      <RedCode style={{ padding: '10px', color: 'red', marginTop: '10px' }}>{error.toString()}</RedCode>
                      <Code>{componentStack}</Code>
                    </>
                  )}
                  <Box my={1}>
                    <ButtonNewest
                      onClick={() => {
                        resetError()
                      }}
                    >
                      {formatMessage('errors.reloadPage')}
                    </ButtonNewest>
                  </Box>
                  <Box my={1}>
                    <ButtonNewest
                      onClick={() => {
                        resetError()
                        window.location.href = '/'
                      }}
                    >
                      {formatMessage('errors.homepage')}
                    </ButtonNewest>
                  </Box>
                </Card>
              </CardContainer>
            </Container>
          </React.Fragment>
        )
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default MobileErrorBoundary
