import { GraphQLError } from 'graphql'

import { createGroup, getGroups, getGroupsBy, getGroupsForOrg, updateGroup, UserLoader } from '../api'
import { Group, Resolvers, User } from '../generated/resolvers'

const GroupResolver: Resolvers = {
  Group: {
    users: async ({ user_ids }) => {
      const result = user_ids ? await UserLoader.loadMany(user_ids) : null
      const users = result?.reduce((acc, curr) => {
        if (curr instanceof GraphQLError) {
          console.error(curr)
        } else {
          return [...acc, curr]
        }

        return acc
      }, [] as User[])

      if (!users) {
        return null
      }

      return users.filter((user) => Boolean(user))
    },
  },
  Query: {
    getGroupsForOrg: async (_, input) => (await getGroupsForOrg(input)) as Group[] | null,
    getGroups: async (_, input) => (await getGroups(input)) as Group[] | null,
    getGroupsBy: async (_, input) => (await getGroupsBy(input)) as Group[] | null,
  },
  Mutation: {
    createGroup: async (_, args) => {
      const { organisation_id, input } = args

      const group = await createGroup({ organisation_id, input })

      return group as Group | null
    },
    updateGroup: async (_, args) => {
      const { group_id, input } = args

      const updatedGroup = await updateGroup({ group_id, input })

      return updatedGroup as Group
    },
  },
}

export default GroupResolver
