import DataLoader from 'dataloader'
import { GraphQLError } from 'graphql'

import { Role } from '../generated/resolvers'
import request from '../requesters/default'
import { CdmGetRoleResponse } from '../service/luther/model'

const RolesLoader = new DataLoader(async (roleIds: string[]) => {
  return await getRolesBatchFn(roleIds)
})

const RolesByNameLoader = new DataLoader(async (roleNames: string[]) => {
  return await getRolesByNameBatchFn(roleNames)
})

export const getRolesBatchFn = async (roleIds: string[]) => {
  const response = await request.get<CdmGetRoleResponse>(`/role`, {
    params: { role_ids: roleIds },
  })

  if (!response.data.roles) {
    return roleIds.map(
      (roleId) =>
        new GraphQLError(`Role not found for id: ${roleId}`, {
          extensions: {
            code: 'ROLE_NOT_FOUND',
            status: 404,
            id: roleId,
          },
        }),
    )
  }

  response.data.roles.forEach((role) => {
    if (role.role_name) {
      RolesByNameLoader.prime(role.role_name, role)
    }
  })

  const roles = roleIds.map((roleId) => {
    return (
      response.data.roles?.find((role) => role.role_id === roleId) ||
      new GraphQLError(`Role not found for id: ${roleId}`, {
        extensions: {
          code: 'ROLE_NOT_FOUND',
          status: 404,
          id: roleId,
        },
      })
    )
  })

  return roles
}

export const getRolesByNameBatchFn = async (roleNames: string[]) => {
  const response = await request.get<CdmGetRoleResponse>(`/role`, { params: { role_names: roleNames } })

  if (!response.data.roles) {
    return roleNames.map(
      (roleName) =>
        new GraphQLError(`Role not found for name: ${roleName}`, {
          extensions: {
            code: 'ROLE_NOT_FOUND',
            status: 404,
            name: roleName,
          },
        }),
    )
  }

  response.data.roles.forEach((role) => {
    if (role.role_id) {
      RolesLoader.prime(role.role_id, role)
    }
  })

  const roles = roleNames.map((roleName) => {
    return (
      response.data.roles?.find((role) => role.role_name === roleName) ||
      new GraphQLError(`Role not found for name: ${roleName}`, {
        extensions: {
          code: 'ROLE_NOT_FOUND',
          status: 404,
          name: roleName,
        },
      })
    )
  })

  return roles
}

export const getRoles = async (roleIds: string[]) => {
  const result = await RolesLoader.loadMany(roleIds)

  const roles: Role[] = []

  result.forEach((role) => {
    if (role instanceof GraphQLError) {
      throw role
    } else {
      roles.push(role as unknown as Role)
    }
  })

  return roles
}

export const getRolesByName = async (roleNames: string[]) => {
  const result = await RolesByNameLoader.loadMany(roleNames)

  const roles: Role[] = []

  result.forEach((role) => {
    if (role instanceof GraphQLError) {
      throw role
    } else {
      roles.push(role as Role)
    }
  })

  return roles
}
