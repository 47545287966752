import { AxiosResponse } from 'axios'

import { AuthUrLs, Exception } from '../generated/resolvers'
import request from '../requesters/default'

export const fetchAuthUrls = async (email: string): Promise<AuthUrLs> => {
  type AuthUrlResponse = {
    auth_urls: string[]
  }

  const response: AxiosResponse<AuthUrlResponse> = await request.get('/user/auth_url', {
    params: {
      user_email: email,
    },
  })

  return {
    email,
    urls: response && response.data ? response.data.auth_urls : [],
  }
}

export const checkUserExists = async (email: string): Promise<{ email: string; exists: boolean }> => {
  const response = await request.get<
    | {
        auth_urls: string[]
      }
    | { exception: Exception }
  >('/user/auth_url', {
    validateStatus: () => true,
    params: {
      user_email: email,
    },
  })

  if (
    response.status === 400 &&
    'exception' in response.data &&
    response.data.exception.description === 'No user found with that email'
  ) {
    return { email, exists: false }
  }

  return { email, exists: true }
}
