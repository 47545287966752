import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

import configs from '@acre/config'

import { SearchResult } from '../generated/resolvers'
import { LutherException } from '../resolvers/util'

type HttpMethods = 'GET' | 'PUT' | 'POST' | 'PATCH' | 'DELETE'

const { API_KEY } = configs

export const fetchSearchResults =
  (method: HttpMethods) =>
  async (url: string, opts: AxiosRequestConfig = { headers: {} }) => {
    const config: AxiosRequestConfig = {
      method: method,
      url: `${url}`,
      data: {
        params: { query: opts.params },
      },
      ...opts,
      withCredentials: true,
      headers: {
        'X-Api-Key': API_KEY,
        ...opts.headers,
      },
    }

    return await axios(config)
      .then((res: AxiosResponse<SearchResult>) => {
        return res.data
      })
      .catch((err) => {
        if (err.response) {
          const errorResponse = err.response.data
          const url = err.response.config.url!

          if (errorResponse.exception) {
            throw new LutherException(err.message, err.response.status, url, errorResponse.exception)
          } else {
            throw err
          }
        } else {
          console.error(err)
        }
      })
  }

export const get = fetchSearchResults('GET')
