import { Maybe, MortgageProduct } from '../../../generated/resolvers'
import inPlaceReplaceWithArray from '../../inPlaceReplaceWithArray'
import { mapErcs } from './mapErcs'
import { mapFees } from './mapFees'
import mapIncentives from './mapIncentives'
import { mapProcFees } from './mapProcFees'
import { mapRateTiers } from './mapRateTiers'
import { SourcingMortgageState } from './mapToLutherMortgage.fixtures'

export const mapToLutherMortgageProduct = (
  sourcingMortgage: SourcingMortgageState,
  loanAmount?: Maybe<string>,
  valuationAmount?: Maybe<string>,
  isCurrent?: Maybe<boolean>,
): MortgageProduct => {
  // When there is a single item in these subfields, then they can come as an object
  // instead of an array, so we need to transform them into an array with one element
  // for all subsequent transformation to work
  inPlaceReplaceWithArray(sourcingMortgage, 'erc')
  inPlaceReplaceWithArray(sourcingMortgage, 'rateBand')
  inPlaceReplaceWithArray(sourcingMortgage, 'procFee')
  inPlaceReplaceWithArray(sourcingMortgage, 'providerFee')
  inPlaceReplaceWithArray(sourcingMortgage, 'incentive')

  // Convert both amounts to pounds if they exist
  const convertedLoanAmount = loanAmount ? Number(loanAmount) / 100 : null
  const convertedValuationAmount = valuationAmount ? Number(valuationAmount) / 100 : null

  const fees = mapFees({
    fees: sourcingMortgage['providerFee'],
    loanAmount: convertedLoanAmount,
    valuationAmount: convertedValuationAmount,
    isCurrent,
  })

  const rate_tiers = mapRateTiers(sourcingMortgage['rateBand'])

  return {
    fees,
    incentives: mapIncentives(sourcingMortgage.incentive),
    product_name: sourcingMortgage.name || sourcingMortgage.productName,
    lender_name: sourcingMortgage.lender,
    fees_total: fees?.reduce((acc, fee) => acc + Number(fee.amount), 0).toString(),
    // this comes back as a string from sourcing
    is_porting: sourcingMortgage?.portable?.includes('true'),
    early_repayment_charge_applies: Boolean(sourcingMortgage?.erc?.length),
    early_repayment_charge_periods: mapErcs(sourcingMortgage['erc']),
    rate_tiers,
    mortgage_club_proc_fees: mapProcFees(sourcingMortgage['procFee']),
  }
}
