import {
  createSchedulerEvent,
  getIsDirectSchedulingAllowed,
  getScheduledEventsOnCase,
  getSchedulerEventInfo,
  getSchedulingAvailability,
  sendSchedulingInvite,
} from '../api/communications'
import { Resolvers, SchedulerEventInfo } from '../generated/resolvers'

const Scheduler: Resolvers = {
  Query: {
    getSchedulerAvailability: async (_, params) => await getSchedulingAvailability(params),
    getSchedulerAvailabilityDirect: async (_, params) => await getSchedulingAvailability(params),
    getSchedulerEventInfo: async (_, params) => (await getSchedulerEventInfo(params)) as SchedulerEventInfo,
    getSchedulerEventInfoDirect: async (_, params) => (await getSchedulerEventInfo(params)) as SchedulerEventInfo,
    getSchedulerEventsDirect: async (_, params) => await getScheduledEventsOnCase(params.caseId),
    getIsDirectSchedulingAllowed: async (_, params) => await getIsDirectSchedulingAllowed(params.caseId),
  },
  Mutation: {
    createSchedulerEvent: async (_, params) => await createSchedulerEvent(params),
    createSchedulerEventDirect: async (_, params) => await createSchedulerEvent(params),
    sendSchedulingInvite: async (_, { input }) => await sendSchedulingInvite(input),
  },
}

export default Scheduler
