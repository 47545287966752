import { DocumentVerificationQueryParams } from '../generated/resolvers'
import request from '../requesters/clientPortal'
import oathKeeper from '../requesters/oathKeeper'
import { CdmGetDocumentVerificationResponse } from '../service/luther/model'

// GetDocumentVerification
// https://crm.dev.acreplatforms.co.uk/api_documentation/redoc.html#operation/GetDocumentVerification
export const getDocumentVerificationCp = async (
  documentId: string,
  params?: DocumentVerificationQueryParams | null,
) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.get<CdmGetDocumentVerificationResponse>(`/document/${documentId}/verification`, {
    params,
  })
  return response.data
}
