import { Palette, PaletteColor, Theme } from '@mui/material'

export const resolveThemeColor = <T extends Theme>(
  theme: T,
  color: 'primary' | 'secondary' | 'error' | 'info' | 'inherit' | 'success' | 'warning' | 'default' | undefined,
  variant: keyof PaletteColor = 'main',
  transform?: (value: string) => string,
) => {
  if (!color || color === 'inherit') {
    return 'inherit'
  }

  const themeColour = theme.palette[color as keyof Palette] as PaletteColor

  return transform ? transform(themeColour[variant]) : themeColour[variant]
}
