import { loadingButtonClasses } from '@mui/lab'
import {
  ButtonProps,
  ChipProps,
  dialogActionsClasses,
  dialogContentClasses,
  dialogTitleClasses,
  tableBodyClasses,
  tableCellClasses,
  tableClasses,
  tableFooterClasses,
} from '@mui/material'
import { createTheme, Theme, ThemeOptions } from '@mui/material/styles'
import { gridClasses } from '@mui/x-data-grid-pro'
import type {} from '@mui/x-data-grid-pro/themeAugmentation'
import merge from 'deepmerge'
import { transparentize } from 'polished'

import acreTheme from './theme'
import { resolveThemeColor } from './theme.helpers'

const SPACING_UNIT = 8
const HTML_FONT_SIZE = 16

const spacing = (factor: number) => `${(SPACING_UNIT / HTML_FONT_SIZE) * factor}rem`

export const caseSidebarDrawWidth = 400

const muiTheme: ThemeOptions = {
  // Breakpoints have been coordinated with design team
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 960,
      lg: 1280,
      xl: 1800,
    },
  },
  mixins: {
    toolbar: {
      minHeight: spacing(6),
      '@media(min-width:600px)': {
        minHeight: spacing(6),
      },
    },
    sidebar: {
      width: spacing(30),
    },
    rightSidebar: {
      width: spacing(250 / 8),
    },
  },
  palette: {
    primary: { main: acreTheme.colours.primary, light: acreTheme.colours.hover, dark: acreTheme.colours.pressed },
    secondary: { main: acreTheme.colours.baseMid, light: acreTheme.colours.baseLight },
    error: { main: acreTheme.colours.new.red2, light: acreTheme.colours.new.red1 },
    warning: { main: acreTheme.colours.new.yellow2, light: acreTheme.colours.new.yellow1 },
    success: { main: acreTheme.colours.new.green },

    text: {
      primary: acreTheme.colours.base,
      secondary: acreTheme.colours.baseMid,
    },
  },
  typography: {
    fontWeightRegular: acreTheme.typography.fontWeight,
    htmlFontSize: HTML_FONT_SIZE,
    button: {
      lineHeight: 1.6,
    },
    h1: {
      fontSize: '2.625rem',
      fontWeight: 600,
      lineHeight: 1.4,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
      lineHeight: 1.4,
    },
    h3: {
      fontSize: '1.625rem',
      fontWeight: 600,
      lineHeight: 1.4,
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: 1.4,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: 1.4,
    },
    h6: {
      fontSize: '0.875rem',
      lineHeight: 1.4,
    },
    body1: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.4,
    },
    body2: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 1.2,
    },
    bullet: {
      lineHeight: 1.4,
      listStyleType: 'disc',
      listStylePosition: 'inside',
    },
    fontWeightBold: 700,
  },
  shape: { borderRadius: 6 },
  spacing,
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#ffffff',
        },
        root: ({ theme }) => ({
          borderBottom: `0.5px solid ${theme.palette.secondary.light}`,
          boxShadow: 'none',
        }),
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: { disableElevation: true, disableRipple: true, variant: 'primary' },
    },
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave',
      },
    },
    Button: {
      variants: [
        {
          props: { variant: 'primary' },
          style: ({ theme }) => ({
            padding: `0 ${theme.spacing(2)}`,

            [`&.${loadingButtonClasses.loading}`]: {
              color: 'transparent',
            },
          }),
        },
        {
          props: { variant: 'primary', size: 'small' },
          style: ({ theme }) => ({
            padding: `0 ${theme.spacing(1)}`,
          }),
        },
        {
          props: { variant: 'secondary' },
          style: ({ theme }) => ({
            borderStyle: 'solid',
            borderWidth: 1,
            padding: `0 ${theme.spacing(2 - 1 / 8)}`,

            '&:disabled': {
              backgroundColor: theme.colours.baseExtraLight,
              borderColor: theme.colours.baseExtraLight,
            },
          }),
        },
        {
          props: { variant: 'secondary', size: 'small' },
          style: ({ theme }) => ({
            padding: `0 ${theme.spacing(1 - 1 / 8)}`,
          }),
        },
        {
          props: { variant: 'text' },
          style: {
            padding: 0,
            height: 'auto',
            lineHeight: '1em',
            minWidth: 'auto',

            '&:hover, &:active': {
              background: 'transparent',
            },
          },
        },
        {
          props: { variant: 'link' },
          style: {
            padding: 0,
            height: 'auto',
            lineHeight: '1em',
            minWidth: 'auto',

            '&:hover:not(:disabled)': {
              textDecoration: 'underline',
            },

            '&:hover, &:active': {
              background: 'transparent',
            },
          },
        },
      ],

      styleOverrides: {
        root: ({ theme, loading }) => ({
          fontSize: theme.typography.pxToRem(14),
          textTransform: 'none',

          '&:disabled': {
            cursor: loading ? 'progress' : 'not-allowed',
            pointerEvents: 'auto',
          },
        }),
        sizeSmall: ({ theme }) => ({
          height: theme.spacing(3),
          minWidth: 'auto',
        }),
        sizeMedium: ({ theme }) => ({
          height: theme.spacing(4),
        }),
        sizeLarge: ({ theme }) => ({
          height: theme.spacing(5),
        }),
        sizeExtraLarge: ({ theme }) => ({
          fontSize: theme.typography.pxToRem(16),
          height: theme.spacing(6),
        }),
        primary: ({ theme, color }) => ({
          backgroundColor: resolveThemeColor(theme, (color as ButtonProps['color']) || 'primary', 'main'),
          color: resolveThemeColor(theme, (color as ButtonProps['color']) || 'primary', 'contrastText'),

          '&:disabled': {
            color: resolveThemeColor(theme, (color as ButtonProps['color']) || 'primary', 'contrastText'),
            backgroundColor: resolveThemeColor(theme, (color as ButtonProps['color']) || 'primary', 'main', (color) =>
              transparentize(0.8, color),
            ),
          },

          '&:hover:not(:disabled)': {
            backgroundColor: resolveThemeColor(theme, (color as ButtonProps['color']) || 'primary', 'light'),
          },

          '&:active:not(:disabled)': {
            backgroundColor: resolveThemeColor(theme, (color as ButtonProps['color']) || 'primary', 'dark'),
          },

          [`& .${loadingButtonClasses.loadingIndicator}`]: {
            color: resolveThemeColor(theme, (color as ButtonProps['color']) || 'primary', 'contrastText'),
          },
        }),
        secondary: ({ theme, color }) => ({
          color: resolveThemeColor(theme, (color as ButtonProps['color']) || 'primary', 'main'),

          '&:hover:not(:disabled)': {
            backgroundColor: resolveThemeColor(theme, (color as ButtonProps['color']) || 'primary', 'main', (color) =>
              transparentize(0.9, color),
            ),
          },

          '&:active:not(:disabled)': {
            backgroundColor: resolveThemeColor(theme, (color as ButtonProps['color']) || 'primary', 'main', (color) =>
              transparentize(0.8, color),
            ),
          },
        }),
        text: ({ theme, color, loading }) => ({
          '&:disabled': {
            ...(!loading && {
              color: resolveThemeColor(theme, (color as ButtonProps['color']) || 'primary', 'main', (color) =>
                transparentize(0.8, color),
              ),
            }),
          },
        }),
        link: ({ theme, color, loading }) => ({
          color: resolveThemeColor(theme, (color as ButtonProps['color']) || 'primary', 'main'),

          '&:disabled': {
            ...(!loading && {
              color: resolveThemeColor(theme, (color as ButtonProps['color']) || 'primary', 'main', (color) =>
                transparentize(0.8, color),
              ),
            }),
          },
        }),
      },
    },
    SelectField: {
      variants: [
        {
          props: { layout: 'row' },
          style: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            '.SelectField-label, .SelectField-select': {
              width: '100%',
            },
            '.SelectField-select': {
              marginLeft: 0,
            },
          },
        },
      ],
    },
    Radio: {
      defaultProps: {},
      styleOverrides: {
        root: ({ theme }) => ({
          '&.checked': {
            borderColor: theme.palette.primary.main,
          },
          '&:hover': {
            borderColor: theme.palette.primary.main,
          },
        }),
        checked: ({ theme }) => ({
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.main,
        }),
        circleChecked: ({ disabled }) => ({
          boxShadow: `inset 0px 0px 0px 2px ${acreTheme.colours.foreground}`,
          borderColor: disabled ? acreTheme.colours.baseLight : theme.palette.primary.main,
          backgroundColor: disabled ? acreTheme.colours.baseLight : theme.palette.primary.main,
        }),
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: 0,
          [theme.breakpoints.up('sm')]: {
            padding: 0,
          },
        }),
      },
    },
    MuiCardActionArea: {
      defaultProps: {
        disableRipple: true,
      },
    },

    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h5',
          component: 'h2' as 'span', // typescript is dumb
        },
        subheaderTypographyProps: {
          variant: 'caption',
        },
      },
      styleOverrides: {
        root: ({ theme }) => ({
          padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
        }),
        action: {
          alignSelf: 'unset',
        },
      },
    },

    MuiDataGrid: {
      defaultProps: {
        headerHeight: 32,
        rowHeight: 48,
      },
      styleOverrides: {
        root: {
          borderRadius: 0,
          '& .MuiBadge-badge': {
            display: 'none',
          },
        },
        columnHeader: ({ theme }) => ({
          color: theme.colours.baseMid,
          fontSize: theme.typography.pxToRem(12),

          '&:focus, &:focus-within': {
            outline: 'none',
          },

          '&:first-of-type': {
            paddingLeft: 32,
          },

          '&:last-of-type': {
            paddingRight: 32,
          },
        }),
        columnHeaderTitle: ({ theme }) => ({
          '.MuiDataGrid-columnHeader--sorted &': {
            color: theme.colours.primary,
          },
        }),
        columnSeparator: {
          height: '100%',

          '.MuiDataGrid-columnHeader:last-of-type > &': {
            display: 'none',
          },
        },
        iconSeparator: {
          height: '100%',
        },

        cell: {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',

          '&:focus, &:focus-within': {
            outline: 'none',
          },

          '&:first-of-type': {
            paddingLeft: 32,
          },

          // TODO: fix issue with right aligned cells when resizing last column
          '&:last-of-type': {
            paddingRight: 32,
          },

          '& > *': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        },
        totalRow: ({ theme }) => ({
          // TODO: Types not working currently
          fontWeight: (theme as Theme).typography.fontWeightBold,
        }),
      },
    },

    DialogForm: {
      variants: [
        {
          props: { variant: 'form' },
          style: {
            [`& .${dialogContentClasses.root}`]: {
              padding: '1.5rem',
            },
          },
        },
        {
          props: { variant: 'table' },
          style: ({ theme }) => ({
            [`& .${dialogTitleClasses.root}`]: {
              borderBottom: 0,
              paddingLeft: theme.spacing(4),
              paddingRight: theme.spacing(4),
            },

            [`& .${dialogContentClasses.root}`]: {
              padding: 0,
            },

            [`& .${dialogActionsClasses.root}`]: {
              borderTop: 0,
            },

            [`& .${gridClasses.root}`]: {
              border: 0,
            },

            [`& .${tableClasses.root}`]: {
              tableLayout: 'fixed',
            },

            [`
              & .${tableFooterClasses.root} .${tableCellClasses.root},
              & .${tableBodyClasses.root} .${tableCellClasses.root}
            `]: {
              paddingTop: theme.spacing(1),
              paddingBottom: theme.spacing(1),
            },

            [`& .${tableBodyClasses.root} .${tableCellClasses.root}`]: {
              '& button': {
                verticalAlign: 'middle',
                height: theme.spacing(3),
              },

              '&:not(:last-of-type)': {
                paddingRight: 0,
              },
            },
          }),
        },
      ],
      styleOverrides: {
        root: {
          backgroundColour: 'blue',
          outline: '5px red solid',

          '& .MuiDialogActions-root': {
            padding: '1rem 1.5rem',
          },

          '& .MuiDialogTitle-root': {
            padding: '1.5rem',
          },

          '& .MuiDialogContent-root': {
            padding: '1.5rem',
          },
        },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          borderTop: '1px solid rgb(215, 218, 224)',
          padding: '1rem',
        },
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid rgb(215, 218, 224)',
          padding: '1rem',

          '.DialogForm-root &': {
            padding: '1.5rem',
          },
        },
      },
    },

    FilterTag: {
      styleOverrides: {
        root: ({ theme, color }) => ({
          backgroundColor: resolveThemeColor(theme, (color as ChipProps['color']) || 'primary', 'main', (color) =>
            transparentize(0.9, color),
          ),
          border: 0,
          color: resolveThemeColor(theme, (color as ChipProps['color']) || 'primary', 'main'),
          fontWeight: 'bold',

          [`&.Mui-selected`]: {
            backgroundColor: resolveThemeColor(theme, (color as ChipProps['color']) || 'primary', 'main'),
            color: theme.palette.primary.contrastText,
          },

          '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: resolveThemeColor(theme, (color as ChipProps['color']) || 'primary', 'main', (color) =>
              transparentize(0.8, color),
            ),

            [`&.Mui-selected`]: {
              backgroundColor: resolveThemeColor(theme, (color as ChipProps['color']) || 'primary', 'main', (color) =>
                transparentize(0.2, color),
              ),
              color: theme.palette.primary.contrastText,
            },
          },

          '&:active:not(:disabled)': {
            color: theme.palette.primary.contrastText,
            backgroundColor: resolveThemeColor(theme, (color as ChipProps['color']) || 'primary', 'dark'),

            [`&:not(.Mui-selected)`]: {
              boxShadow: 'none',
            },
          },
        }),
      },
    },

    MuiListItemIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          minWidth: 'auto!important',
          marginRight: theme.spacing(1),
        }),
      },
    },

    MuiPopover: {
      styleOverrides: {
        paper: ({ theme }) => ({
          boxShadow: `0px 4px 6px rgba(0, 0, 0, 0.12)`,
          border: `1px solid ${theme.palette.secondary.light}`,
        }),
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 14,
          padding: '8px 16px',

          '&:hover': {
            backgroundColor: acreTheme.colours.primary10,
            color: acreTheme.colours.primary,
          },
        },
      },
      variants: [
        {
          props: { color: 'error' },
          style: ({ theme }) => ({
            color: theme.palette.error.main,
            '&:hover': {
              color: theme.palette.error.main,
              backgroundColor: theme.palette.error.light,
            },
          }),
        },
      ],
    },

    MuiTableHead: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderTop: `1px solid ${(theme as Theme).colours.baseLight}`,

          [`& .${tableCellClasses.root}`]: {
            color: (theme as Theme).colours.baseMid,
            fontSize: 12,
            paddingTop: 0,
            paddingBottom: 0,
            height: theme.spacing(4),
          },

          [`& .${tableCellClasses.root}:not(:last-of-type)`]: {
            borderRight: `1px solid ${(theme as Theme).colours.baseLight}`,
          },
        }),
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          '&:first-of-type': {
            paddingLeft: 32,
          },

          '&:last-of-type': {
            paddingRight: 32,
          },
        },
      },
    },

    TableCard: {
      defaultProps: {
        variant: 'elevation',
        elevation: 2,
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          backgroundColor: theme.palette.background.paper,
          boxShadow: acreTheme.clientBoxShadow,
          border: `1px solid ${theme.palette.secondary.light}`,
          color: theme.palette.text.primary,
          fontSize: theme.typography.pxToRem(12),
          padding: theme.spacing(2),
          lineHeight: 1.6,
          borderRadius: theme.shape.borderRadius,
        }),
      },
    },

    MuiTypography: {
      defaultProps: {
        variantMapping: {
          bullet: 'ul',
        },
      },
    },
  },
}

const theme = createTheme(merge(acreTheme, muiTheme))

// SHADOWS
theme.shadows[1] = 'rgb(0 0 0 / 10%) 0px 4px 8px'
theme.shadows[2] = acreTheme.boxShadow
theme.shadows[3] = acreTheme.clientBoxShadow

export default theme
