import to from 'await-to-js'

import getValuation from '../../api/valuation'
import { Resolvers } from '../../generated/resolvers'

const ValuationResolver: Resolvers = {
  Query: {
    getValuation: async (_, { acrePropertyID }) => {
      const [error, result] = await to(getValuation(acrePropertyID))

      // Suppressing Error Message for now
      if (error?.message === 'Could not get valuation: not enough sales data to generate an estimate') {
        return null
      }

      return result
    },
  },
}

export default ValuationResolver
