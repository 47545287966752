import { addBreadcrumb } from '@sentry/react'
import debug from 'debug'

const wrappedDebug = (fieldName: string) => {
  const d = debug(fieldName)
  return (formatter: any, ...args: any[]): void => {
    addBreadcrumb({
      category: fieldName,
      message: `${formatter} ${args
        .map((o) => {
          if (typeof o === 'string') {
            return o
          }
          return JSON.stringify(o)
        })
        .join(' ')}`,
      level: 'debug',
    })
    d(formatter, ...args)
  }
}

export default wrappedDebug
