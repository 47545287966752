import { fetchUsersForOrg } from '../api'
import {
  getCommissionStructure,
  getIntroducers,
  patchCommissionStructure,
  postCommissionStructure,
} from '../api/commission_structure'
import {
  MutationCreateCommissionStructureArgs,
  MutationUpdateCommissionStructureArgs,
  Resolvers,
} from '../generated/resolvers'

const CommissionStructureResolvers: Resolvers = {
  Query: {
    getCommissionStructures: async (_, args) => await getCommissionStructure(args),
    introducers: async (_, args) => await getIntroducers(args),
  },
  Mutation: {
    createCommissionStructure: (_, args: MutationCreateCommissionStructureArgs) => postCommissionStructure(args),
    updateCommissionStructure: (_, args: MutationUpdateCommissionStructureArgs) => patchCommissionStructure(args),
  },
  IntroducerCommission: {
    users: async ({ organisation_id }) => await fetchUsersForOrg(organisation_id),
  },
}

export default CommissionStructureResolvers
