import { QueryResult } from '@apollo/client'
import { LandRegistryFullResponse, LandRegistryTitleResponse } from '@client-portal-contexts/LandRegistryTitleContext'
import { AddressWrapper, PropertyResponse, Quarter } from '@client-portal-contexts/PropertyContext'
import { AxiosResponse } from 'axios'
import { CRS as LeafletCRS, Icon } from 'leaflet'
import Proj from 'proj4leaflet'
import { LineSeriesPoint } from 'react-vis'

import { ErrorProvider } from '@acre/utils'
import {
  ClientAddress,
  GetPropertyCpQuery,
  Maybe,
  Property,
  PropertyDetailsFragment,
  PropertyLocation,
  requesters,
} from '@acre/graphql'
import { ItemCardData } from '@acre/design-system'

import { DemographicsResponse } from '../../../contexts/DemographicsContext'
import homePointer32 from '../images/home_pointer_32.png'
import homePointer64 from '../images/home_pointer_64.png'
import shadowMarker from '../images/marker-shadow.png'

export const getCRS = () =>
  new Proj.CRS(
    'EPSG:27700',
    '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +datum=OSGB36 +units=m +no_defs',
    {
      resolutions: [
        5031.7636014261625, 2515.8818007130812, 1257.9409003565406, 628.9704501782703, 314.48522508913516,
        157.24261254456758, 78.62130627228379, 39.310653136141894, 19.655326568070947, 9.827663284035474,
        4.913831642017737, 2.4569158210088684, 1.2284579105044342, 0.6142289552522171, 0.3071144776261086,
        0.1535572388130543,
      ],
    },
  ) as LeafletCRS

export const titleCase = (str: string) => {
  return str.replace(/[_-]+/g, ' ').replace(/\w\S*/g, (t) => {
    return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase()
  })
}

export const valueToXCoord = (value: Quarter): number => {
  return (value.year - 1995) * 4 + value.quarter - 1
}

export const dateToXCoord = (value: string): number => {
  const parts = value.split('-')
  const quarter = (parseInt(parts[1]) - (parseInt(parts[1]) % 3)) / 3
  return (parseInt(parts[0]) - 1995) * 4 + quarter
}

export const xCoordToValue = (v: number): { year: number; quarter: number } => {
  const quarter = v % 4
  const year = (v - quarter) / 4 + 1995
  return { year, quarter: quarter + 1 }
}

export const getSeries = (s: Quarter[] | undefined): LineSeriesPoint[] => {
  let series = []
  if (s) {
    for (const value of s) {
      series.push({ x: valueToXCoord(value), y: value.average_price })
    }
  }
  series.sort((a, b) => {
    if (a.x > b.x) return 1
    if (b.x > a.x) return -1
    return 0
  })
  return series
}

export const mapBoundaries = (inVal: { [key: string]: string }): ItemCardData[] => {
  return Object.entries(inVal)
    .map((value) => {
      if (value[1] === '') {
        return {
          id: 'skip',
          label: '',
          value: '',
        }
      }
      switch (value[0]) {
        case 'county':
          return {
            id: 'SummaryCounty',
            label: 'County',
            value: value[1],
          }
        case 'ceremonial_county':
          return {
            id: 'SummaryCeremonialCounty',
            label: 'Ceremonial county',
            value: value[1],
          }
        default:
          return {
            id: 'skip',
            label: '',
            value: '',
          }
      }
    })
    .filter((value) => value.id !== 'skip')
}

export const mapSectionTitle = (input: string): string => {
  switch (input) {
    case 'commons':
      return 'Common land'
    case 'defra':
      return 'DEFRA register'
    case 'designated':
      return 'Protected areas'
    case 'flood':
      return 'Flood risk'
    case 'geo':
      return 'Geology'
    default:
      return titleCase(input)
  }
}

export const mapToNavLink = (input: string): string => {
  switch (input) {
    case 'commons':
      return 'protected-areas'
    case 'defra':
      return 'polluters'
    case 'designated':
      return 'protected-areas'
    case 'flood':
      return 'flooding'
    case 'geo':
      return 'geological'
    case 'infrastructure_projects':
      return 'major-projects'
    case 'internal drainage':
      return 'flooding'
    default:
      return input
  }
}

export const isNorthernIreland = (addressData: Maybe<ClientAddress | Property>) => {
  const postcode = addressData?.address?.postcode
  return addressData?.property_location === PropertyLocation.NorthernIreland || postcode?.toUpperCase().startsWith('BT')
}

const doSoftMatch = async (details: PropertyDetailsFragment) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')
  const request = useOathKeeper === 'true' ? requesters.oathKeeper : requesters.clientPortalPropertyData

  const response = await request.get<AddressWrapper>('/propertydata/address', {
    params: {
      query: details.details.address?.postcode,
    },
  })

  let propertyMatch

  for (const property of response.data) {
    if (
      details.details.address?.address1 &&
      property.summaryline
        .toLowerCase()
        .replace(/,/g, '')
        .startsWith(details.details.address?.address1?.toLowerCase().replace(/,/g, '') || '')
    ) {
      propertyMatch = property

      break
    }
  }
  if (!propertyMatch) {
    // If we have no property ID and can't find an exact match, pick the nearest property...

    propertyMatch = response.data?.pop()
  }
  return propertyMatch?.id
}

export const wrapPropertyContext = async (details?: PropertyDetailsFragment) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')
  const request = useOathKeeper === 'true' ? requesters.oathKeeper : requesters.clientPortalPropertyData

  if (details?.details && !isNorthernIreland(details?.details)) {
    let propertyMatch = details.details.acre_property_id
    if (!propertyMatch) {
      propertyMatch = await doSoftMatch(details)
    }
    if (propertyMatch) {
      return request
        .get<PropertyResponse>('/propertydata/search', {
          params: {
            propertyId: propertyMatch,
          },
        })
        .catch((error) => {
          throw error
        })
    }
  }
  return
}

export type propertyPageProps = {
  propertyId: string
  id: string
}

export const purchaseTitle = (propertyID: number, caseID: string, titleNumber: string) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')
  const request = useOathKeeper === 'true' ? requesters.oathKeeper : requesters.clientPortalPropertyData

  return request.get<LandRegistryFullResponse>('/propertydata/landregistry/purchase', {
    params: {
      propertyID,
      caseID,
      title: titleNumber,
    },
  })
}

export const loadData = (
  data: QueryResult<GetPropertyCpQuery>['data'],
  setPropertyState: (value: PropertyResponse | undefined) => void,
  setDemographicsState: (value: DemographicsResponse | undefined) => void,
  caseId: string | undefined,
  setLandRegistryState: (value: LandRegistryTitleResponse | undefined) => void,
) => {
  wrapPropertyContext(data?.propertyCp).then(
    (response) => {
      const useOathKeeper = sessionStorage.getItem('useOathKeeper')
      const request = useOathKeeper === 'true' ? requesters.oathKeeper : requesters.clientPortalPropertyData

      if (response) {
        setPropertyState(response.data)
        if (caseId && data?.propertyCp.details.property_location == PropertyLocation.EnglandWales) {
          request
            .get('/propertydata/landregistry/search', {
              params: {
                propertyID: response.data.property?.property_id,
                caseID: caseId,
              },
            })
            .then((value: AxiosResponse<LandRegistryTitleResponse>) => {
              if (value) {
                setLandRegistryState(value.data)
              }
            })
            .catch((err) => {
              if (err.response?.data) {
                console.warn('error loading land registry data', err.response.data.message)
                return
              }
              console.warn('error loading land registry data', err)
            })
        }

        request
          .get<DemographicsResponse>('/propertydata/search', {
            params: {
              propertyId: response.data.property?.property_id,
              searches: 'demographics',
            },
          })
          .then((value) => {
            if (value) {
              setDemographicsState(value.data)
            }
          })
      }
    },
    (error) => {
      if (error.response?.data) {
        ErrorProvider.showError(new Error(error.response.data.message))
        return
      }
    },
  )
}

export const homeIcon = new Icon({
  iconUrl: homePointer32,
  iconRetinaUrl: homePointer64,
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  iconShadowUrl: shadowMarker,
  shadowSize: [32, 32],
  shadowAnchor: [12, 29],
  shadowUrl: shadowMarker,
})

export const graphColours: string[] = [
  'pink3',
  'orange3',
  'amber3',
  'rose',
  'purple3',
  'indigo4',
  'cyan',
  'emerald',
  'fuchsia',
  'lime',
  'red4',
]

// For non property data results, where we just want to get the long/lat for the map widget
const doSoftAddressMatch = async (details: ClientAddress | Property) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')
  const request = useOathKeeper === 'true' ? requesters.oathKeeper : requesters.clientPortalPropertyData

  const response = await request.get<AddressWrapper>('/propertydata/address', {
    params: {
      query: details?.address?.postcode,
    },
  })

  let propertyMatch
  for (const property of response.data) {
    if (
      details.address?.address1 &&
      property.summaryline
        .toLowerCase()
        .replace(/,/g, '')
        .startsWith(details.address?.address1?.toLowerCase().replace(/,/g, '') || '')
    ) {
      propertyMatch = property

      break
    }
  }
  if (!propertyMatch) {
    // If we have no property ID and can't find an exact match, pick the nearest property...

    propertyMatch = response.data?.pop()
  }
  return propertyMatch?.id
}

export const wrapPropertyAddressContext = async (
  details?: ClientAddress | Property,
  doNotincludeSearches?: boolean,
) => {
  const propertyMatch = details && (await doSoftAddressMatch(details))
  if (propertyMatch) {
    const params: any = {
      propertyId: propertyMatch,
    }
    // If we just want a map, by setting the searches input to an empty string we can save the server from making a ton of
    // lookups that we don't need.
    if (doNotincludeSearches) {
      params.searches = ''
    }
    const useOathKeeper = sessionStorage.getItem('useOathKeeper')
    const request = useOathKeeper === 'true' ? requesters.oathKeeper : requesters.clientPortalPropertyData

    return request.get<PropertyResponse>('/propertydata/search', {
      params,
    })
  }
}

export const loadPropertyData = (
  data: ClientAddress | Property | undefined,
  setPropertyState: (value: PropertyResponse | undefined) => void,
  doNotIncludeSearches?: boolean,
) => {
  wrapPropertyAddressContext(data, doNotIncludeSearches).then(
    (response) => {
      if (response) {
        setPropertyState(response.data)
      }
    },
    (error) => {
      if (error.response?.data) {
        ErrorProvider.showError(new Error(error.response.data.message))
        return
      }
    },
  )
}
