export const toDecimalString = (value: number | string | null | undefined, fallback: string = ''): string => {
  let valueToFormat = value
  if (typeof value === 'string') {
    // remove comma from string if it exists
    valueToFormat = value.replace(/,/g, '')
  }
  return Number(valueToFormat) ? (((Number(valueToFormat) || 0) * 100) / 100).toFixed(2) : fallback
}

export const formatAsHumanReadable = (
  value: number | string | undefined | null,
  defaultValue: string = '',
  options: Intl.NumberFormatOptions = {},
) => {
  // Be specific about invalid values, because 0 is falsey but we want it to be formatted
  if (value === undefined || value === null || value === '') return defaultValue
  const { style, currency, maximumFractionDigits = 2, minimumFractionDigits } = options
  return Intl.NumberFormat('en-GB', {
    style,
    currency,
    maximumFractionDigits,
    minimumFractionDigits,
  }).format(Number(value))
}

export const formatAsCurrency = (
  value: string | number | bigint | null | undefined,
  isPence: boolean = true,
  defaultValue?: string,
) => {
  const valueInPounds = isPence ? convertPenceToPounds(value?.toString()) : formatAsNumberReadable(value?.toString())
  const isInt = Number.isInteger(parseFloat(valueInPounds || '0'))
  const nDigits = isInt ? 0 : 2

  return formatAsHumanReadable(valueInPounds, defaultValue ?? '', {
    style: 'currency',
    currency: 'GBP',
    maximumFractionDigits: nDigits,
    minimumFractionDigits: nDigits,
  })
}

export const formatAsBoolean = (value: boolean) => {
  if (value) return 'generic.included'
  return 'generic.notIncluded'
}

export const formatAsPercentage = (value: number | string | null | undefined) => {
  if (value === null || value === undefined || Number.isNaN(Number(value))) {
    return ''
  }
  return `${Number(value) / 100000}%`
}

export const mortgageReasons = [
  'REASON_BTL',
  'REASON_FTB',
  'REASON_REMORTGAGE',
  'REASON_HOUSE_MOVE',
  'REASON_EQUITY_RELEASE',
  'REASON_FURTHER_ADVANCE',
]

export const formatAsNumberReadable = (value: string | null | undefined): string | null | undefined => {
  if (isNaN(Number(value)) || !value) return value
  return Number(value).toFixed(2).replace('.00', '')
}

// @TODO Deprecate this in favour of formatAsCurrencyP2P(...)
export const convertPenceToPounds = (value: string | null | undefined): string | null | undefined => {
  if (isNaN(Number(value)) || !value) return value
  return (Number(value) / 100).toFixed(2).replace('.00', '')
}

// @TODO Deprecate this in favour of formatAsCurrencyP2P(...)
export const convertPoundsToPence = (value: string | null | undefined): string | null | undefined => {
  if (isNaN(Number(value?.replace(/,/g, ''))) || !value) return value
  return Math.round(Number(value.replace(/,/g, '')) * 100).toString()
}

export const convertPercentageToLutherFormat = (value: string): string => {
  if (isNaN(Number(value))) return value
  return Math.round(Number(value) * 100000).toString()
}

export const convertToPercentageValue = (value: number | string | null | undefined) => {
  if (value === null || value === undefined || Number.isNaN(Number(value))) {
    return ''
  }
  return `${Number(value) / 100000}`
}

export const convertFromLutherPercentage = (num: number | null | undefined): number | null => {
  return num ? Math.round(num * 100000) : null
}
