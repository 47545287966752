import { GraphQLError } from 'graphql'

import {
  changeUserEmail,
  createUser,
  fetchClients,
  fetchOrganisation,
  fetchUser,
  fetchUsers,
  fetchUsersForOrg,
  loginAsSupportUser,
  patchUser,
  UserLoader,
} from '../api'
import { setupUser } from '../api/acctmgmt'
import { getRoles } from '../api/role'
import { Resolvers, Status } from '../generated/resolvers'

const UserResolver: Resolvers = {
  Query: {
    // TODO: Should use DataLoader
    user: (_parent, { id, useClientApi }) => fetchUser(id, useClientApi),
    users: (_parent, { ids, useClientApi }) => fetchUsers(ids!, useClientApi),
    currentUser: async () => {
      // NOTE: Once the server is implemented we can remove the .clear below
      const user = await UserLoader.load('me')

      if (user instanceof GraphQLError) {
        throw user
      }

      return user
    },
    caseOwners: async (_parent, { organisationId, filterOut }) => {
      const users = await fetchUsersForOrg(organisationId)
      const supportedCaseOwnerRoleIds: string[] = []
      // This seems to be temp solution
      // role id determines the id of the user, os until it becomes and enum on BE it will be hardcoded
      const principal = 'a43f1c0e-90f5-4c80-ad3d-b538f85435e8'
      const firmManager = '90997f32-8118-4aa9-bbf8-10d6f688e999'
      const advisor = '53a15527-4b5c-41a4-93ff-a2ee04005f86'
      if (!filterOut?.principal) {
        supportedCaseOwnerRoleIds.push(principal)
      }
      if (!filterOut?.firmManager) {
        supportedCaseOwnerRoleIds.push(firmManager)
      }
      if (!filterOut?.advisor) {
        supportedCaseOwnerRoleIds.push(advisor)
      }

      return users.filter((user) => supportedCaseOwnerRoleIds.includes(user.primary_role_id as string))
    },
    loginAsSupportUser: (_, { id }) => loginAsSupportUser(id),
  },
  User: {
    role_name: async (parent) => {
      if (!parent.primary_role_id) return null
      const roles = await getRoles([parent.primary_role_id])
      const { role_name } = roles[0]
      return role_name
    },
    client_record: async (parent) => {
      // Add sentry logs
      if (!parent.email_address) return null

      // When a user logs into the platform, we want to use their email addrresess
      // to look for their client record by doing
      const clientsByEmail = await fetchClients({
        filter_email_address: parent.email_address,
      })

      // Users could have more than 1 client record with same email so we return all
      if (clientsByEmail?.length) {
        return clientsByEmail
      }

      return null
    },
    organisation: async (parent, { useClientApi }) => {
      if (!parent.organisation_id) {
        return null
      }

      const organisation = await fetchOrganisation(parent.organisation_id, useClientApi)

      if (organisation instanceof GraphQLError) {
        console.error(organisation)
        throw organisation
      }

      return organisation
    },
  },
  Mutation: {
    updateUserStatus: async (_, { userId, status }) => {
      const input = { status: status ? Status.Active : Status.Disabled }
      const user = await patchUser(userId, input)

      return user
    },
    updateUser: async (_, { userId, input }) => {
      const user = await patchUser(userId, input)

      return user
    },
    createUser: async (_, { input }) => {
      const user = await createUser(input)
      return user
    },
    setupUser: async (_, { input }) => await setupUser(input),
    changeUserEmail: async (_, { userId, newEmail }) => {
      const user = await changeUserEmail(userId, newEmail)

      UserLoader.clear(userId)

      return user
    },
  },
}

export default UserResolver
