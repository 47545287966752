import request from '../requesters/clientPortal'
import oathKeeper from '../requesters/oathKeeper'
import { normaliseAddressFind, PWCAddressFindSuggestions } from '../utils/normalisers/normaliseAddressFind '

// IMPORTANT: This does not charge on every request
const fetchAddressLookupListCP = async (searchFragment: string, pathFilter: string) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')
  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.get<PWCAddressFindSuggestions[]>(
    `/propertydata/find?query=${searchFragment}&pathfilter=${pathFilter}&format=json`,
  )

  const jsonResponse = response.data
  const hasResults = jsonResponse.length > 0
  return hasResults ? normaliseAddressFind(jsonResponse) : []
}

export default fetchAddressLookupListCP
