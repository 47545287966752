import { styled, Theme } from '@mui/material'

export const AlertButton = styled('div')<{ isSuccess: boolean }>(
  ({ theme, isSuccess }: { theme: Theme; isSuccess: boolean }) => `
  @keyframes slide-up {
    0% {
      bottom: -50px;
    }
    100% {
      bottom: ${theme.display.ms3};
    }
  }

  position: fixed;
  left: 50%;
  transform: translateX(-50%);

  animation: slide-up 0.2s ease-in;
  animation-fill-mode: forwards;
  display: flex;
  justify-content: space-between;
  padding: ${theme.display.ms1};
  width: 90%;
  max-width: 762px;
  align-items: center;

  font-size: 16px;
  background-color: ${isSuccess ? theme.colours.successColor : theme.colours.base};
  color: ${theme.colours.foreground};
  border-radius: ${theme.display.borderRadius};
  z-index: ${theme.display.alertZIndex};
  white-space: pre-line; // allow \n to break line

  /* @TODO: FRON-892 Handle styles.css in frontend namespace */
  & button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: 0 none;
  }
`,
)
export const CloseButton = styled('button')(
  ({ theme }: { theme: Theme }) => `
  border: 0 none;
  & path {
    stroke: ${theme.colours.foreground};
  }
  & > svg {
    width: 20px;
  }
`,
)
