import { AxiosResponse } from 'axios'

import {
  MutationCreateGroupArgs,
  MutationUpdateGroupArgs,
  QueryGetGroupsArgs,
  QueryGetGroupsByArgs,
  QueryGetGroupsForOrgArgs,
} from '../generated/resolvers'
import request from '../requesters/default'
import { CdmCreateGroupResponse, CdmGetGroupResponse, CdmUpdateGroupResponse } from '../service/luther/model'

export const getGroupsBy = async (params?: QueryGetGroupsByArgs) => {
  const response = await request.get<CdmGetGroupResponse>('/group', { params: params?.input })
  return response?.data.groups
}

export const getGroupsForOrg = async (params?: QueryGetGroupsForOrgArgs) => {
  delete params?.fetchUsers

  const response: AxiosResponse<CdmGetGroupResponse> = await request.get('/group', { params })
  return response?.data.groups
}

export const getGroups = async (params: QueryGetGroupsArgs) => {
  const response = await request.get<CdmGetGroupResponse>('/group', {
    params: {
      group_ids: params.ids,
      show_disabled: true,
    },
  })
  return response?.data.groups
}

export const createGroup = async (params: MutationCreateGroupArgs) => {
  const { organisation_id, input } = params
  const response = await request.post<CdmCreateGroupResponse>('/group', { organisation_id, ...input })
  return response?.data.group
}

export const updateGroup = async (params: MutationUpdateGroupArgs) => {
  const { group_id, input } = params
  const response = await request.patch<CdmUpdateGroupResponse>(`/group/${group_id}`, input)
  return response?.data.group
}
