import to from 'await-to-js'
import { AxiosError } from 'axios'

import { getSolutionSourcingResults, runSolutionSourcing } from '../../api_protection'
import { ExceptionType, Resolvers, RunSolutionSourcingResponse } from '../../generated/resolvers'
import { GraphqlException } from '../util'

const resolvers: Resolvers = {
  Query: {
    getSolutionSourcingResults: async (_, { location }) => {
      const data = await getSolutionSourcingResults(location)
      return data
    },
  },
  Mutation: {
    runSolutionSourcing: async (_, { input }) => {
      const inputFormatted = input
      const [error, data] = await to<RunSolutionSourcingResponse, AxiosError>(runSolutionSourcing(inputFormatted))

      if (error && error?.response) {
        const { type, description } = error.response.data?.exception || {}
        const isViolation = type === ExceptionType.SecurityViolation
        //override with custom error
        const errorMessage = isViolation
          ? 'Missing iPipeline credentials. Go to ‘My Settings’ to enter them. If you have already entered your credentials, ensure they are valid. If this issue persists, contact support.'
          : description
        // todo: extra karma points to pass as gql error
        throw new GraphqlException(errorMessage)
      }
      return data || null
    },
  },
}

export default resolvers
