import React from 'react'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import { Button as MuiButton, ButtonProps as MuiButtonProps, CircularProgress } from '@mui/material'
import { styled } from '@mui/material'

import { getButtonStyledOptions, getLoadingIndicatorSize } from './Button.helpers'

export type ButtonProps<
  D extends React.ElementType<any> = 'button',
  P = {},
  T extends LoadingButtonProps<D, P> | MuiButtonProps<D, P> = LoadingButtonProps<D, P>,
> = T

const ButtonBase = React.forwardRef<HTMLButtonElement, ButtonProps>(function ButtonBase<
  D extends React.ElementType<any> = 'button',
  P = {},
>(props: ButtonProps<D, P>, ref: React.ForwardedRef<HTMLButtonElement>) {
  if ((props as LoadingButtonProps<D, P>).loading === undefined) {
    return <MuiButton {...(props as MuiButtonProps<D, P>)} ref={ref} />
  }

  const loadingIndicatorSize = getLoadingIndicatorSize(props)

  return (
    <LoadingButton
      as={LoadingButton}
      loadingIndicator={<CircularProgress color="inherit" size={loadingIndicatorSize} />}
      {...props}
      ref={ref}
    />
  )
})

const Button = styled(
  ButtonBase,
  getButtonStyledOptions('Button'),
)({
  minWidth: 'fit-content',
})

Button.defaultProps = {
  variant: 'primary',
  size: 'medium',
}

export default Button as typeof Button
