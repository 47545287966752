import { AxiosResponse } from 'axios'
import { isEmpty } from 'lodash'

import { Address, PropertyLocation, PropertyTenure } from '../generated/resolvers'
import request from '../requesters/default'
import { ADDRESS_LOOKUP_API_URL } from '../resolvers/util'
import { CountryCode } from '../types'

// For more information about what this api returns
// https://developers.alliescomputing.com/postcoder-web-api/address-lookup/international-addresses
// e.g. Empty address fields are not returned in the response, so all fields should be treated as optional
export type AddressToNormalise = {
  addressline1: string
  addressline2: string
  addressline3: string
  summaryline: string
  organisation?: string
  buildingname: string
  premise: string
  street: string
  dependentlocality?: string
  posttown: string
  postcode: string
  county: string
  country: string
  field_updates?: PropertyFieldUpdates
  property: PropertyStub
}

export type PropertyFieldUpdates = {
  property_tenure?: PropertyTenure
  property_type?: string
  property_sub_type?: string
  price_paid?: number
  year_built?: number
  property_location?: PropertyLocation
  bedrooms?: number
  floor_number?: number
  storeys?: number
  current_energy_rating?: string
}

type PropertyStub = {
  property_id: number
  post_town: string
}

export type AddressAndFieldUpdates = Address & {
  field_updates?: PropertyFieldUpdates
  property_id?: number
}

export const normaliseAddress = ({
  addressline1,
  addressline2,
  addressline3,
  posttown,
  postcode,
  country,
  county,
  field_updates,
  property,
}: AddressToNormalise): AddressAndFieldUpdates => {
  return {
    line_1: addressline1 || '',
    line_2: addressline2 || '',
    line_3: addressline3 || '',
    // sometimes api returns two post towns, we should give precedence to the one
    // on the property.post_town rather to the one from posttown on parent object
    town: !isEmpty(property.post_town) ? normalisePostTown(property.post_town) : posttown || '',
    postcode: postcode || '',
    country: country || '',
    county: county || '',
    field_updates: field_updates || {},
    property_id: property.property_id || 0,
  }
}

const normalisePostTown = (postTown: string) => {
  return postTown
    .split(' ')
    .map((word) => {
      return word[0] + word.slice(1).toLowerCase()
    })
    .join(' ')
}

export const normaliseAddressLookup = (
  srcObject: AddressToNormalise[],
  countryCode: CountryCode = 'GB',
): AddressAndFieldUpdates[] => {
  return srcObject.map((item) => normaliseAddress({ ...item, country: countryCode }))
}

// IMPORTANT: This charges on every request
const fetchAddressLookup = async (
  lines: number = 3,
  addressId: string,
  searchFragment?: string,
): Promise<AddressAndFieldUpdates[]> => {
  const response: AxiosResponse<AddressToNormalise[]> = await request.get('/retrieve', {
    baseURL: ADDRESS_LOOKUP_API_URL,
    params: {
      id: addressId,
      query: searchFragment,
      format: 'json',
      lines,
    },
  })
  const jsonResponse = response.data
  const hasResults = jsonResponse.length > 0
  return hasResults ? normaliseAddressLookup(jsonResponse) : []
}

export default fetchAddressLookup
