import React from 'react'
import { helmetData } from '@client-portal-utils/helmetData'
import { Outlet, ScrollRestoration } from 'react-router-dom'

import { MobileErrorBoundary, PageTracker } from '@acre/design-system'

const AppRoutes = () => {
  return (
    <PageTracker helmetData={helmetData}>
      <MobileErrorBoundary>
        <Outlet />
        <ScrollRestoration />
      </MobileErrorBoundary>
    </PageTracker>
  )
}

export default AppRoutes
