import DataLoader from 'dataloader'

import config from '@acre/config'
const { API1_URL, CLIENT_PORTAL_OATHKEEPER_API_URL, CLIENT_PORTAL_API_URL } = config

import { GraphQLError } from 'graphql'

import { FirstTimeAccess, Maybe, SupportUser, User, UserInput } from '../generated/resolvers'
import request from '../requesters/default'
import {
  CdmCreateUserResponse,
  CdmGetMeResponse,
  CdmGetUserResponse,
  CdmUpdateUserResponse,
  CdmUser,
} from '../service/luther/model'
import { AddressType, UUID } from '../types'

export type UserParent = {
  user_id: string
  first_name: string
  last_name: string
  address: AddressType
  first_time_access: FirstTimeAccess
  organisation_id: string
  primary_role_id: string
  phone_mobile: string
  phone_desk: string
  user_firm_identifier: string
  photo: string
}

export const formatUser = (user: CdmUser): User =>
  ({
    ...user,
    id: user.user_id,
    mobile_number: user.phone_mobile,
    phone_number: user.phone_desk,
    user_firm_identifier: user.user_firm_identifier,
  }) as User

export const fetchUsersForOrg = async (id?: Maybe<UUID>) => {
  const response = await request.get<CdmGetUserResponse>('/user', {
    params: {
      filter_organisation_id: id,
      user_details: true,
      page_size: 500,
    },
  })

  const users = response.data.users
  return (users || []).map((user) => formatUser(user))
}

export const fetchUsers = async (ids: UUID[], useClientApi?: Maybe<boolean>) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const baseApiUrl =
    useOathKeeper === 'true' ? CLIENT_PORTAL_OATHKEEPER_API_URL : useClientApi ? CLIENT_PORTAL_API_URL : undefined
  const axiosOpts = { baseURL: baseApiUrl }

  if (ids.length > 0) {
    const response = await request.get<CdmGetUserResponse>('/user', {
      ...axiosOpts,
      params: { user_ids: ids, user_details: true },
    })

    return response.data.users?.map((user) => formatUser(user)) || null
  }

  return []
}

export const fetchUser = async (id: UUID, useClientApi?: Maybe<boolean>) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const baseApiUrl =
    useOathKeeper === 'true' ? CLIENT_PORTAL_OATHKEEPER_API_URL : useClientApi ? CLIENT_PORTAL_API_URL : undefined
  const axiosOpts = { baseURL: baseApiUrl }
  const response = await request.get<CdmGetUserResponse>('/user', {
    ...axiosOpts,
    params: {
      user_ids: id,
      user_details: true,
    },
  })
  const users = response.data.users

  const user = users?.find(({ user_id }) => user_id === id)

  if (!user) {
    throw new GraphQLError(`User not found for id ${id}`, {
      extensions: {
        code: 'USER_NOT_FOUND',
      },
    })
  }

  return formatUser(user)
}
export const loginAsSupportUser = async (userId: UUID) => {
  const response = await request.get<{
    new_user: SupportUser
    original_user: SupportUser
  }>('/authentication/support/login', {
    baseURL: `${API1_URL}/acre`,
    params: {
      user_id: userId,
    },
  })
  return response.data.new_user
}

export const fetchCurrentUser = async () => {
  const response = await request.get<CdmGetMeResponse>(`/user/me`)

  const user = response.data.user

  if (!user) {
    throw new GraphQLError('Current user not found', {
      extensions: {
        code: 'USER_NOT_FOUND',
        status: 404,
      },
    })
  }

  return formatUser(user)
}

export const patchUser = async (id: string, input: UserInput) => {
  const { data } = await request.patch<CdmUpdateUserResponse>(`/user/${id}`, input)

  if (!data?.user) {
    throw new GraphQLError('User not updated', {
      extensions: {
        code: 'USER_NOT_UPDATED',
      },
    })
  }

  UserLoader.clear(id)

  return formatUser(data.user)
}

export const createUser = async (input: UserInput) => {
  const { data } = await request.post<CdmCreateUserResponse>(`/user/`, input)

  if (!data?.user) {
    throw new GraphQLError('User not created', {
      extensions: {
        code: 'USER_NOT_CREATED',
      },
    })
  }

  return formatUser(data.user)
}

export const changeUserEmail = async (id: string, newEmail: string) => {
  await request.post(
    `/acctmgmt/user/email/${id}`,
    {
      email_address: newEmail,
    },
    { baseURL: `${API1_URL}/acre` },
  )

  const updatedUser = await fetchUser(id)

  return updatedUser
}

export const UserLoader = new DataLoader(
  async (ids: string[]) => {
    if (ids.every((id) => id === 'me')) {
      const user = await fetchCurrentUser()
      return [user]
    }

    const filteredIds = ids.filter((id) => id !== 'me')
    const results = await fetchUsers(filteredIds)

    if (!results) {
      return ids.map(
        (id) =>
          new GraphQLError(`User not found for id: ${id}`, {
            extensions: {
              code: 'USER_NOT_FOUND',
              id,
            },
          }),
      )
    }

    return ids.map(
      (id) =>
        results.find((user) => user.id === id) ||
        new GraphQLError(`User not found for id: ${id}`, {
          extensions: {
            code: 'USER_NOT_FOUND',
            status: 404,
            id,
          },
        }),
    )
  },
  {
    // Max batch size to avoid BE timing out
    maxBatchSize: 30,
  },
)
