import { isEmpty, omit, uniq } from 'lodash'

import config from '@acre/config'

import {
  CaseLoader,
  ClientPropertyLoader,
  createClientOrganisationRelationship,
  fetchCase,
  fetchCasesForClient,
  fetchClientProperties,
  fetchProtectedClientFields,
  getDocumentSummaries,
  getMiDocumentSummaries,
  getMiDocumentTotals,
  requestEidvCheck,
  updateCase,
  updateProperty,
  upsertCompany,
} from '../../api'
import {
  addClient,
  fetchClient,
  fetchClientByVersion,
  fetchClientOrganisationRelationships,
  fetchClients,
  fetchClientsWithPagination,
  fetchClientVerifications,
  getClientByVersion,
  updateClient,
  updateClientAddresses,
  updateClientOrganisationRelationship,
} from '../../api/client'
import { fetchMortgages, updateMortgage } from '../../api/mortgage'
import { deleteCaseProtectionProduct, getProtectionProduct } from '../../api/protection_product'
import {
  ClientLoaderUser,
  ClientPropertyLoaderCp,
  fetchClientUser,
  fetchProtectedClientUserFields,
  getClientUserDocumentDetails,
  getClientUserDocumentSummaries,
  updateClientUser,
} from '../../api_client_portal'
import { createMandate, getNeedsMandate, getPayments } from '../../api_client_portal/accounting'
import { getDocumentVerificationCp } from '../../api_client_portal/document'
import {
  AddressResidentialStatus,
  ClientAddress,
  ClientInput,
  ClientRelationship,
  ClientVersion,
  Document,
  GetNeedsMandateResponse,
  GetPaymentsResponse,
  MarketingPreferences,
  Maybe,
  MiDocumentTotal,
  Mortgage,
  MortgageStatus,
  PropertyVersion,
  Resolvers,
  TemplateType,
} from '../../generated/resolvers'
import { CdmGetClientRequest, CdmGetProtectionProductRequest } from '../../service/luther/model'
import { isNonNaturalClient, separateRelationships } from '../../utils'
import { getDirectors } from '../../utils/getDirectors'
import { formatClientAsRequestBody, formatClientUser, formatNationalities } from '../../utils/schemaMapping'
import { GraphqlException } from '../util'
import {
  addClientAndUpdateCase,
  addNewClientProperty,
  isEmploymentCurrent,
  loadExistingClientProperties,
  removeClientsRequirementsOnTheCase,
  updatePropertyRegisteredOwners,
} from './Client.helpers'

const ClientResolver: Resolvers = {
  Deposit: {
    donors: async (_parent) => {
      if (_parent.donors && _parent.donors.length > 0) {
        return (await fetchClients({ client_ids: _parent.donors as unknown as string[] })) || null
      }
      return []
    },
  },
  Query: {
    clientList: async (_parent, variables) => {
      const response = await fetchClientsWithPagination({
        ...(variables as CdmGetClientRequest),
        page_size: variables.page_size ?? undefined,
      })

      return response
    },
    client: async (_parent, { id, version }) => {
      let client: ClientVersion

      if (version === undefined || version === null) {
        client = await fetchClient({ client_ids: [id] })
      } else {
        client = await fetchClientByVersion({ client_id: id, version })
      }

      return client
    },
    clientVersion: async (_parent, { id, version }) => await getClientByVersion(id, version),
    clientProtectedFields: async (_parent, { id, protectedFields }) => {
      const client = await fetchProtectedClientFields(id, protectedFields)

      return client || null
    },
    clients: async (_parent, params) => {
      const clients = await fetchClients({
        client_ids: params.ids || undefined,
        filter_organisation_id: params.organisationId || undefined,
      })

      return clients || null
    },
    clientVerifications: async (_parent, { id, show_pass, useClientApi }) =>
      await fetchClientVerifications(id, show_pass, useClientApi),
    clientOrganisationRelationship: async (_parent, { client_id, organisation_id_or_ext }) => {
      const { client_organisation_relationship } = await fetchClientOrganisationRelationships(
        client_id,
        organisation_id_or_ext,
      )
      return client_organisation_relationship || {}
    },
    clientOrganisationRelationships: async (_parent, { client_id }) => {
      const { client_organisation_relationship } = await fetchClientOrganisationRelationships(client_id)
      return (
        client_organisation_relationship && client_organisation_relationship.marketing_preferences
          ? client_organisation_relationship.marketing_preferences
          : []
      ) as MarketingPreferences[]
    },
    clientUser: async () => {
      const response = await fetchClientUser()

      return response?.client?.client_id ? formatClientUser(response?.client) : null
    },
    clientsUserDetails: async (_parent, params) => {
      if (!params.ids) {
        return []
      }

      const caseClients = await ClientLoaderUser.loadMany(params.ids)
      const cleanCaseClients = caseClients.filter((client) => client !== null) as ClientVersion[]

      const director_ids = getDirectors(cleanCaseClients)

      const clientsUsers = await ClientLoaderUser.loadMany([...params.ids, ...director_ids])

      const filteredClients = clientsUsers.filter((client) => client !== null) as ClientVersion[]

      return filteredClients
    },
    clientUserProtectedFields: async (_parent, { id, protectedFields }) => {
      const client = await fetchProtectedClientUserFields(id, protectedFields)
      if (!client) return null
      return client
    },
    getPayments: async () => {
      const payments = await getPayments()
      if (!payments) return {} as GetPaymentsResponse
      return payments
    },
    getNeedsMandate: async () => {
      const needsMandate = await getNeedsMandate()
      if (!needsMandate) return {} as GetNeedsMandateResponse
      return needsMandate
    },
  },
  Mutation: {
    addClient: (_parent, { input }) => addClient(input),
    changeRelationshipType: async (_parent, { relatedClientId, relationshipType, clientId, caseId }) => {
      const client = await fetchClient({ client_ids: [clientId] })

      if (!client) {
        return null
      }

      const { remainingRelationships, relevantPersonRelationship } = separateRelationships(
        relatedClientId,
        client.details.relationships,
      )

      // IF a relationship for this client already exists
      if (relevantPersonRelationship) {
        if (
          [
            ClientRelationship.Psc,
            ClientRelationship.DirectorTrustee,
            ClientRelationship.DirectorTrusteeAndPsc,
          ].includes(relationshipType) ||
          (relationshipType === ClientRelationship.InvalidRelationshipType && !caseId)
        ) {
          await updateClient(clientId, {
            relationships: [
              ...remainingRelationships,
              { ...relevantPersonRelationship, relationship_type: relationshipType },
            ],
          })
        } else if (relationshipType === ClientRelationship.InvalidRelationshipType && caseId) {
          await addClientAndUpdateCase(relatedClientId, caseId)

          // Remove the existing relationship
          await updateClient(clientId, {
            relationships: remainingRelationships,
          })
        }
        // When no caseId passed, it means that we are changing a relationship from the client's overview screen
        // in which case it should be impossible to remove the "regular" client from case as there is no case context
      } else if (caseId) {
        const fetchedCase = await fetchCase(caseId)
        await updateCase(
          {
            client_ids: fetchedCase.details.client_ids!.filter((id) => id !== relatedClientId),
          },
          caseId,
        )
        await updateClient(clientId, {
          relationships: [
            ...remainingRelationships,
            {
              relationship_data_access_permitted: true,
              relationship_reference: relatedClientId,
              relationship_type: relationshipType,
            },
          ],
        })
      }
      return relatedClientId
    },
    addClientAndUpdateCase: async (_parent, { newClientId, caseId }) => {
      return await addClientAndUpdateCase(newClientId, caseId)
    },
    // TODO FRON-3143 Move deleteClientAndUpdateCase resolver fn to Case resolvers section
    deleteClientAndUpdateCase: async (_parent, { deleteClientId, caseId }) => {
      const fetchedCase = await fetchCase(caseId)
      if (!fetchedCase?.details?.client_ids) return fetchedCase
      const caseClients = await fetchClients({ client_ids: fetchedCase.details.client_ids })

      // Delete client ids from mortgages on this case
      const caseMortgages = await fetchMortgages({
        filter_case_id: caseId,
      })

      const selectedAndProposedMortgages = caseMortgages?.filter(
        (mortgage) =>
          mortgage &&
          mortgage.status &&
          [MortgageStatus.StatusSelected, MortgageStatus.StatusProposed, MortgageStatus.StatusLenderProposed].includes(
            mortgage.status,
          ),
      )

      if (selectedAndProposedMortgages) {
        selectedAndProposedMortgages.forEach(async (mortgageDetails) => {
          if (!mortgageDetails) {
            return
          }
          await updateMortgage(mortgageDetails.mortgage_id, {
            client_ids: mortgageDetails.client_ids?.filter((clientId) => clientId !== deleteClientId),
          })
        })
      }

      const nonNaturalClient = caseClients?.find((client) => client && isNonNaturalClient(client.details))

      const { remainingRelationships, relevantPersonRelationship } = separateRelationships(
        deleteClientId,
        nonNaturalClient?.details.relationships,
      )
      const isClientOnCase = caseClients?.some((client) => client?.details.id === deleteClientId)
      const isClientNonNatural = deleteClientId === nonNaturalClient?.id

      // If client is stored directly on case, then remove the client directly from case
      if (isClientOnCase) {
        // we should remove the requirements, existing protection products and protection producst(benefits) from the case if we removing the client
        // basically everything client is associated to protection wise
        const variables = { filterCaseIds: [caseId], protectionProductDetails: true }
        // get the protection products on the case
        const protectionProducts = await getProtectionProduct(variables as CdmGetProtectionProductRequest)

        //filter the protection products that have the clients id
        const protectionProductsIdsWithDeletedClient = protectionProducts?.protection_products
          ?.filter((product) => product.details?.client_ids?.includes(deleteClientId))
          .map((product) => product.protection_id)
        // if there are any, remove them
        if (protectionProductsIdsWithDeletedClient) {
          await Promise.all(
            protectionProductsIdsWithDeletedClient.map(async (protection_id) => {
              protection_id && (await deleteCaseProtectionProduct(protection_id, caseId))
            }),
          )
        }
        //filter the requirements out of the fetchedCase and update the case
        const requirements = fetchedCase.details.protection?.requirements
        if (requirements) {
          const filteredRequirements = removeClientsRequirementsOnTheCase(requirements, deleteClientId)
          await updateCase(
            {
              protection: { ...fetchedCase.details.protection, requirements: filteredRequirements },
            },
            caseId,
          )
        }

        // If removing the non natural client, we need to make mark the case as not done through company and move
        // all regular clients on case and the directors of the non-natural client directly onto the case
        const naturalClients = fetchedCase.details.client_ids.filter((clientId: string) => deleteClientId !== clientId)
        const nonNaturalClients = (nonNaturalClient?.details.relationships || []).reduce<string[]>(
          (acc, relationship) => {
            if (relationship.relationship_reference) {
              acc.push(relationship.relationship_reference)
            }
            return acc
          },
          [],
        )

        const clientsFullList = uniq([...naturalClients, ...nonNaturalClients])

        if (isClientNonNatural) {
          await updateCase(
            {
              btl_through_company: false,
              client_ids: clientsFullList,
            },
            caseId,
          )
        } else if (isClientOnCase) {
          await updateCase(
            {
              client_ids: fetchedCase.details.client_ids.filter((clientId: string) => deleteClientId !== clientId),
            },
            caseId,
          )
        }
      }

      // If client shown on case as a result of a relationship to a non-natural client
      // then remove that relationship
      if (
        relevantPersonRelationship &&
        [ClientRelationship.DirectorTrustee, ClientRelationship.Psc, ClientRelationship.DirectorTrusteeAndPsc].includes(
          relevantPersonRelationship.relationship_type as ClientRelationship,
        )
      ) {
        await updateClient(nonNaturalClient!.id, { relationships: remainingRelationships })
      }
      CaseLoader.clear(caseId)
      return await fetchCase(caseId)
    },
    updateClient: async (_, { id, input }) => {
      const result = await updateClient(id, input)
      return result
    },
    updateClientAddresses: async (_, { id, input, client_ids, property_id, useClientApi }) => {
      //If client_ids are passed through, load the properties on both clients and check if any of the addresses match the addressHistoryInput address
      // and the client id in registered_owners_details is not the client. If that's the case update the property with both ids, if not add property.
      const client_id = id
      const filteredInput = input.map((address) => omit(address, 'property_location'))
      const response = await updateClientAddresses(client_id, filteredInput, useClientApi)

      if (response) {
        // If client_ids (dual client case) exists then retrieve the properties associated with the client_ids
        // or just use the id to load single clients existing properties
        const existingClientProperties = await loadExistingClientProperties(client_id, client_ids)

        input.forEach(async (updatedAddress: ClientAddress) => {
          // Check if any of the existing client/clients properties match the new updated address
          let existingProperty: Maybe<PropertyVersion> = null
          const { is_current_correspondence, residential_status } = updatedAddress

          if (!isEmpty(existingClientProperties)) {
            existingProperty = existingClientProperties!.find((property) => {
              return property.details?.address?.postcode === updatedAddress.address.postcode
            })
          }

          // If property is current correspondence and the status is owner occupier
          // update existing address if it exists, if not add a new property
          if (is_current_correspondence && residential_status === AddressResidentialStatus.OwnerOccupier) {
            const clientsToBeAddedAsOwners = client_ids?.filter(
              (currentId) => !existingProperty?.details?.registered_owners_details?.includes(currentId),
            )
            if (existingProperty?.id && !isEmpty(clientsToBeAddedAsOwners)) {
              await updatePropertyRegisteredOwners(existingProperty?.id, clientsToBeAddedAsOwners!)
            } else if (!existingProperty) {
              await addNewClientProperty(id, updatedAddress, property_id)
            }
            // If not current property or owner status, remove client from registered owner details if already included
          } else if (
            existingProperty?.id &&
            existingProperty?.details?.registered_owners_details?.includes(client_id)
          ) {
            const updatedOwners = existingProperty.details.registered_owners_details.filter(
              (ownerId) => ownerId !== client_id,
            )
            await updateProperty({ registered_owners_details: updatedOwners }, existingProperty.id)
            ClientPropertyLoader.clearAll()
          }
        })
      }
      return response
    },
    updateClientPreviousNames: async (_, { id, input }) => {
      // To stop from sending obfuscated data to backend,
      // we filter out previous_name with an *
      if (input?.previous_names) {
        input?.previous_names?.forEach((name, index, currentArray) => {
          if (name?.previous_first_name && name.previous_first_name.includes('**')) {
            delete currentArray[index]?.previous_first_name
          }
          if (name?.previous_surname && name.previous_surname.includes('**')) {
            delete currentArray[index]?.previous_surname
          }
        })
      }

      const result = updateClient(id, {
        previous_names: input.previous_names,
      })

      return result
    },
    runIdVerification: (_parent, { id }) => requestEidvCheck(id),
    updateClientIncome: (_, { id, input }) => updateClient(id, { income_and_employment: input }),
    updateClientAccounts: (_parent, { id, input }) => updateClient(id, { accounts: input }),
    updateClientDeposits: (_parent, { id, input }) => {
      const client = updateClient(id, { deposits: input })
      return client
    },
    updateClientDebts: (_parent, { id, input }) => updateClient(id, { debts: input }),
    updateClients: async (_, payload) => {
      const clients = await Promise.all(
        payload.clients.map((client: ClientInput) => {
          const { id, ...details } = client

          if (!id) {
            throw new GraphqlException('Cannot complete updateClients mutation without client id')
          }

          return updateClient(id, details)
        }),
      )

      return clients.filter(Boolean) as ClientVersion[]
    },
    updateClientCcjs: (
      _parent,
      { id, input: { credit_history_adverse_details_has_client_had_ccj, credit_history_county_court_judgements } },
    ) =>
      updateClient(id, {
        credit_history_adverse_details_has_client_had_ccj,
        credit_history_county_court_judgements,
      }),
    updateClientCreditRefusals: (
      _parent,
      {
        id,
        input: { credit_history_have_you_ever_had_an_application_denied_or_refused, mortgage_application_refused },
      },
    ) =>
      updateClient(id, {
        credit_history_have_you_ever_had_an_application_denied_or_refused,
        mortgage_application_refused,
      }),
    updateClientBankruptcyEvents: (
      _parent,
      {
        id,
        input: { credit_history_adverse_details_has_client_declared_bankruptcy, credit_history_bankruptcy_events },
      },
    ) =>
      updateClient(id, {
        credit_history_adverse_details_has_client_declared_bankruptcy,
        credit_history_bankruptcy_events,
      }),
    updateClientDefaultEvents: (
      _parent,
      { id, input: { credit_history_adverse_details_has_client_had_default, credit_history_default_events } },
    ) =>
      updateClient(id, {
        credit_history_adverse_details_has_client_had_default,
        credit_history_default_events,
      }),
    updateClientDebtManagementPlans: (
      _parent,
      {
        id,
        input: {
          credit_history_adverse_details_has_client_had_debt_management_plan,
          credit_history_debt_management_plans,
        },
      },
    ) =>
      updateClient(id, {
        credit_history_adverse_details_has_client_had_debt_management_plan,
        credit_history_debt_management_plans,
      }),
    updateClientArrears: (
      _parent,
      { id, input: { credit_history_adverse_details_has_arrears, credit_history_arrears } },
    ) =>
      updateClient(id, {
        credit_history_adverse_details_has_arrears,
        credit_history_arrears,
      }),
    updateClientIvas: (
      _parent,
      {
        id,
        input: { credit_history_adverse_details_has_client_had_iva, credit_history_individual_voluntary_arrangements },
      },
    ) =>
      updateClient(id, {
        credit_history_adverse_details_has_client_had_iva,
        credit_history_individual_voluntary_arrangements,
      }),
    updateClientOrganisationRelationship: async (_parent, { client_id, organisation_id_or_ext, input }) => {
      const { client_organisation_relationship } = await updateClientOrganisationRelationship(
        client_id,
        organisation_id_or_ext,
        input,
      )
      return (
        client_organisation_relationship &&
        client_organisation_relationship.marketing_preferences &&
        client_organisation_relationship.marketing_preferences
          ? client_organisation_relationship.marketing_preferences
          : []
      ) as MarketingPreferences[]
    },
    createClientOrganisationRelationship: async (_parent, { client_id, organisation_id_or_ext, input }) => {
      const { client_organisation_relationship } = await createClientOrganisationRelationship(
        client_id,
        organisation_id_or_ext,
        input,
      )
      return {
        client_id: client_organisation_relationship?.client_id,
        org_id: client_organisation_relationship?.org_id,
        relationship_type: client_organisation_relationship?.relationship_type,
      }
    },

    //Client Portal
    updateClientUser: async (_, { id, input }) => {
      const formattedInput = formatClientAsRequestBody(input)

      // By default spread nothing onto payload
      let hasAddressesToFormat = {}
      let hasNationalitiesToFormat = {}
      let hasCountryOfBirthToFormat = {}

      if (formattedInput.addresses && formattedInput.addresses.length > 0) {
        hasAddressesToFormat = {
          addresses: formattedInput.addresses,
        }
      }

      if (formattedInput.nationalities && formattedInput.nationalities.length > 0) {
        hasNationalitiesToFormat = {
          nationalities: formatNationalities(formattedInput.nationalities),
        }
      }

      await updateClientUser(id, {
        ...formattedInput,
        ...hasAddressesToFormat,
        ...hasNationalitiesToFormat,
        ...hasCountryOfBirthToFormat,
      })
      ClientLoaderUser.clearAll()

      const response = await fetchClientUser()
      return formatClientUser(response.client)
    },
    upsertCompany: (_parent, { company, user_id, organisation_id }) => upsertCompany(company, user_id, organisation_id),
    createMandate: async () => await createMandate(),
  },
  Client: {
    cases: async (_parent) => (_parent.client_id ? await fetchCasesForClient(_parent.client_id) : null),
    debts: async (parent) => parent.debts || [],
    documents: async ({ client_id: filter_owning_client_id }) => {
      const params = { filter_owning_client_id }
      const { documents } = await getDocumentSummaries(params)
      return (documents as Document[]) || []
    },
    miDocuments: async ({ client_id }, { filters }) => {
      return client_id ? await getMiDocumentSummaries('client', client_id, filters) : null
    },
    document_totals: async ({ id }, { filters }) => {
      const documentTotals = await getMiDocumentTotals('client', id, filters)

      return documentTotals as MiDocumentTotal[]
    },
    properties: async ({ client_id }) => {
      return client_id ? await fetchClientProperties(client_id) : null
    },
    mortgages: async ({ client_id }) => {
      if (!client_id) {
        return null
      }

      const mortgages = await fetchMortgages({ filter_client_ids: [client_id] })
      const formattedMortgages = mortgages
        ? (mortgages.filter(
            (mortgage) =>
              mortgage !== null &&
              (mortgage.status === MortgageStatus.StatusCreditReportCurrent ||
                mortgage.status === MortgageStatus.StatusCreditReportSettled),
          ) as Mortgage[])
        : []

      return formattedMortgages
    },

    // Client Portal
    clientUserIdd: async ({ client_id }) => {
      const { documents } = await getClientUserDocumentSummaries({ filter_owning_client_id: client_id })

      const iddDocuments = documents?.filter((document) => {
        return document.template_type === TemplateType.ClientPortalIdd
      })

      // Fetch document details of CP and CR Idds
      if (iddDocuments && iddDocuments.length > 0) {
        let iddDocumentsDetails = []
        for (const iddDocument of iddDocuments) {
          const documentDetails = await getClientUserDocumentDetails(iddDocument.document_id!, {
            document_details: true,
          })
          if (documentDetails?.document_id) {
            iddDocumentsDetails.push(documentDetails)
          }
        }

        // Filter out Credit Report Idds
        const clientPortalIdds = iddDocumentsDetails.filter(
          (doc) => !doc?.rendered_organisation_ids?.includes(config.ACRE_SUPPORT_ORG_ID),
        )

        // Get latest Client Portal Idd
        const latestClientPortalIdd = clientPortalIdds?.sort((a, b) =>
          a?.date_stored && b?.date_stored ? Date.parse(b.date_stored) - Date.parse(a.date_stored) : 0,
        )[0]

        // // Check if Client has already verified Client Poral Idd
        const docVerifications = await getDocumentVerificationCp(latestClientPortalIdd?.document_id!, {
          verification_details: true,
        })

        if (docVerifications?.verifications && client_id) {
          const clientVerification = docVerifications?.verifications.filter(
            (verification) => verification.client_id === client_id,
          )

          return !isEmpty(clientVerification) ? {} : latestClientPortalIdd
        }
        // Return latest Client Portal Idd if not yet signed
        return latestClientPortalIdd
      }
      return null
    },
    propertiesCp: async ({ client_id }) => {
      return client_id ? await ClientPropertyLoaderCp.load(client_id) : null
    },
  },
  ClientIncome: {
    is_current: async (_parent) => (_parent ? isEmploymentCurrent(_parent) : null),
  },
}

export default ClientResolver
