import { Maybe, MortgageProductIncentive } from '../../../generated/resolvers'
import { convertPoundsToPence } from '../../formatNumber/index'
import { SourcingMortgageState } from './mapToLutherMortgage.fixtures'

const mapIncentives = (
  incentives: SourcingMortgageState['incentive'],
): Maybe<MortgageProductIncentive[]> | undefined => {
  return incentives?.map((incentive) => ({
    id: String(incentive.id),
    amount: convertPoundsToPence(incentive['incentive.amount']?.toString()),
    type: incentive['incentive.type'],
    maxAmount: convertPoundsToPence(incentive['incentive.maxAmount']?.toString()),
    method: incentive['incentive.method'],
  }))
}

export default mapIncentives
