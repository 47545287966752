import { createContext, useContext } from 'react'

import { CaseVersion } from '@acre/graphql'

export type CaseContextType = CaseVersion | undefined

const CaseContext = createContext<CaseContextType>(undefined)
const CaseContextConsumer = CaseContext.Consumer
const CaseContextProvider = CaseContext.Provider
CaseContext.displayName = 'CaseContext'

export const useCaseContext = (canBeEmpty?: boolean) => {
  const context = useContext<CaseContextType>(CaseContext)
  if (!context && !canBeEmpty) {
    throw new Error('useCaseContext must be used within CaseContextProvider')
  }
  return context
}

export { CaseContext, CaseContextConsumer, CaseContextProvider }
