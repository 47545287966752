import { fetchAuthToken } from '../api/notifications'
import { Resolvers } from '../generated/resolvers'

const NotificationsResolver: Resolvers = {
  Query: {
    getNotificationsToken: async () => await fetchAuthToken(),
  },
}

export default NotificationsResolver
