import { createContext, useContext } from 'react'

import { ClientVersion, GetClientUserQuery, Maybe, VerificationCollection } from '@acre/graphql'

type Verifications = {
  verification_collections?: Maybe<VerificationCollection[]>
}

export type ClientContextType = Maybe<
  (GetClientUserQuery['clientUser'] | ClientVersion) & {
    verifications?: Verifications
  }
>

export const useClientContext = (canBeEmpty?: boolean) => {
  const context = useContext<ClientContextType>(ClientContext)
  if (!context && !canBeEmpty) {
    throw new Error('useClientContext must be used within ClientContextProvider')
  }
  return context
}

export const ClientContext = createContext<ClientContextType>(null)
export const ClientConsumer = ClientContext.Consumer
export const ClientProvider = ClientContext.Provider
