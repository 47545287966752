import DataLoader from 'dataloader'
import { isEqual, omit, unionWith } from 'lodash'

import configs from '@acre/config'

import {
  GetFilteredNoteResponse,
  GetNoteResponse,
  Maybe,
  MutationAddMessageArgs,
  MutationAddNoteArgs,
  MutationUpdateNoteArgs,
  Note,
  QueryGetFilteredNotesArgs,
  QueryGetNoteArgs,
  QueryGetNotesArgs,
} from '../generated/resolvers'
import request from '../requesters/default'
import { CdmCreateNoteResponse } from '../service/luther'

const { API1_URL, CLIENT_PORTAL_API_URL, CLIENT_PORTAL_OATHKEEPER_API_URL } = configs

// TODO: [RD-7987] This is a time bomb. We need to fix this.
let organisation_id: string
let useClientApi: boolean | null | undefined

const fetchAllCaseNotesFn = async (
  case_id: string,
  useClientApi: boolean | null | undefined,
): Promise<Maybe<Note[]>> => {
  const response: GetNoteResponse = await fetchCaseNotesFn({
    params: { organisation_id: organisation_id!, filter_case_id: case_id },
    useClientApi,
  })
  let next_page = response.next_page
  let notes = response.notes || null
  while (next_page) {
    const next_response: GetNoteResponse = await fetchCaseNotesFn({
      params: { ...next_page },
      useClientApi,
    } as QueryGetNotesArgs)
    next_page = next_response.next_page
    notes = unionWith(notes, next_response.notes, isEqual)
  }

  return notes
}

const fetchCaseNotesFn = async (params: QueryGetNotesArgs) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const baseUrl = useOathKeeper === 'true' ? CLIENT_PORTAL_OATHKEEPER_API_URL : CLIENT_PORTAL_API_URL
  const axiosOpts = useClientApi ? { baseURL: baseUrl, params: params.params } : { params: params.params }

  // const query = qs.stringify({ ...{ ...params }.params })
  const response = await request.get<GetNoteResponse>('/note', axiosOpts)
  return response.data
}

export const fetchCaseNote = async (args: QueryGetNoteArgs) => {
  const params = { organisation_id: args.params?.organisation_id!, note_ids: args.params?.note_ids?.[0]! }
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const baseUrl = useOathKeeper === 'true' ? CLIENT_PORTAL_OATHKEEPER_API_URL : CLIENT_PORTAL_API_URL
  const axiosOpts = args.useClientApi ? { baseURL: baseUrl, params } : { params }

  const response = await request.get<GetNoteResponse>('/note', axiosOpts)
  return response.data
}

export const CaseNotesLoader = new DataLoader(async (case_ids: string[]) => {
  return Promise.all(case_ids.map((case_id) => fetchAllCaseNotesFn(case_id, useClientApi)))
})

export const fetchFilteredNotes = async (params: QueryGetFilteredNotesArgs) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')
  const baseUrl = useOathKeeper === 'true' ? CLIENT_PORTAL_OATHKEEPER_API_URL : CLIENT_PORTAL_API_URL

  const axiosOpts = {
    baseURL: params.useClientApi ? baseUrl : `${API1_URL}/acre`,
    params: params.params,
  }

  const response = await request.get<GetFilteredNoteResponse>('/mi/notes/filtered', axiosOpts)
  return response.data
}

export const fetchAllCaseNotes = async (params: QueryGetNotesArgs) => {
  useClientApi = params.useClientApi
  organisation_id = params!.params!.organisation_id

  return CaseNotesLoader.load(params!.params!.filter_case_id!)
}

export const addNote = async (params: MutationAddNoteArgs) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')
  const baseUrl = useOathKeeper === 'true' ? CLIENT_PORTAL_OATHKEEPER_API_URL : CLIENT_PORTAL_API_URL
  useClientApi = params.useClientApi
  const axiosOpts = params.useClientApi ? { baseURL: baseUrl } : {}

  const response = await request.post<CdmCreateNoteResponse>(
    `/note`,
    omit(params.input, ['note_id', 'modified_at', 'created_at_at', 'created_by']),
    axiosOpts,
  )

  if (response.data.note && !params.isIntroducerCase) {
    if (!organisation_id) {
      organisation_id = params?.input?.organisation_ids?.[0]!
    }
    const existing = await CaseNotesLoader.load(response.data.note.case_id!)
    const modified = unionWith(existing, [response.data.note as Note], (a, b) => a.note_id == b.note_id)
    CaseNotesLoader.clear(response.data.note.case_id!).prime(response.data.note.case_id!, modified || [])
  }

  return response.data.note as Note | undefined
}

export const addMessage = async (params: MutationAddMessageArgs) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')
  const baseUrl = useOathKeeper === 'true' ? CLIENT_PORTAL_OATHKEEPER_API_URL : CLIENT_PORTAL_API_URL
  useClientApi = params.useClientApi
  const axiosOpts = params.useClientApi ? { baseURL: baseUrl } : {}

  const response = await request.post<{
    note: Maybe<Note>
  }>(`/note`, omit(params.input, ['note_id', 'modified_at', 'created_at_at', 'created_by']), axiosOpts)
  if (response.data.note && !params.isIntroducerCase) {
    const existing = await CaseNotesLoader.load(response.data.note.case_id!)
    const modified = unionWith(existing, [response.data.note], (a, b) => a.note_id == b.note_id)
    CaseNotesLoader.clear(response.data.note.case_id!).prime(response.data.note.case_id!, modified || [])
  }

  return response.data.note
}

export const updateNote = async (params: MutationUpdateNoteArgs) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')
  const baseUrl = useOathKeeper === 'true' ? CLIENT_PORTAL_OATHKEEPER_API_URL : CLIENT_PORTAL_API_URL
  const axiosOpts = params.useClientApi ? { baseURL: baseUrl } : {}

  const response = await request.patch<{ note: Maybe<Note> }>(
    `/note/${params.note_id}`,
    omit(params.input, ['note_id', 'modified_at', 'created_at_at', 'created_by']),
    axiosOpts,
  )

  CaseNotesLoader.clearAll()

  return response.data.note
}
