import { MortgageProductFeeTiming, MortgageProductFeeType } from '../../../generated/resolvers'
import { NonDeprecatedMortgageProductFeeType } from '../mapFeeTypeToCanBeAddedToLoan'

export const mapFeeTypeToFeeTiming: { [key in NonDeprecatedMortgageProductFeeType]: MortgageProductFeeTiming } = {
  [MortgageProductFeeType.AdministrationFee]: MortgageProductFeeTiming.UpFront,
  [MortgageProductFeeType.ApplicationFee]: MortgageProductFeeTiming.UpFront,
  [MortgageProductFeeType.ArrangementFee]: MortgageProductFeeTiming.UpFront,
  [MortgageProductFeeType.AssessmentFee]: MortgageProductFeeTiming.UpFront,
  [MortgageProductFeeType.BookingFee]: MortgageProductFeeTiming.UpFront,
  [MortgageProductFeeType.BrokerFee]: MortgageProductFeeTiming.UpFront,
  [MortgageProductFeeType.ChapsTelegraphicTransferFee]: MortgageProductFeeTiming.UpFront,
  [MortgageProductFeeType.CompletionFee]: MortgageProductFeeTiming.CompletionTiming,
  [MortgageProductFeeType.DeedsReleaseFee]: MortgageProductFeeTiming.Redemption,
  [MortgageProductFeeType.LegalFee]: MortgageProductFeeTiming.UpFront,
  [MortgageProductFeeType.EarlyRepaymentCharge]: MortgageProductFeeTiming.EarlyRedemption,
  [MortgageProductFeeType.HigherLendingCharge]: MortgageProductFeeTiming.UpFront,
  [MortgageProductFeeType.ValuationFee]: MortgageProductFeeTiming.UpFront,
  [MortgageProductFeeType.InsuranceAdministration]: MortgageProductFeeTiming.UpFront,
  [MortgageProductFeeType.LandRegistryFee]: MortgageProductFeeTiming.UpFront,
  [MortgageProductFeeType.LenderConveyancingFee]: MortgageProductFeeTiming.UpFront,
  [MortgageProductFeeType.MasterBrokerFee]: MortgageProductFeeTiming.UpFront,
  [MortgageProductFeeType.MortgageAccountFee]: MortgageProductFeeTiming.UpFront,
  [MortgageProductFeeType.OtherFee]: MortgageProductFeeTiming.UpFront,
  [MortgageProductFeeType.PackagerFee]: MortgageProductFeeTiming.UpFront,
  [MortgageProductFeeType.ProductTransferFee]: MortgageProductFeeTiming.UpFront,
  [MortgageProductFeeType.ReInspectionFee]: MortgageProductFeeTiming.UpFront,
  [MortgageProductFeeType.RedemptionAdminFee]: MortgageProductFeeTiming.Redemption,
  [MortgageProductFeeType.TitleDeedsAdminFee]: MortgageProductFeeTiming.UpFront,
  [MortgageProductFeeType.InvalidFeeType]: MortgageProductFeeTiming.InvalidTiming,
}
