import { createContext } from 'react'

export type LandRegistryTitleResponse = Array<LandRegistryTitleSummary>

export type LandRegistryTitleSummary = {
  title_number: string
  description?: string
  tenure?: string
  tenure_type?: number
  address?: LandRegistryAddress
  already_purchased: boolean
  full_data?: LandRegistryFullResponse
}

export type LandRegistryAddress = {
  BuildingName?: string
  SubBuildingName?: string
  BuildingNumber?: string
  StreetName?: string
  CityName?: string
  PostcodeZone?: {
    Postcode: string
  }
}

export type LandRegistryFullResponse = {
  title_number: string
  summary: {
    OfficialCopyDateTime: string
    EditionDate: string
    PricePaidEntry: {
      MultipleTitleIndicator: string
      EntryDetails: Q1EntryDetails
    }
    PropertyAddress: Address[]
    Title: {
      TitleNumber: string
      ClassOfTitleCode: string
      CommonholdIndicator: boolean
      TitleRegistrationDetails: {
        DistrictName: string
        AdministrativeArea: string
        LandRegistryOfficeName: string
        LatestEditionDate: string
        PostcodeZone: PostcodeZone[]
        RegistrationDate: string
      }
    }
    RegisterEntryIndicators: {
      AgreedNoticeIndicator: boolean
      BankruptcyIndicator: boolean
      CautionIndicator: boolean
      CCBIIndicator: boolean
      ChargeeIndicator: boolean
      ChargeIndicator: boolean
      ChargeRelatedRestrictionIndicator: boolean
      ChargeRestrictionIndicator: boolean
      CreditorsNoticeIndicator: boolean
      DeathOfProprietorIndicator: boolean
      DeedOfPostponementIndicator: boolean
      DiscountChargeIndicator: boolean
      EquitableChargeIndicator: boolean
      GreenOutEntryIndicator: boolean
      HomeRightsChangeOfAddressIndicator: boolean
      HomeRightsIndicator: boolean
      LeaseHoldTitleIndicator: boolean
      MultipleChargeIndicator: boolean
      NonChargeRestrictionIndicator: boolean
      NotedChargeIndicator: boolean
      PricePaidIndicator: boolean
      PropertyDescriptionNotesIndicator: boolean
      RentChargeIndicator: boolean
      RightOfPreEmptionIndicator: boolean
      ScheduleOfLeasesIndicator: boolean
      SubChargeIndicator: boolean
      UnidentifiedEntryIndicator: boolean
      UnilateralNoticeBeneficiaryIndicator: boolean
      UnilateralNoticeIndicator: boolean
      VendorsLienIndicator: boolean
    }
    Proprietorship: {
      CurrentProprietorshipDate: string
      CautionerParty: Party[]
      RegisteredProprietorParty: Party[]
    }
    Lease: {
      LeaseEntry: Array<{
        LeaseTerm: string
        LeaseDate: string
        Rent: string
        LeaseParty: Party[]
        EntryDetails: Q1EntryDetails
      }>
    }
    RestrictionDetails: {
      RestrictionEntry: Array<RestrictionContainer>
    }
    Charge: {
      ChargeEntry: Array<
        Charge & {
          ChargeID: string
          SubCharge: Charge[]
        }
      >
    }
    AgreedNotice: {
      AgreedNoticeEntry: Array<{
        AgreedNoticeType: string
        EntryDetails: Q1EntryDetails
      }>
    }
    Bankruptcy: EntryDetails
    Caution: EntryDetails
    DeedOfPostponement: EntryDetails
    GreenOutEntry: EntryDetails
    HomeRights: {
      HomeRightsEntry: Array<{
        ChangeOfAddressIndicator: boolean
        HomeRightsEntryDetails: Q1EntryDetails
        ChangeOfAddressEntryDetails: Q1EntryDetails
      }>
    }
    RentCharge: EntryDetails
    VendorsLien: EntryDetails
    RightOfPreEmption: EntryDetails
    DocumentDetails: {
      Document: Array<{
        DocumentType: string
        DocumentDate: string
        EntryNumber: string[]
        PlanOnlyIndicator: boolean
        FiledUnder: string
        RegisterDescription: string
      }>
    }
    UnilateralNoticeDetails: {
      UnilateralNoticeEntry: Array<{
        UnilateralNotice: Q1EntryDetails
        UnilateralNoticeBeneficiary: Q1EntryDetails
      }>
    }
    DeathOfProprietor: EntryDetails
    DiscountCharge: EntryDetails
    EquitableCharge: EntryDetails
    NotedCharge: EntryDetails
    CreditorsNotice: EntryDetails
    UnidentifiedEntry: EntryDetails
    CCBIEntry: EntryDetails
  }
  register: {
    PropertyRegister: Register
    ProprietorshipRegister: Register
    ChargesRegister: Register
  }
}

export type Register = {
  DistrictDetails: {
    EntryText: string[]
  }
  RegisterEntry: RegisterEntry[]
  Schedule: Array<{
    ScheduleType: string
    ScheduleEntry: RegisterEntry[]
  }>
}

export type RestrictionContainer = {
  ChargeRelatedRestriction: Restriction
  ChargeRestriction: Restriction
  NonChargeRestriction: Restriction
}

type RegisterEntry = {
  EntryNumber: string
  EntryDate: string
  EntryType: string
  EntryText: string[]
}

type Charge = {
  ChargeDate: string
  RegisteredCharge: {
    MultipleTitleIndicator: boolean
    EntryDetails: Q1EntryDetails
  }
  ChargeProprietor: {
    ChargeeParty: Party[]
    EntryDetails: Q1EntryDetails
  }
}

export type Restriction = {
  RestrictionTypeCode: string
  ChargeID: string
  EntryDetails: Q1EntryDetails
}

export type Party = {
  Address: Address[]
  CharityDetails: {
    CharityName: string[]
    CharityAddress: Address[]
    CharityType: string
  }
  TradingName: string
  PartyNumber: string
  PartyDescription: string
  PrivateIndividual: {
    Name: Name
    Alias: Name[]
  }
  Organization: {
    Name: string
    CompanyRegistrationNumber: string
  }
}

type Name = {
  ForenamesName: string
  SurnameName: string
}

type Address = {
  AddressLine: {
    IndexNumeric: number[]
    Line: string[]
  }
  PostcodeZone?: PostcodeZone
}

type PostcodeZone = {
  Postcode: string
}

type EntryDetails = {
  EntryDetails: Array<Q1EntryDetails>
}

type Q1EntryDetails = {
  EntryNumber: string
  EntryText: string
  RegistrationDate: string
  Infills: {
    Amount: string
    ChargeDate: string
    ChargeParty: TextType
    Date: string
    DeedDate: TextType
    DeedExtent: TextType
    DeedParty: TextType
    DeedType: TextType
    ExtentOfLand: TextType
    MiscellaneousText: TextType
    Name: TextType
    Note: TextType
    OptionalMiscText: TextType
    PlansReference: TextType
    TitleNumber: string
    VerbatimText: TextType
  }
  SubRegisterCode: string
  ScheduleCode: string
}

type TextType = {
  TextType: string | null
}

export const LandRegistryTitleContext = createContext<LandRegistryTitleResponse | undefined>(
  [] as LandRegistryTitleResponse,
)
