import configs from '@acre/config'

import {
  CalendarEventCreationNoteResponse,
  CalendarEventInput,
  MutationCreateSchedulerEventArgs,
  MutationCreateSchedulerEventDirectArgs,
  OauthRedirectInput,
  QueryGetSchedulerAvailabilityArgs,
  QueryGetSchedulerAvailabilityDirectArgs,
  QueryGetSchedulerEventInfoArgs,
  QueryGetSchedulerEventInfoDirectArgs,
  SchedulingInviteInput,
} from '../generated/resolvers'
import oathKeeper from '../requesters/oathKeeper'
const { CLIENT_PORTAL_API_URL, CLIENT_PORTAL_OATHKEEPER_API_URL } = configs
import { AxiosRequestConfig } from 'axios'

import cpRequest from '../requesters/clientPortal'
import request from '../requesters/default'
import {
  ApiInviteResponse,
  HttptypesAvailabilityResponse,
  HttptypesAvailabilityTimeSlot,
  HttptypesEventResponse,
  HttptypesEventsResponse,
  HttptypesSchedulingInfoResponse,
} from '../service/communications'

const { API1_URL } = configs

export const getOauthRedirectLink = async (input: OauthRedirectInput) => {
  //pull out acre_redirect, as qs encoding of symbols pastes extra characters in
  const { acre_redirect, ...params } = input

  const res = await request.post(`/communications/redirect/generate?acre_redirect=${acre_redirect}`, null, {
    baseURL: `${API1_URL}/acre`,
    params,
  })

  return res.data
}

export const oauthRevoke = async (id: string) => {
  await request.post(
    '/communications/auth/revoke',
    { id },
    {
      baseURL: `${API1_URL}/acre`,
    },
  )

  // return revoked id
  return id
}

export const oauthCheck = async () => {
  const res = await request.post('/communications/auth/check', null, {
    baseURL: `${API1_URL}/acre`,
  })
  return res?.data
}

export const userOauthCheck = async (ids: string | string[]) => {
  const res = await request.get('/communications/auth/check/users', {
    baseURL: `${API1_URL}/acre`,
    params: {
      scopes: 'calendar',
      user_ids: ids,
    },
  })
  return res.data
}

export const getFreeBusy = async (start: number, end: number, emails: string[]) => {
  const res = await request.post(
    `/communications/nylas/calendars/free-busy`,
    {
      start_time: start,
      end_time: end,
      emails,
    },
    {
      baseURL: `${API1_URL}/acre`,
    },
  )
  return res.data
}

export const createCalendarEvent = async (input: CalendarEventInput) => {
  const res = await request.post<CalendarEventCreationNoteResponse>('/communications/calendar/event', input, {
    baseURL: `${API1_URL}/acre`,
  })

  return res.data.note
}

const fetchSchedulingAvailabilityBatchFn = async (
  params: QueryGetSchedulerAvailabilityArgs | QueryGetSchedulerAvailabilityDirectArgs,
) => {
  let startTime = params.startTime
  let endTime = params.endTime
  const requestsData = []
  const weekInSeconds = 604800
  const chunksNeeded = Math.ceil((endTime - startTime) / weekInSeconds)
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')
  const baseUrl = useOathKeeper === 'true' ? CLIENT_PORTAL_OATHKEEPER_API_URL : CLIENT_PORTAL_API_URL

  while (requestsData.length < chunksNeeded) {
    let endTimeChunk = startTime + weekInSeconds
    if (endTimeChunk > endTime) {
      endTimeChunk = endTime
    }
    // set start and end time for start of the day and end of the day by seconds
    const startDateAtMidnight = new Date(startTime * 1000).setHours(0, 0, 0, 0)
    const endDateAtEndOfDate = new Date(endTimeChunk * 1000).setHours(23, 59, 59, 0)
    const chunk = {
      start_time: startDateAtMidnight / 1000,
      end_time: endDateAtEndOfDate / 1000,
    }

    requestsData.push(chunk)
    // this is for the next iteration, otherwise we have a bug when end date on last iteration does not update correctly
    startTime = endTimeChunk + 1 // Move to the next chunk period
  }

  const requests = requestsData.map(async (requestData) => {
    const axiosConfig: AxiosRequestConfig = {
      baseURL: `${API1_URL}/acre`,
      params: { start_time: requestData.start_time, end_time: requestData.end_time },
    }

    let url = ''

    if ('key' in params) {
      url = '/communications/scheduling/availability'
      axiosConfig.params.key = params.key
    } else if (params.caseId) {
      url = `/communications/scheduling/direct/${params.caseId}/availability`
      axiosConfig.baseURL = baseUrl
    }

    if (!url) {
      console.warn('Availability requested without URL, something went wrong.')
    }

    const response = await request.get<HttptypesAvailabilityResponse>(url, axiosConfig)

    return response
  })

  const responses = await Promise.all(requests)

  return responses.map((response) => response.data)
}

export const getSchedulingAvailability = async (
  params: QueryGetSchedulerAvailabilityArgs | QueryGetSchedulerAvailabilityDirectArgs,
) => {
  const response = await fetchSchedulingAvailabilityBatchFn(params)

  const allTimeSlots = response.reduce((acc, data) => {
    if (data.time_slots) {
      acc.push(...data.time_slots)
    }

    return acc
  }, [] as HttptypesAvailabilityTimeSlot[])

  return {
    time_slots: allTimeSlots ?? [],
  }
}

export const getSchedulerEventInfo = async (
  input: QueryGetSchedulerEventInfoArgs | QueryGetSchedulerEventInfoDirectArgs,
) => {
  const axiosConfig: AxiosRequestConfig = {
    baseURL: `${API1_URL}/acre`,
  }
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')
  const baseUrl = useOathKeeper === 'true' ? CLIENT_PORTAL_OATHKEEPER_API_URL : CLIENT_PORTAL_API_URL
  let url = ''

  if ('key' in input) {
    url = '/communications/scheduling/info'
    axiosConfig.params = { key: input.key }
  } else if (input.caseId) {
    url = `/communications/scheduling/direct/${input.caseId}/info`
    axiosConfig.baseURL = baseUrl
  }

  const response = await request.get<HttptypesSchedulingInfoResponse>(url, axiosConfig)
  return response.data
}

export const createSchedulerEvent = async (
  input: MutationCreateSchedulerEventArgs | MutationCreateSchedulerEventDirectArgs,
) => {
  const axiosConfig: AxiosRequestConfig = {
    baseURL: `${API1_URL}/acre`,
  }

  const useOathKeeper = sessionStorage.getItem('useOathKeeper')
  const baseUrl = useOathKeeper === 'true' ? CLIENT_PORTAL_OATHKEEPER_API_URL : CLIENT_PORTAL_API_URL

  let url = ''
  let variables

  if ('key' in input) {
    url = '/communications/scheduling/event'
    axiosConfig.params = { key: input.key }
    variables = { start_time: input.start_time }
  } else if (input.caseId) {
    url = `/communications/scheduling/direct/${input.caseId}`
    axiosConfig.baseURL = baseUrl
    variables = { start_time: input.start_time, title: input.title }
  }

  const response = await request.post<HttptypesEventResponse>(url, variables, axiosConfig)
  return response.data
}

export const sendSchedulingInvite = async (inviteRequest: SchedulingInviteInput) => {
  const res = await request.post<ApiInviteResponse>('/communications/scheduling_invite', inviteRequest, {
    baseURL: `${API1_URL}/acre`,
  })

  return res.data
}

export const getScheduledEventsOnCase = async (caseId: string) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')
  const requester = useOathKeeper === 'true' ? oathKeeper : cpRequest

  const res = await requester.get<HttptypesEventsResponse | ''>(`/communications/scheduling/direct/${caseId}`)

  return res.data !== '' && res.data.events ? res.data.events : []
}

export const getIsDirectSchedulingAllowed = async (caseId: string) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')
  const requester = useOathKeeper === 'true' ? oathKeeper : cpRequest

  const res = await requester.get(`/communications/scheduling/direct/${caseId}/allowed`, {
    validateStatus: () => true,
  })

  if (res.status === 200) return true

  return false
}
