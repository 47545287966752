import { AxiosError } from 'axios'
import { uniq } from 'lodash'

import configs from '@acre/config'

import {
  ApplyForDip,
  Dip,
  DipDetails,
  DipInput,
  DipStatusInput,
  SubmitDip,
  SubmitDipInput,
} from '../generated/resolvers'
import request from '../requesters/default'

const { API1_URL } = configs

type DipError = {
  detail?: {
    errors?: Array<{ message: string }>
  }
}

type submitDipProxy = SubmitDip & { _detail: DipDetails }

export const canDoDip = async (input: DipInput) => {
  if (!input.mortgage_ids.length) {
    return []
  }
  const response = await request.get<Dip[]>('/mortgage-application/can_do_dip', {
    baseURL: `${API1_URL}/acre`,
    params: {
      case_id: input.case_id,
      mortgage_id: input.mortgage_ids,
      existing_mortgage_id: input.existing_mortgage_id,
    },
  })

  return response.data
}

export const submitDip = async (payloadInput: DipInput, queryInput?: SubmitDipInput | null) => {
  if (payloadInput.mortgage_ids.length !== 1) {
    throw new Error('You must only try to DIP one mortgage at a time')
  }
  const mortgageAccountNumber = queryInput?.mortgage_account_number
  const previousLenderName = queryInput?.previous_lender_name

  const params = queryInput
    ? {
        mortgage_account_number: mortgageAccountNumber,
        previous_lender_name: previousLenderName,
        date_paid_off: queryInput?.date_paid_off,
        current_rent: queryInput?.current_rent,
        client0industry: queryInput?.client0industry,
        client1industry: queryInput?.client1industry,
      }
    : undefined

  const payload = {
    case_id: payloadInput.case_id,
    mortgage_id: payloadInput.mortgage_ids[0],
    existing_mortgage_id: payloadInput.existing_mortgage_id,
  }

  const response = await request
    .post<submitDipProxy>('/mortgage-application/submitDip', payload, {
      baseURL: `${API1_URL}/acre`,
      params,
    })
    .catch((res) => {
      // this block handles the detailed errors we sometimes get back from providers
      const e = res as AxiosError<DipError>
      if (e.isAxiosError) {
        const errors = e.response?.data?.detail?.errors
        if (errors?.length) {
          throw new Error(uniq(errors.map((err) => err.message)).join(' '))
        }
      }
      throw res
    })
  const data = response?.data
  if (data?._detail) {
    data.details = data._detail
  }
  return data as SubmitDip
}

export const getDipStatus = async (input: DipStatusInput) => {
  const response = await request.get<submitDipProxy>('/mortgage-application/status', {
    baseURL: `${API1_URL}/acre`,
    params: {
      id: input.dip_application_id,
      mortgage: input.mortgage_id,
    },
  })
  const data = response?.data
  if (data?._detail) {
    data.details = data._detail
  }
  return data as SubmitDip
}

export const applyForDip = async (id: string, mortgage: string) => {
  const response = await request.get<ApplyForDip>('/mortgage-application/fma', {
    baseURL: `${API1_URL}/acre`,
    params: {
      id,
      mortgage,
    },
  })
  return response.data
}
