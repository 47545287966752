import { getOauthRedirectLink as getLink, oauthCheck, oauthRevoke, userOauthCheck } from '../api/communications'
import { Resolvers } from '../generated/resolvers'

const OauthResolver: Resolvers = {
  Query: {
    getOauthRedirectLink: async (_, variables) => await getLink(variables.input),
    getOauthCheck: async () => await oauthCheck(),
    getOauthCheckForUsers: async (_, { ids }) => (ids ? await userOauthCheck(ids) : null),
  },
  Mutation: {
    revokeOauth: async (_, variables) => await oauthRevoke(variables.id),
  },
}

export default OauthResolver
