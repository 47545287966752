import { ClientVersion } from '../../generated/resolvers'
import { CdmClient } from '../../service/luther'
import { getCountryName } from '../countries'

export const formatClientUser = (client: CdmClient): ClientVersion => {
  const { details } = client
  const nationalities =
    details?.nationalities && details.nationalities.length > 0
      ? details.nationalities.reduce((acc, countryCode) => {
          const countryName = getCountryName(countryCode)
          if (countryName) {
            return [...acc, countryName]
          }
          return acc
        }, [] as string[])
      : null

  return {
    id: client.client_id,
    ...client,
    // @ts-ignore we do not return organisation here because it is resolved down the tree
    details: {
      ...details,
      id: client.client_id,
      nationalities,
    },
  } as ClientVersion
}
