import { LutherException } from '@acre/graphql'

import { errorMessages, formatErrorMessage } from '../errorHandler'

export const chooseSecurityViolationMessage = (
  statusCode: LutherException['statusCode'],
  originalErrorId: LutherException['id'],
  lutherMessage: LutherException['description'],
) => {
  // currently ExceptionType.SecurityViolation has different status codes
  // related to it, this differentiates based on error status codes.
  let message

  switch (statusCode) {
    case 403: {
      const genericMessage = formatErrorMessage(errorMessages.unauthorised, {
        id: originalErrorId,
      })
      const specificMessage = lutherMessage ? ` ${lutherMessage}.` : ''

      message = `${genericMessage}${specificMessage}`
      break
    }
    case 401:
    default: {
      message = formatErrorMessage(errorMessages.login, {
        id: originalErrorId,
      })
    }
  }

  return message
}
