import config from '@acre/config'

import request from '../requesters/default'

export type valuation = {
  range: valuationRange
  valuation: number
  breakdown?: valuationFactor[]
}

export type valuationRange = {
  from: number
  to: number
}

export type valuationFactor = {
  estimate: number
  method: string
  note?: string
  weight: number
}

const getValuation = async (acrePropertyID: number) => {
  const response = await request.get<valuation>('/valuation', {
    baseURL: config.PROPERTY_INFO_API_URL,
    params: {
      propertyID: acrePropertyID,
    },
  })
  return response.data
}

export default getValuation
