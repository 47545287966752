import { getKbaQuestionnaire, submitKba } from '../api/checks'
import { Resolvers } from '../generated/resolvers'
import { mortgageLoader } from '../loaders/mortgage'

const KbaResolvers: Resolvers = {
  Query: {
    kbaQuestionnaire: async (_, { userId, useClientApi }) => {
      return await getKbaQuestionnaire(userId, useClientApi)
    },
  },
  Mutation: {
    submitKbaQuestionnaire: async (_, { userId, input, useClientApi }) => {
      if (!useClientApi) {
        mortgageLoader.clear({ filter_client_ids: [userId] })
      }
      return await submitKba(userId, input, useClientApi)
    },
  },
}

export default KbaResolvers
