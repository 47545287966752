import { createContext } from 'react'

const FieldDisabledContext = createContext<boolean>(false)

FieldDisabledContext.displayName = 'FieldDisabledContext'

export const FieldDisabledConsumer = FieldDisabledContext.Consumer
export const FieldDisabledProvider = FieldDisabledContext.Provider

export default FieldDisabledContext
