import { v4 as generateUniqueId } from 'uuid'

import {
  AdditionalLoanPurposes,
  Mortgage,
  MortgageDipStatus,
  MortgageStatus,
  PaymentStatusSummary,
  TermUnit,
} from '../../generated/resolvers'
import { CdmMortgage } from '../../service/luther/model'

export const formatMortgage = <T extends CdmMortgage>(mortgage: T) => {
  const id = mortgage.mortgage_id ? mortgage.mortgage_id : generateUniqueId()

  return {
    ...mortgage,
    // the mortgages from Equifax do not contain IDs, so we need to add them manually
    id: id,
    mortgage_id: id,
    // Ideally we would make status mandatory, but the data model allows
    // for mortgages without status, so we should accomodate just in case
    status: (mortgage.status as MortgageStatus) || null,
    additional_loan: {
      amount: mortgage.additional_loan_amount,
      purposes: mortgage.additional_loan_purposes as unknown as AdditionalLoanPurposes,
      reason_description: mortgage.additional_loan_reason_description,
    },
    mortgage_product: {
      id: mortgage.mortgage_product_id,
    },
    term: mortgage.term !== undefined && mortgage.term !== null ? Number(mortgage.term) : undefined,
    term_unit: mortgage.term_unit ? (mortgage.term_unit as TermUnit) : undefined,
    mortgage_product_source_rank:
      mortgage.mortgage_product_source_rank !== undefined && mortgage.mortgage_product_source_rank !== null
        ? Number(mortgage.mortgage_product_source_rank)
        : undefined,
    dip_status: mortgage.dip_status ? (mortgage.dip_status as MortgageDipStatus) : undefined,
    payment_status_summary: mortgage.payment_status_summary
      ? (mortgage.payment_status_summary as PaymentStatusSummary)
      : undefined,
  } as Mortgage
}
