import {
  GetSolutionSourcingResultsResponse,
  ProtectionAuth,
  RunSolutionSourcingInput,
  RunSolutionSourcingResponse,
  SaveToCaseInput,
  SplitCoverBenefitsInput,
} from '../generated/resolvers'
import { SyncProtectionDocumentsInput } from '../main'
import request from '../requesters/protection'

type ProductApplyResponse = {
  url: string
}

export const splitProductEstimate = async (body: SplitCoverBenefitsInput) => {
  const response = await request.post('/quote', body)

  return response?.data
}

export const runSolutionSourcing = async (body: RunSolutionSourcingInput): Promise<RunSolutionSourcingResponse> => {
  const response = await request.post<RunSolutionSourcingResponse>('/solutions', body)
  return response?.data
}

export const saveProtectionProductsToCase = async (body: SaveToCaseInput) => {
  const response = await request.post('/saveToCase', body)
  return response?.data
}

export const getSolutionSourcingResults = async (location: string) => {
  // We need to remove the prefix that out API utils automatically prepend
  // to each URL, because the protection service returns a location with
  // that prefix already included.
  const prefix = '/v1/acre/protection'
  const locationWithoutPrefix = location.replace(prefix, '')
  const response = await request.get<GetSolutionSourcingResultsResponse>(decodeURIComponent(locationWithoutPrefix))
  return response?.data
}

export const productApply = async (integratorQuoteReference: string) => {
  const response = await request.get<ProductApplyResponse>(`/products/${integratorQuoteReference}/applyDirect`)
  return response?.data
}

export const checkAuthProtection = async (body: ProtectionAuth) => {
  const response = await request.post('/check_auth', body)
  return response?.data
}

export const syncProtectionDocuments = async (body: SyncProtectionDocumentsInput) => {
  const response = await request.post('/syncProtectionDocuments', body)

  return response?.data
}
