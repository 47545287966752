import type { Theme } from '@mui/material/styles'

export const headingStyles = (theme: Theme) => ({
  h1: {
    fontSize: theme.typography.pxToRem(42),
    lineHeight: theme.typography.h1.lineHeight?.valueOf(),
  },
  h2: {
    fontSize: theme.typography.pxToRem(32),
    lineHeight: theme.typography.h2.lineHeight?.valueOf(),
  },
  h3: {
    fontSize: theme.typography.pxToRem(26),
    lineHeight: theme.typography.h3.lineHeight?.valueOf(),
  },
  h4: {
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.h4.lineHeight?.valueOf(),
  },
  h5: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.h5.lineHeight?.valueOf(),
  },
  h6: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.h6.lineHeight?.valueOf(),
  },
})
