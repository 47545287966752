import React, { createContext, useState } from 'react'

type OnboardingProviderContext = {
  children: React.ReactNode
}

type OnboardingContextType = {
  isOnboardingCompleted: boolean
  setOnboardingCompleted: (isCompleted: boolean) => void
}

// @ts-ignore - Empty context type
export const OnboardingContext = createContext<OnboardingContextType>({})

// used to see if user has already attempted to complete onboarding
// context with hook to have controls from any component
export const OnboardingProvider = ({ children }: OnboardingProviderContext) => {
  // Use State to keep the values
  const [isOnboardingCompleted, setOnboardingCompleted] = useState<boolean>(false)

  // Make the context object
  const onboardingContext = {
    isOnboardingCompleted,
    setOnboardingCompleted,
  }

  // pass the value in provider and return
  return <OnboardingContext.Provider value={onboardingContext}>{children}</OnboardingContext.Provider>
}

export const { Consumer: OnboardingConsumer } = OnboardingContext
