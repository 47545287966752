import { styled, Theme } from '@mui/material'

import { Variant } from '../../utils/constants'
import testHandle from '../../utils/testHandle'

export type Props = {
  variant?: Variant
  inverted?: boolean
  inButton?: boolean
  inDropdown?: boolean
  isPlaceholder?: boolean
  noMargin?: boolean
}

const compactStyles = (theme: Theme) => `
  width:  ${theme.spacers.size20};
  height:  ${theme.spacers.size20};
`

const largeStyles = (theme: Theme) => `
  width: ${theme.spacers.size64};
  height: ${theme.spacers.size64};
`

const LoadingSpinnerNoProps = styled('div')<{
  inverted?: boolean
  isPlaceholder?: boolean
  variant?: Variant
  noMargin?: boolean
  inButton?: boolean
}>(
  ({ inverted, isPlaceholder, variant, noMargin, inButton, theme }) => `
  @keyframes donut-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  display: inline-block;
  border: 4px solid ${inverted ? 'rgba(0,0,0,0.2)' : 'rgba(0,0,0,.1)'};
  border-left-color: ${isPlaceholder ? 'rgba(0,0,0,.1)' : inverted ? '#FFF' : theme.palette.primary.main};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: donut-spin 1.2s linear infinite;
  display: block;
  margin: ${noMargin ? '0' : 'auto'};

  ${(variant === 'compact' || variant === 'compactLeftAligned') && compactStyles(theme)};
  ${variant === 'large' && largeStyles(theme)};


  ${
    inButton &&
    `
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
  `
  }
`,
)

const attrs = {
  'data-testid': testHandle('LoadingSpinner'),
  id: 'LoadingSpinner',
}

const LoadingSpinner = (props: Props) => <LoadingSpinnerNoProps {...attrs} {...props} />

export { LoadingSpinner }
