import { omit } from 'lodash'

import { formatFeeAsAcre, formatFeeAsLuther } from '../../api/common.helpers'
import { ClientVersion, NewFee, Organisation, OrganisationInput, User } from '../../generated/resolvers'
import { CdmOrganisation } from '../../service/luther'

export const formatOrganisationPayload = (input: OrganisationInput) => {
  const inputClone = { ...input }

  // If the primary_address is available, we want to include it in the PATCH payload,
  // If it doesn't exist, we don't want to accidentally override the primary address
  // with a null value

  if (input.primary_address === null) {
    delete inputClone.primary_address
  }

  if (input.complaints_address === null) {
    delete inputClone.complaints_address
  }

  const fees = input.fees ? input.fees.map((fee) => formatFeeAsLuther(fee as NewFee)) : undefined

  const standard_fee_options = input.standard_fee_options
    ? input.standard_fee_options.map((option) => ({
        ...option,
        fees: option.fees?.map((fee) => formatFeeAsLuther(fee as NewFee)),
      }))
    : undefined

  return { ...inputClone, fees, standard_fee_options }
}

export const formatOrganisationResponse = (organisation: CdmOrganisation) =>
  ({
    ...omit(organisation, ['bank_details_sort_code', 'bank_details_account_no']),
    id: organisation.organisation_id,
    organisation_id: organisation.organisation_id,
    external_id: organisation.organisation_ext_identifier,
    primary_address: organisation.primary_address,
    complaints_address: organisation.complaints_address,
    bank_account: {
      sort_code: organisation.bank_details_sort_code,
      number: organisation.bank_details_account_no,
    },
    users: [] as User[], // Resolved in resolvers/organisation.ts
    clients: [] as ClientVersion[], // Resolved in resolvers/organisation.ts
    fees: organisation.fees?.map(formatFeeAsAcre) || [],
    standard_fee_options:
      organisation.standard_fee_options?.map((option) => ({
        ...option,
        fees: option.fees?.map(formatFeeAsAcre),
      })) || [],
    administered_by: organisation.administered_by ? ({ id: organisation.administered_by } as Organisation) : null,
  }) as Organisation
