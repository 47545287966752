import { MortgageProductFeeType } from '../../../generated/resolvers'
import { ProviderFeeType } from './mapToLutherMortgage.fixtures'

export const mapToLutherFeeType: { [key in ProviderFeeType]: MortgageProductFeeType } = {
  [ProviderFeeType.AdministrationFee]: MortgageProductFeeType.AdministrationFee,
  [ProviderFeeType.ApplicationFee]: MortgageProductFeeType.ApplicationFee,
  [ProviderFeeType.ArrangementFee]: MortgageProductFeeType.ArrangementFee,
  [ProviderFeeType.AssessmentFee]: MortgageProductFeeType.AssessmentFee,
  [ProviderFeeType.BookingFee]: MortgageProductFeeType.BookingFee,
  [ProviderFeeType.BrokerFee]: MortgageProductFeeType.BrokerFee,
  [ProviderFeeType.ChapsTelegraphicTransferFee]: MortgageProductFeeType.ChapsTelegraphicTransferFee,
  [ProviderFeeType.CompletionFee]: MortgageProductFeeType.CompletionFee,
  [ProviderFeeType.DeedsReleaseFee]: MortgageProductFeeType.DeedsReleaseFee,
  [ProviderFeeType.DisbursementFee]: MortgageProductFeeType.LegalFee,
  [ProviderFeeType.EarlyRepaymentCharge]: MortgageProductFeeType.EarlyRepaymentCharge,
  [ProviderFeeType.HigherLendingCharge]: MortgageProductFeeType.HigherLendingCharge,
  [ProviderFeeType.HomebuyersFee]: MortgageProductFeeType.ValuationFee,
  [ProviderFeeType.InsuranceAdministration]: MortgageProductFeeType.InsuranceAdministration,
  [ProviderFeeType.LandRegistryFee]: MortgageProductFeeType.LandRegistryFee,
  [ProviderFeeType.LegalFee]: MortgageProductFeeType.LegalFee,
  [ProviderFeeType.LenderConveyancingFee]: MortgageProductFeeType.LenderConveyancingFee,
  [ProviderFeeType.MasterBrokerFee]: MortgageProductFeeType.MasterBrokerFee,
  [ProviderFeeType.MortgageAccountFee]: MortgageProductFeeType.MortgageAccountFee,
  [ProviderFeeType.OtherFee]: MortgageProductFeeType.OtherFee,
  [ProviderFeeType.PackagerFee]: MortgageProductFeeType.PackagerFee,
  [ProviderFeeType.ProductTransferFee]: MortgageProductFeeType.ProductTransferFee,
  [ProviderFeeType.ReInspectionFee]: MortgageProductFeeType.ReInspectionFee,
  [ProviderFeeType.RedemptionAdminFee]: MortgageProductFeeType.RedemptionAdminFee,
  [ProviderFeeType.TitleDeedsAdminFee]: MortgageProductFeeType.TitleDeedsAdminFee,
  [ProviderFeeType.ValuationFee]: MortgageProductFeeType.ValuationFee,
}
