import { GraphQLError } from 'graphql'

import configs from '@acre/config'

import { SetupOrganisationInput, UserInput } from '../generated/resolvers'
import request from '../requesters/default'
import { CdmOrganisation, CdmUser } from '../service/luther'
import { formatOrganisationPayload, formatOrganisationResponse } from '../utils/schemaMapping/organisations'
import { formatUser } from './user'

const { API1_URL } = configs

// const formatOrganisationPayload = (input: OrganisationInput) => {
//   // If the primary_address is available, we want to include it in the PATCH payload,
//   // If it doesn't exist, we don't want to accidentally override the primary address
//   // with a null value
//   let additionalFields = {}
//   if (input.primary_address) {
//     additionalFields = {
//       primary_address: {
//         address1: input?.primary_address?.address1,
//         address2: input?.primary_address?.address2,
//         address3: input?.primary_address?.address3,
//         posttown: input?.primary_address?.posttown,
//         postcode: input?.primary_address?.postcode,
//         county: input?.primary_address?.county,
//         country: input?.primary_address?.country,
//       },
//     }
//   }

//   if (input.complaints_address) {
//     additionalFields = {
//       complaints_address: {
//         address1: input?.complaints_address?.address1,
//         address2: input?.complaints_address?.address2,
//         address3: input?.complaints_address?.address3,
//         posttown: input?.complaints_address?.posttown,
//         postcode: input?.complaints_address?.postcode,
//         county: input?.complaints_address?.county,
//         country: input?.complaints_address?.country,
//       },
//     }
//   }

//   const fees = input.fees?.map((fee) => formatFeeAsLuther(fee as NewFee))

//   // TODO: should we format the standard_fee_options here too?

//   return { ...input, ...additionalFields, fees }
// }

export const setupOrganisation = async (input: SetupOrganisationInput) => {
  const response = await request.post<{
    organisation: CdmOrganisation
    user: CdmUser
  }>(
    '/acctmgmt/setup/createorganisation',
    {
      user: input.user,
      organisation: formatOrganisationPayload(input.organisation),
    },
    {
      baseURL: `${API1_URL}/acre`,
    },
  )
  return {
    ...formatOrganisationResponse(response.data.organisation),
    users: response.data.user ? [formatUser(response.data.user)] : null,
  }
}

export const setupUser = async (input: UserInput) => {
  const response = await request.post<{
    user: CdmUser
  }>(
    `/acctmgmt/setup/createuser`,
    { user: input },
    {
      baseURL: `${API1_URL}/acre`,
    },
  )

  if (!response.data.user) {
    throw new GraphQLError('Unable to create user', {
      extensions: {
        code: 'Unable to create user',
      },
    })
  }

  return formatUser(response.data.user)
}
