import configs from '@acre/config'

import { Maybe, MiOrganisationsInput, MiOrganisationsResponse } from '../generated/resolvers'
import request from '../requesters/default'

const { API1_URL } = configs

export const getMiOrganisations = async (filters?: Maybe<MiOrganisationsInput>) => {
  const response = await request.get<MiOrganisationsResponse>('/mi/global/organisations', {
    baseURL: `${API1_URL}/acre`,
    params: filters,
  })
  return response.data
}
