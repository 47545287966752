import { useContext, useEffect } from 'react'

import { useLocalStorage, UserContext } from '@acre/utils'
import { Maybe, User } from '@acre/graphql'

export const iconStyle = {
  color: 'white',
  backgroundColor: 'transparent',
}

export const useSidebarDrawerOpenLocalStorage = (open: boolean, toggleDrawerOpen: () => void) => {
  const user = useContext(UserContext)
  const { key, defaultValue } = setParamsForDrawerOpenLocalStorage(user)

  const [sideBarOpenLocalStorage, updateLocalStorage] = useLocalStorage(key, defaultValue)

  // Set 'open' to true on mount if sideBarOpenLocalStorage is true
  useEffect(() => {
    if (sideBarOpenLocalStorage) {
      toggleDrawerOpen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (sideBarOpenLocalStorage) {
      updateLocalStorage(true)
    } else {
      updateLocalStorage(false)
    }
  }, [open, sideBarOpenLocalStorage, toggleDrawerOpen, updateLocalStorage])

  return { sideBarOpenLocalStorage, updateLocalStorage }
}

export const setParamsForDrawerOpenLocalStorage = (user: Maybe<User>) => {
  const key = `${user?.id}-reviewModeDrawerOpen`
  const width = window.innerWidth

  const defaultValue = width >= 1450

  return { key, defaultValue }
}
