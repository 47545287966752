import { AxiosError } from 'axios'
import DataLoader from 'dataloader'

// import { GraphQLError } from 'graphql'
import {
  CdmGetMortgageRequest,
  CdmGetMortgageResponse,
  CdmGetMortgageVersionResponse,
  CdmMortgage,
} from '../service/luther/model'
import { createBatchLoaderFn, createBatchLoaderFnWithReducer, getFirstUsedKey } from '../utils/dataloader'

const fetchMortgageBatchFn = createBatchLoaderFnWithReducer<CdmGetMortgageRequest, CdmGetMortgageResponse>(
  '/mortgage',
  {
    reduceKeys: ['mortgage_ids', 'filter_ext_ids', 'filter_property_secured_id', 'filter_case_id'],
    defaultParams: {
      mortgage_details: true,
    },
  },
)
type BatchKeyToFieldNameType = {
  [key in keyof CdmGetMortgageRequest]?: (key: string) => (mortgage: CdmMortgage) => boolean | undefined
}
const batchKeyToFieldName: BatchKeyToFieldNameType = {
  mortgage_ids: (key: string) => (mortgage: CdmMortgage) => mortgage?.mortgage_id === key,
  filter_ext_ids: (key: string) => (mortgage: CdmMortgage) =>
    !!mortgage?.external?.find((externalSource) => externalSource?.id === key),
  filter_property_secured_id: (key: string) => (mortgage: CdmMortgage) => mortgage?.property_secured_ids?.includes(key),
  filter_case_id: (key: string) => (mortgage: CdmMortgage) => mortgage?.case_ids?.includes(key),
}

const fetchMortgageAtVersionBatchFn = createBatchLoaderFn<
  { mortgage_id: string; version: number },
  CdmGetMortgageVersionResponse
>(({ version, mortgage_id }) => `/mortgage/${mortgage_id}/version/${version}`, {
  forwardParams: false,
})

export const createMortgageLoader = () =>
  new DataLoader(
    async (params: CdmGetMortgageRequest[]) => {
      const response = await fetchMortgageBatchFn(params)

      if (!response.mortgages) {
        return params.map(
          () => null,
          // new GraphQLError(`No mortgage found for query: ${JSON.stringify(param)}`, {
          //   extensions: { code: 'NOT_FOUND', payload: param, expection: response.exception },
          // })
        )
      }

      const batchKey = getFirstUsedKey(params[0], [
        'mortgage_ids',
        'filter_ext_ids',
        'filter_property_secured_id',
        'filter_case_id',
      ])

      const results = params.map((param) => {
        const keys = param[batchKey]

        // NOTE: Is this a bug somewhere? It seems like the keys are not always an array
        const key = Array.isArray(keys) ? keys[0] : keys
        const findFunction = batchKeyToFieldName[batchKey]?.(key as string)

        if (batchKey.startsWith('filter')) {
          return findFunction && (response.mortgages?.filter(findFunction) as CdmMortgage)
        }

        const mortgage = findFunction && response.mortgages?.find(findFunction)

        return (
          mortgage || null
          // new GraphQLError(`No mortgage found for query: ${JSON.stringify(param)}`, {
          //   extensions: { code: 'NOT_FOUND', payload: param, expection: response.exception },
          // })
        )
      })

      return results
    },
    {
      cacheKeyFn: (params) => {
        const cacheKey = JSON.stringify(params)
        return cacheKey
      },
    },
  )

export const createMortgageVersionLoader = () =>
  new DataLoader(
    async (params: { mortgage_id: string; version: number }[]) => {
      const responses = await fetchMortgageAtVersionBatchFn(params)

      return params.map((param) => {
        const responseOrError = responses.find(
          (response) => (response as CdmGetMortgageVersionResponse).mortgage?.mortgage_id === param.mortgage_id,
        )

        return (responseOrError as CdmGetMortgageVersionResponse)?.mortgage || (responseOrError as AxiosError<any>)
      })
    },
    {
      cacheKeyFn: JSON.stringify,
    },
  )

export const mortgageLoader = createMortgageLoader()
export const mortgageVersionLoader = createMortgageVersionLoader()
