import DataLoader from 'dataloader'

import { MortgageAndCase, MortgageAndProduct, MortgageAndProductInput, MortgageInput } from '../generated/resolvers'
import request from '../requesters/clientPortal'
import oathKeeper from '../requesters/oathKeeper'
import {
  CdmCreateMortgageResponse,
  CdmDeleteMortgageCaseResponse,
  CdmGetMortgageResponse,
} from '../service/luther/model'
import { UUID } from '../types'
import { formatMortgage } from '../utils/schemaMapping/mortgage'

const fetchMortgageBatchFn = async (params: Object[]) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const requests = params.map(async (params) => {
    const response = await requester.get<CdmGetMortgageResponse>('/mortgage', {
      params: { ...params, mortgage_details: true },
    })

    return response
  })

  const responses = await Promise.all(requests)

  return responses.map((response) => response.data)
}

export const CaseMortgageLoader = new DataLoader(
  async (params: Object[]) => {
    return await fetchMortgageBatchFn(params)
  },
  {
    cacheKeyFn: (key) => JSON.stringify(key),
  },
)

export const fetchMortgagesForPropertiesCP = async (property_id: UUID) => {
  CaseMortgageLoader.clear({ filter_property_secured_id: property_id })
  return await CaseMortgageLoader.load({
    filter_property_secured_id: property_id,
  })
}

export const fetchMortgagesForCaseCP = async (case_id: UUID) => {
  return await CaseMortgageLoader.load({ filter_case_id: case_id })
}

export const createMortgageCP = async (input: MortgageInput) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.post<CdmCreateMortgageResponse>('/mortgage', input)

  input.case_ids?.forEach((id) => CaseMortgageLoader.clear({ filter_case_id: id }))
  input.property_secured_ids?.forEach((id) => CaseMortgageLoader.clear({ filter_property_secured_id: id }))

  return response.data.mortgage ? formatMortgage(response.data.mortgage) : null
}

export const associateMortgageWithProductCP = async (input: MortgageAndProductInput) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.post<MortgageAndProduct>(`/mortgage/${input.mortgage_id}/product`, input)

  return response.data
}

export const addMortgageToCaseCP = async (mortgage_id: string, case_id: string) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.post<MortgageAndCase>(`/mortgage/${mortgage_id}/case`, { mortgage_id, case_id })
  CaseMortgageLoader.clear({ filter_case_id: case_id })
  return response.data
}

export const updateMortgageCP = async (mortgage_id: string, input: MortgageInput) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.patch<CdmCreateMortgageResponse>(`/mortgage/${mortgage_id}`, input)

  CaseMortgageLoader.clearAll()

  return response.data.mortgage ? formatMortgage(response.data.mortgage) : null
}

export const deleteMortgageCp = async (mortgageId: string, caseId: string) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.delete<CdmDeleteMortgageCaseResponse>(`/mortgage/${mortgageId}/case/${caseId}`)

  CaseMortgageLoader.clearAll()

  return response.data
}
