import { AxiosResponse } from 'axios'
import { GraphQLError } from 'graphql'
import { isEmpty, omit, pick } from 'lodash'

import configs from '@acre/config'

import {
  ChCompanyInput,
  ClientAddressInput,
  ClientInput,
  ClientOrganisationRelationshipInput,
  ClientRelationship,
  ClientType,
  ClientVerification,
  ClientVersion,
  GetClientResponse,
  MarketingPreferencesInput,
  Maybe,
  SearchResult,
} from '../generated/resolvers'
import { clientLoader, clientVersionLoader } from '../loaders/client'
import request from '../requesters/default'
import oathKeeper from '../requesters/oathKeeper'
import {
  CdmClient,
  CdmCreateClientOrganisationRelationshipResponse,
  CdmCreateClientResponse,
  CdmGetClientOrganisationRelationshipResponse,
  CdmGetClientRequest,
  CdmGetClientResponse,
  CdmUpdateClientOrganisationRelationshipResponse,
  CdmUpdateClientResponse,
} from '../service/luther/model'
import { UUID } from '../types'
import { getCountryCodeForName } from '../utils/countries'
import { getFirstUsedKey, processLoaderResults, shouldUseLoader, spreadParameters } from '../utils/dataloader'
import {
  formatClient,
  formatClientAsRequestBody,
  formatNationalities,
  removeInternalHash,
} from '../utils/schemaMapping/client'
import { CaseLoader } from './case'
import { conditionalMerge, setPersistedCompany, setPersistedDirector } from './client.helpers'

const { CLIENT_PORTAL_API_URL, CLIENT_PORTAL_OATHKEEPER_API_URL } = configs

const batchKeys = new Set<keyof CdmGetClientRequest>(['client_ids'])

export const fetchClient = async (params: CdmGetClientRequest) => {
  const client = await clientLoader.load(params)

  if (client instanceof GraphQLError) {
    throw client
  }

  return formatClient(client)
}

const isClientRequestNotEmpty = (req: CdmGetClientRequest): boolean => {
  if (req.client_ids?.length) {
    return true
  }
  if (req.filter_client_ext_ids?.length) {
    return true
  }
  if (req.filter_email_address) {
    return true
  }
  if (req.filter_organisation_id) {
    return true
  }
  if (req.filter_ext_ids?.length) {
    return true
  }
  return false
}

export const fetchClients = async (params: CdmGetClientRequest) => {
  if (!isClientRequestNotEmpty(params)) {
    return []
  }
  if (shouldUseLoader(params, batchKeys)) {
    const batchKey = getFirstUsedKey(params, [...batchKeys])

    const batchParams = params[batchKey]

    if (Array.isArray(batchParams) && batchParams.length > 1) {
      const clientsFromLoader = await clientLoader.loadMany(spreadParameters(params, batchKey))

      const clean = processLoaderResults(clientsFromLoader)

      return clean.map(formatClient)
    }

    const client = await clientLoader.load(params)

    if (client instanceof GraphQLError) {
      throw client
    }

    return [formatClient(client)]
  }

  const response = await request.get<CdmGetClientResponse>('/client', { params })

  return response.data.clients?.map(formatClient) ?? []
}

export const fetchClientsWithPagination = async (params: CdmGetClientRequest) => {
  const response = await request.get<CdmGetClientResponse>('/client', { params })

  return { ...response.data, clients: response.data.clients?.map(formatClient) ?? null } as GetClientResponse
}

export const fetchClientByVersion = async (params: { client_id: string; version: number }) => {
  const client = await clientVersionLoader.load(params)

  if (client instanceof Error) {
    // console.error(client)
    throw client
  }

  return formatClient(client)
}

export const fetchProtectedClientFields = async (id: UUID, protectedFields: string[] | undefined | null) => {
  clientLoader.clear({
    client_ids: [id],
    client_details: true,
    show_protected: protectedFields?.map((field) => `.${field}`),
  })
  return await fetchClient({
    client_ids: [id],
    client_details: true,
    show_protected: protectedFields?.map((field) => `.${field}`),
  })
}

export const addClient = async (input: ClientInput) => {
  let nationalities
  const addresses = (input.addresses && input.addresses.length > 0 && input.addresses) || []

  if (input.nationalities && input.nationalities.length > 0) {
    nationalities = {
      nationalities: formatNationalities(input.nationalities),
    }
  }

  const response = await request.post<CdmCreateClientResponse>(`/client`, {
    client: {
      details: {
        ...input,
        ...nationalities,
        addresses,
      },
    },
  })

  if (!response.data.client) {
    return null
  }

  const formattedClient = formatClient(response.data.client)

  clientLoader.prime({ client_ids: [response.data.client.client_id as string] }, formattedClient as CdmClient)

  return formattedClient
}

export const updateClient = async (id: string, input: ClientInput) => {
  delete input.disposable_income

  const formattedInput = formatClientAsRequestBody(input)
  // By default spread nothing onto payload
  let hasAddressesToFormat = {}
  let hasNationalitiesToFormat = {}

  // To stop from sending obfuscated data to backend, we filter out bank account fields
  // with an *
  if (formattedInput?.accounts && !isEmpty(formattedInput.accounts)) {
    formattedInput?.accounts?.forEach((account, index) => {
      if (account?.sort_code?.includes('**')) {
        delete formattedInput?.accounts?.[index]?.sort_code
      }
      if (account?.number?.includes('**')) {
        delete formattedInput?.accounts?.[index]?.number
      }
    })
  }

  if (formattedInput.addresses && formattedInput.addresses.length > 0) {
    hasAddressesToFormat = {
      addresses: formattedInput.addresses,
    }
  }

  if (formattedInput.nationalities && formattedInput.nationalities.length > 0) {
    hasNationalitiesToFormat = {
      nationalities: formatNationalities(formattedInput.nationalities),
    }
  }

  const hasCountryOfBirthToFormat = {
    country_of_birth: formattedInput.country_of_birth && getCountryCodeForName(formattedInput.country_of_birth),
  }

  const response = await request.patch<CdmUpdateClientResponse>(`/client/${id}`, {
    details: {
      ...formattedInput,
      ...hasAddressesToFormat,
      ...hasNationalitiesToFormat,
      ...hasCountryOfBirthToFormat,
    },
  })

  clientLoader.clearAll()

  return response.data.client ? formatClient(response.data.client) : null
}

export const updateClientAddresses = async (
  id: string,
  addresses: ClientAddressInput[],
  useClientApi?: Maybe<boolean>,
) => {
  const axiosOpts = useClientApi ? { baseURL: configs.CLIENT_PORTAL_API_URL } : undefined

  const response = await request.patch<CdmUpdateClientResponse>(
    `/client/${id}`,
    {
      details: {
        addresses: removeInternalHash(addresses),
      },
    },
    axiosOpts,
  )

  clientLoader.clearAll()

  return response.data.client ? formatClient(response.data.client) : null
}

export const requestEidvCheck = async (id: UUID) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')
  const requester = useOathKeeper === 'true' ? oathKeeper : request

  // Difficult to type this before we have *real* eIDV
  const response = await requester.post(`/client/${id}/identity/request_verification`, { client_id: id })
  // eidv check updates client as well as case status
  // need to clear All loader so refetch of lists (compliance and case lists) would work on refetch
  CaseLoader.clearAll()
  clientLoader.clearAll()

  return response.status === 200
}

export const fetchClientVerifications = async (id: UUID, show_pass?: Maybe<boolean>, useClientApi?: Maybe<boolean>) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const baseApiUrl =
    useOathKeeper === 'true' ? CLIENT_PORTAL_OATHKEEPER_API_URL : useClientApi ? CLIENT_PORTAL_API_URL : undefined
  const axiosOpts = { baseURL: baseApiUrl }

  const response = await request.get<ClientVerification>(`/client/${id}/verifications`, {
    ...axiosOpts,
    params: { show_pass },
  })

  return response.data
}

export const fetchClientOrganisationRelationships = async (
  client_id: string,
  organisation_id_or_ext?: Maybe<string>,
) => {
  const orgRelationshipUrl = `/client/${client_id}/organisation_relationship`
  const response = await request.get<CdmGetClientOrganisationRelationshipResponse>(
    organisation_id_or_ext ? `${orgRelationshipUrl}/${organisation_id_or_ext}` : orgRelationshipUrl,
  )
  return response.data
}

export const updateClientOrganisationRelationship = async (
  client_id: string,
  organisation_id_or_ext: string,
  input: MarketingPreferencesInput,
) => {
  const response = await request.patch<CdmUpdateClientOrganisationRelationshipResponse>(
    `/client/${client_id}/organisation_relationship/${organisation_id_or_ext}`,
    { marketing_preferences: [input] },
  )
  return response.data
}

export const createClientOrganisationRelationship = async (
  client_id: string,
  organisation_id_or_ext: string,
  input: ClientOrganisationRelationshipInput,
) => {
  const response = await request.post<CdmCreateClientOrganisationRelationshipResponse>(
    `/client/${client_id}/organisation_relationship/${organisation_id_or_ext}`,
    { client_organisation_relationship: input },
  )
  return response.data
}

export const upsertCompany = async (company: ChCompanyInput, user_id: string, organisation_id: string) => {
  let response: ClientVersion[] = []
  let clientsToGet: UUID[] = []
  let companiesToGet: UUID[] = []
  let searchRequests: Promise<AxiosResponse<SearchResult>>[] = []

  for (const director of company.directors || []) {
    const directorParams =
      `/search?q=${director!.firstNames} ${director!.lastName}`.replace(/ +/gi, '+') + '&show=CLIENT'
    const params = organisation_id ? directorParams + '&owning_org_ids=' + organisation_id : directorParams
    const directorResponsePromise = request.get<SearchResult>(params, {
      baseURL: configs.SEARCH_API_URL,
    })
    searchRequests.push(directorResponsePromise)
  }

  const companyParams = `/search?q=${company.name}`.replace(/ +/gi, '+') + '&show=CLIENT'
  const params = organisation_id ? companyParams + '&owning_org_ids=' + organisation_id : companyParams
  const companyResponsePromise = request.get(params, { baseURL: configs.SEARCH_API_URL })
  searchRequests.push(companyResponsePromise)

  const searchResults = await Promise.all(searchRequests)
  searchResults.forEach((searchResult) => {
    searchResult.data.results?.forEach((result) => {
      result?.first_name && clientsToGet.push(result.id!)
      result?.organisation_name && companiesToGet.push(result.id!)
    })
  })

  const clientsToGetAsSet = [...new Set(clientsToGet)]
  const companiesToGetAsSet = [...new Set(companiesToGet)]

  let clientsToSearch: ClientVersion[] | undefined
  let companiesToSearch: ClientVersion[] | undefined

  if (clientsToGetAsSet.length > 0) {
    const byClientResults = await fetchClients({
      client_ids: clientsToGetAsSet,
      show_protected: ['.date_of_birth', '.first_name', '.last_name', '.addresses', '.nationalities'],
    })

    if (byClientResults) {
      clientsToSearch = byClientResults
    }
  }

  if (companiesToGetAsSet.length) {
    const byCompanyResults = await fetchClients({
      client_ids: companiesToGet,
    })

    if (byCompanyResults) {
      companiesToSearch = byCompanyResults
    }
  }

  for (const director of company.directors || []) {
    const directorInput: ClientInput = {
      is_natural_person: true,
      client_type: [ClientType.Client],
      first_name: director?.firstNames,
      last_name: director?.lastName,
      nationalities: director?.citizenship ? director.citizenship.split(',') : undefined,
      date_of_birth: director?.dateOfBirth,
      addresses: director?.address
        ? [
            {
              is_current_correspondence: true,
              address: {
                address1: director?.address?.line1,
                address2: director?.address?.line2,
                posttown: director?.address?.city,
                postcode: director?.address?.postCode,
                country: director?.address?.country ?? 'GB',
              },
            },
          ]
        : undefined,
    }

    const persistedDirector = setPersistedDirector(clientsToSearch, director)

    if (persistedDirector) {
      const updatedDirector: ClientInput = conditionalMerge(persistedDirector.details, directorInput)

      // updating without date_of_birth due to companies house sending just month and year
      // omitting client_type on update not to override existing data
      const existingClient = await updateClient(
        persistedDirector.id,
        pick({ ...updatedDirector }, ['addresses', 'nationalities', 'first_name', 'last_name']),
      )

      if (existingClient) {
        response.push(existingClient)
      }
    } else {
      const newClient = await addClient(directorInput)

      if (newClient) {
        response.push(newClient)
      }
    }
  }

  const companyInput: ClientInput = {
    is_natural_person: false,
    client_type: [ClientType.Client],
    organisation_name: company.name,
    limited_company_registration_number: company.companyNumber,
    non_natural_organisation_type: company.nonNaturalOrganisationType,
    organisation_sic: company.sicCode,
    relationships: response.map((client) => ({
      relationship_data_access_permitted: true,
      relationship_reference: client.id,
      relationship_type: ClientRelationship.DirectorTrustee,
    })),
    addresses: company.address
      ? [
          {
            is_current_correspondence: true,
            address: {
              address1: company.address?.line1,
              address2: company.address?.line2,
              posttown: company.address?.city,
              postcode: company.address?.postCode,
              country: company?.address?.country ?? 'GB',
            },
          },
        ]
      : undefined,
  }

  const persistedCompany = setPersistedCompany(companiesToSearch, company)

  if (persistedCompany) {
    const updatedCompany: ClientInput = conditionalMerge(persistedCompany.details, companyInput)

    // omitting client_type on update not to override existing data
    const existingCompany = await updateClient(
      persistedCompany.id,
      omit({ ...updatedCompany }, ['client_type', 'id', 'total_guaranteed_annual', 'total_additional_annual']),
    )

    if (existingCompany) {
      response.unshift(existingCompany)
    }
  } else {
    const newCompany = await addClient(companyInput)

    if (newCompany) {
      response.unshift(newCompany)
    }
  }
  return response
}

export const getClientByVersion = async (client_id: string, version: number) => {
  const response = await request.get(`/client/${client_id}/version/${version}`)

  if (!response.data) {
    return null
  }

  return response.data.client && formatClient(response.data.client)
}
