import DataLoader from 'dataloader'
import { isNil } from 'lodash'

import {
  ClientInput,
  Document,
  DocumentInput,
  GetDocumentDetailsQueryVariables,
  GetDocumentSummariesQueryVariables,
} from '../generated/resolvers'
import request from '../requesters/clientPortal'
import oathKeeper from '../requesters/oathKeeper'
import {
  CdmAddCaseDocumentRequest,
  CdmAddCaseDocumentResponse,
  CdmClient,
  CdmCreateDocumentVerificationRequest,
  CdmGetClientResponse,
  CdmRenderTemplateRequest,
  CdmRenderTemplateResponse,
  CdmUpdateClientResponse,
  CdmUploadDocumentRequest,
} from '../service/luther/model'
import { UUID } from '../types'
import { formatClient } from '../utils/schemaMapping'

export const ClientLoaderUser = new DataLoader(
  async (ids: string[]) => {
    const clients = await fetchClientsBatchFunction(ids)

    return ids.map((id) => clients.find((client) => client.id === id) || null)
  },
  { maxBatchSize: 10 },
)

const fetchClientsBatchFunction = async (ids: UUID[], showProtected: string[] = []) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const clients = await Promise.all(
    ids.map(async (id) => {
      let url = `/client?client_ids=${id}&client_details=true`

      if (showProtected.length > 0) {
        url = `${url}&show_protected=${showProtected.join('&show_protected=')}`
      }

      try {
        const response = await requester.get<CdmGetClientResponse>(url, {
          validateStatus: function (status) {
            return status < 500 // Resolve only if the status code is less than 500
          },
        })
        return response?.data.clients
      } catch (error) {
        console.error(error)
      }
    }),
  )
  /**
   * Array of clients after filtering out any `null` or `undefined` values and flattening the array.
   *
   * @type {Array<Client>}
   */
  const formattedClients = clients.filter((client) => !isNil(client)).flat()

  return (formattedClients || []).map((client) => formatClient(client!))
}

// ###### LOADERS ######
// before using fetch calls, use loaders in case data already exist in cache
export const DocumentSummariesLoader = new DataLoader((params: GetDocumentSummariesQueryVariables[]) =>
  Promise.all(params.map((param) => getDocumentSummariesBatchFn(param))),
)

export const DocumentDetailsLoader = new DataLoader(
  (
    payloads: {
      documentId: string
      params?: GetDocumentDetailsQueryVariables['params']
    }[],
  ) => Promise.all(payloads.map((payload) => getDocumentBatchFn(payload.documentId, payload.params))),
)

export const fetchProtectedClientUserFields = async (id: UUID, protectedFields: string[] | undefined | null) => {
  const protectedQueryParams = (protectedFields || [])
    .map((field) => (field ? `&show_protected=.${field}` : ''))
    .join('')
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.get<CdmGetClientResponse>(
    `/client?client_ids=${id}&client_details=true${protectedQueryParams}`,
  )

  const client = response.data.clients?.find(({ client_id }): boolean => id === client_id)

  return client ? formatClient(client) : null
}

// ###### CLIENT DETAILS ######

export const fetchClientUser = async () => {
  const requester = sessionStorage.getItem('useOathKeeper') === 'true' ? oathKeeper : request

  const response = await requester.get<{ client: CdmClient }>('/me')

  return response?.data
}

export const updateClientUser = async (id: string, body: ClientInput) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')
  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.patch<CdmUpdateClientResponse>(`/client/${id}`, {
    details: body,
  })
  return response.data
}

// ###### DOCUMENTS ######

const getDocumentSummariesBatchFn = async (params?: GetDocumentSummariesQueryVariables) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.get<{ documents?: Document[] | null }>('/document', { params })

  return response?.data
}

const getDocumentBatchFn = async (documentId: string, params?: GetDocumentDetailsQueryVariables['params']) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.get<{ document: Document | null }>(`/document/${documentId}`, {
    params,
  })
  return response?.data
}

export const getClientUserDocumentSummaries = async (params?: GetDocumentSummariesQueryVariables) => {
  return await DocumentSummariesLoader.load(params!)
}

export const getClientUserDocumentDetails = async (
  documentId: string,
  params?: GetDocumentDetailsQueryVariables['params'],
) => {
  const documentDetails = await DocumentDetailsLoader.load({ documentId, params })

  if (documentDetails.document) {
    return documentDetails.document
  }
  return null
}

// UploadDocument
// https://crm.dev.acreplatforms.co.uk/api_documentation/client_portal.html#operation/UploadDocument
export const uploadClientUserDocument = async (body: CdmUploadDocumentRequest) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')
  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.post<{ document: Document | null }>('/document', body)
  return response?.data
}

// UpdateDocument
// https://crm.dev.acreplatforms.co.uk/api_documentation/client_portal.html#operation/UpdateDocument
export const updateDocumentCp = async (documentId: string, body: DocumentInput) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')
  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.patch<{ document: Document | null }>(`/document/${documentId}`, body)
  return response?.data
}

// AddCaseDocument
// https://crm.dev.acreplatforms.co.uk/api_documentation/redoc.html#operation/AddCaseDocument
export const addCaseUserDocument = async (documentId: string, body: CdmAddCaseDocumentRequest) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')
  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.post<CdmAddCaseDocumentResponse>(`/document/${documentId}/case`, body)
  return response?.data
}

// Verify Document
export const createClientUserDocumentVerification = async (
  documentId: string,
  body: CdmCreateDocumentVerificationRequest,
) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')
  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.post(`/document/${documentId}/verification`, body)
  return response?.data
}

//render template
export const renderClientUserTemplate = async (body: CdmRenderTemplateRequest) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.post<CdmRenderTemplateResponse>('/document/render_template', body)
  return response?.data.document
}

// ###### AUTH ######
export const fetchMagicLinkDetails = async (email: string) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.post('/magic_link', { email })

  return { hasError: response.status !== 200 }
}

export const setMagicLinkCookie = async (token: string, clientId?: string | null) => {
  const decodedToken = decodeURIComponent(token)

  const payload = clientId ? { token: decodedToken, client_id: clientId } : { token: decodedToken }
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.post('/login', payload, {
    validateStatus: () => true,
  })

  if (response.data.login_options) {
    return { loginOptions: response.data.login_options, hasError: false }
  }

  return { hasError: response.status !== 200 }
}

// ###### ONFIDO ######
export const fetchOnfidoAuthToken = async () => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.post('/checks-service/onfido_sdk_token', {})
  return response.data.token
}

export const initiateOnfidoCallback = async () => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.post('/checks-service/onfido_sdk_callback', {})
  return response.status === 200 || response.status === 204
}

// ###### CREDIT ######

export const initiateCreditCheckCallback = async () => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.post('/checks-service/equifax_credit_search', {})
  return response.status === 200 || response.status === 204
}
