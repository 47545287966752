import config from '@acre/config'

import request from '../requesters/default'

const { API1_URL, CLIENT_PORTAL_API_URL, CLIENT_PORTAL_OATHKEEPER_API_URL } = config

export const postCheckBrowserId = async (useClientApi?: boolean | null) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const apiBaseUrl =
    useOathKeeper === 'true'
      ? CLIENT_PORTAL_OATHKEEPER_API_URL
      : useClientApi
        ? CLIENT_PORTAL_API_URL
        : `${API1_URL}/acre`
  const response = await request.post(`/authentication/bid`, null, { baseURL: apiBaseUrl })
  return response?.data
}
