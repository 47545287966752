/**
 * Most of this file is ripped from the npm `flag` package.
 *
 * They made significant breaking changes in their version that supports React 18+ and
 * we don't need any of the new features, so it's easier to just copy the old version
 * and make it support React 18+.
 **/

import React, { createContext } from 'react'
import { get } from 'lodash'

type Split<KP extends string> = KP extends `${infer A}.${infer B}` ? [A, ...Split<B>] : [KP]

type FlagScalar = string | number | boolean

type Join<KP extends any[]> = KP extends [infer A]
  ? A extends string
    ? A
    : never
  : KP extends [infer A, ...infer B]
    ? A extends string
      ? `${A}.${Join<B>}`
      : never
    : never

type KeyPath<T> = {
  [Key in keyof T & string]: T[Key] extends object ? [Key, ...KeyPath<T[Key]>] : [Key]
}[keyof T & string]

type KeyPathString<T> = Join<KeyPath<T>>

type GetValueFromKeyPath<T, KP extends KeyPath<T>> = KP extends [infer K, ...infer Rest]
  ? /**
     * `GetValueFromKeyPath<T[K], Rest>` produces errors because we haven't proven that `K` is a key of `T`
     * or that `Rest` is a `KeyPaths<T[K]>`. As we just derived the key path from `T`, I'm pretty sure
     * we can safely assume that these constraints are met.
     */
    // @ts-expect-error
    GetValueFromKeyPath<T[K], Rest>
  : T extends FlagScalar
    ? T
    : never

type GetValueFromKeyPathString<T, KP extends Join<KeyPath<T>>> = Split<KP> extends KeyPath<T>
  ? GetValueFromKeyPath<T, Split<KP>>
  : never

export type FlagInput = { [name: string]: boolean | FlagInput }

const createFlags = <F extends FlagInput>() => {
  type KeyPathStringFlagProps<K extends KeyPathString<F>> = {
    keyPath: K
    defaultValue: GetValueFromKeyPathString<F, K>
    children: React.ReactNode
  }

  type KeyPathFlagProps<K extends KeyPath<F>> = {
    keyPath: K
    defaultValue: GetValueFromKeyPath<F, K>
    children: React.ReactNode
  }

  const FlagProvider = createContext<F>({} as F)

  function useFlag<K extends KeyPathString<F>>(
    keyPath: K,
    defaultValue: GetValueFromKeyPathString<F, K>,
  ): GetValueFromKeyPathString<F, K>
  function useFlag<KP extends KeyPath<F>>(
    keyPath: KP,
    defaultValue: GetValueFromKeyPath<F, KP>,
  ): GetValueFromKeyPath<F, KP>
  function useFlag(keyPath: any, defaultValue?: any) {
    const flags = React.useContext(FlagProvider)

    const value = get(flags, keyPath)

    return value === undefined ? defaultValue : value
  }

  function Flag<K extends KeyPathString<F>>(props: KeyPathStringFlagProps<K>): JSX.Element | null
  function Flag<KP extends KeyPath<F>>(props: KeyPathFlagProps<KP>): JSX.Element | null
  function Flag({ keyPath, defaultValue, children }: any) {
    const flag = useFlag(keyPath, defaultValue)

    return flag ? <>{children}</> : null
  }

  return {
    FlagProvider: FlagProvider.Provider,
    useFlag,
    useFlags: () => React.useContext(FlagProvider),
    Flag,
  }
}

// export type InferType<T> = { [P in keyof T]: T[P] extends boolean ? boolean : InferType<T[P]> }

export type InferType<T> = {
  [K in keyof T]: T[K] extends object ? InferType<T[K]> : boolean
}

export { createFlags }
