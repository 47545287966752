import { Template } from '../../generated/resolvers'
import { CdmTemplate } from '../../service/luther'

export const formatTemplates = (templates: CdmTemplate[]) => {
  return templates.map(
    (template) =>
      ({
        id: template.template_id,
        ...template,
      }) as Template,
  )
}
