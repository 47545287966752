import { FC, forwardRef, ReactNode } from 'react'

interface Props {
  disabled?: boolean
}

const shareSessionStorage = <P extends Props>(WrappedComponent: FC<P> | { props: P }) => {
  const Wrapped = WrappedComponent as FC<P>
  // any used because of https://github.com/DefinitelyTyped/DefinitelyTyped/issues/35834
  const Component = forwardRef<any, P & { children?: ReactNode }>(function Component(props, ref) {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const case_id = urlParams.get('case_id')

    if (case_id) {
      sessionStorage.setItem('case_id', case_id)
    }

    return <Wrapped {...props} ref={ref} />
  })

  return Component
}

export default shareSessionStorage
