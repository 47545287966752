import axios, { AxiosError, AxiosResponse } from 'axios'
import { redirect } from 'react-router-dom'

import envConfig from '@acre/config'

const { SEARCH_API_URL, REFRESH_URL } = envConfig

import createAxiosInstance from '../utils/createAxiosInstance'

const axiosInstance = createAxiosInstance(
  {
    baseURL: SEARCH_API_URL,
  },
  {
    useApiKey: true,
  },
)

// Intercept axios errors and handle expired auth token inside the requester
axiosInstance.interceptors.response.use(undefined, async (error: AxiosError) => {
  const initialLocation = window.location.pathname
  const isLoginRoute = initialLocation === '/login'
  const isRefreshRoute = initialLocation === '/refresh'

  // If auth token has expired, attempt to refresh the token
  if (error?.response?.status === 401 && !isLoginRoute && !isRefreshRoute) {
    const response: AxiosResponse = await axios({
      url: REFRESH_URL,
      method: 'POST',
      withCredentials: true,
      validateStatus: () => true,
    }).catch((refreshError) => {
      // If refresh fails, redirect the user to login and throw error
      redirect('/login')
      return Promise.reject(refreshError)
    })

    // If token refresh is successful, retry the original request
    if (response.status === 200) {
      console.log('Refreshed auth token')
      return await axios.request(error.config)
    }
  }
  // Throw other error types (e.g. 500) normally
  return Promise.reject(error)
})

export default axiosInstance
