import DataLoader from 'dataloader'

import configs from '@acre/config'

import {
  BulkCreateRemoLeadsInput,
  CaseAssigneesInput,
  CaseFlagReviewInput,
  CaseInput,
  CaseUpdateStatusResponse,
  CaseVerification,
  CaseVersion,
  CaseVersionsResponse,
  Client,
  GetCaseRequestInput,
  GetMiCaseListFilterInput,
  GetMiCaseListResponse,
  GetReferredCaseListFilterInput,
  Maybe,
  ReferralCaseInput,
} from '../generated/resolvers'
import { mortgageLoader } from '../loaders/mortgage'
import request from '../requesters/default'
import {
  CdmCase,
  CdmCreateCaseFlagReviewResponse,
  CdmCreateCaseResponse,
  CdmGetCaseResponse,
  CdmUpdateCaseResponse,
  CdmUpdateCaseStatusResponse,
} from '../service/luther/model'
import { UUID } from '../types'
import { formatCase } from '../utils/schemaMapping/case'

const { API1_URL, CLIENT_PORTAL_API_URL, CLIENT_PORTAL_OATHKEEPER_API_URL } = configs

const fetchCasesBatchFn = async (ids: UUID[], useClientApi?: boolean) => {
  const params = { case_ids: ids, case_details: true }
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const baseApiUrl =
    useOathKeeper === 'true' ? CLIENT_PORTAL_OATHKEEPER_API_URL : useClientApi ? CLIENT_PORTAL_API_URL : undefined
  const axiosOpts = { baseURL: baseApiUrl, params }
  const response = await request.get<{ cases: Array<CdmCase> }>('/case', axiosOpts)

  const cases = response.data.cases || []

  return cases.map(formatCase)
}

export const CaseLoader = new DataLoader(async (ids: string[], useClientApi?: boolean) => {
  const cases = await fetchCasesBatchFn(ids, useClientApi)

  return ids.map((id) => cases.find((caseData) => caseData.id === id)) as CaseVersion[]
})

export const fetchCase = async (id: UUID): Promise<CaseVersion> => {
  return await CaseLoader.load(id)
}

export const fetchAllCases = async (params?: Maybe<GetCaseRequestInput>) => {
  const reqParams = {
    filter_order_reverse: true,
    page_size: 20,
    case_details: false,
    ...params,
  }

  const response = await request.get<CdmGetCaseResponse>('/case', { params: reqParams })

  if (!response.data.cases) {
    return null
  }

  const caseIds = response.data.cases.reduce((acc, caseData) => {
    if (caseData.case_id) {
      acc.push(caseData.case_id)
    }

    return acc
  }, [] as string[])

  const formattedResponse = {
    cases: await CaseLoader.loadMany(caseIds),
    next_page: response.data.next_page,
  }

  return formattedResponse
}

export const fetchCasesForClient = async (clientId: string) => {
  const response = await request.get<CdmGetCaseResponse>(`/case?case_details=false&filter_client_ids=${clientId}`)

  if (!response.data.cases) {
    return null
  }

  const caseIds = response.data.cases.reduce((acc, caseData) => {
    if (caseData.case_id) {
      acc.push(caseData.case_id)
    }

    return acc
  }, [] as string[])

  return await CaseLoader.loadMany(caseIds)
}

export const addCase = async (details: CaseInput) => {
  const response = await request.post<CdmCreateCaseResponse>(`/case`, {
    case: { details },
  })

  // Add new case to the cache
  if (response.data.case?.case_id) {
    CaseLoader.prime(response.data.case.case_id, formatCase(response.data.case))
  }

  return response.data.case ? formatCase(response.data.case) : null
}
export const createReferralCase = async (input: ReferralCaseInput) => {
  const response = await request.post<CdmCreateCaseResponse>(`/case/referral`, input)

  if (!response.data.case) {
    return null
  }

  const formattedCase = formatCase(response.data.case)

  // Add new case to the cache
  CaseLoader.prime(formattedCase.id, formattedCase)

  return formattedCase
}

export const updateCase = async (input: CaseInput, id: string) => {
  const response = await request.patch<CdmUpdateCaseResponse>(`/case/${id}`, {
    details: input,
  })

  CaseLoader.clear(id)

  return response.data.case ? formatCase(response.data.case) : null
}

export const updateCaseAssignees = async (input: CaseAssigneesInput) => {
  const response = await request.post('/case/assign', input)

  return response.data
}

export const bulkCreateRemoLeads = async (input: BulkCreateRemoLeadsInput) => {
  const response = await request.post('/case/leads', input)

  return response.data
}

export const fetchVerifications = async (
  case_id: string,
  show_pass?: Maybe<boolean>,
  show_documents?: Maybe<boolean>,
) => {
  const response = await request.get<CaseVerification>(
    `/case/${case_id}/verifications?case_verifications=true&document_requirements=${show_documents ?? false}`,
    {
      params: { show_pass: show_pass ?? false },
    },
  )
  return response.data
}

export const updateCaseStatus = async (case_id: UUID, input: any) => {
  const response = await request.post<CdmUpdateCaseStatusResponse>(`/case/${case_id}/case_transition`, {
    ...input,
    case_id,
  })

  if (!response.data.case) {
    return null
  }

  // Update dataloader cache with updated case
  CaseLoader.clear(case_id)
  CaseLoader.prime(case_id, formatCase(response.data.case))

  // Clear cached mortgage data on case
  // as BE updates some of it on case status update
  // so we'd like to re-fetch it
  mortgageLoader.clear({ filter_case_id: case_id })

  return { ...response.data, case: formatCase(response.data.case) } as CaseUpdateStatusResponse
}

// TODO: FRON-1417 change any types
// we currently have no response from create case flag review endpoint
export const createCaseFlagReview = async (input: CaseFlagReviewInput) => {
  const response = await request.post<CdmCreateCaseFlagReviewResponse>(
    `/case/${input.case_id}/flag/${input.flag_id}/review`,
    {
      reviewed_case_version: input.reviewed_case_version,
      review_passed: input.review_passed,
      skip_future_reviews: input.skip_future_reviews,
      ...(input.reviewer_notes ? { reviewer_notes: input.reviewer_notes } : {}),
    },
  )

  CaseLoader.clear(input.case_id)

  return response.data
}

export const getMiCaseList = async (input: GetMiCaseListFilterInput) => {
  const response = await request.get<GetMiCaseListResponse>('/dashboard', {
    baseURL: configs.SEARCH_API_URL,
    params: input,
  })
  return response.data
}

export const getReferredCaseList = async (input: GetReferredCaseListFilterInput) => {
  const response = await request.get<GetMiCaseListResponse>('/mi/firm/case/referrals', {
    baseURL: `${API1_URL}/acre`,
    params: input,
  })
  const leadCases = response.data?.cases?.map((item) => {
    const leadCase = {
      ...item,
      introducerClients: item.clients as Client[],
    }

    return leadCase
  })

  return { ...response.data, cases: leadCases }
}

export const getCaseVersions = async (case_id: string) => {
  const response = await request.get<CaseVersionsResponse>(`/case/${case_id}/version`, {
    baseURL: `${API1_URL}/acre`,
  })

  return response.data
}

export const getCaseByVersion = async (case_id: string, version: number) => {
  const response = await request.get(`/case/${case_id}/version/${version}`, {
    baseURL: `${API1_URL}/acre`,
  })

  return response?.data?.case && formatCase(response.data.case)
}
