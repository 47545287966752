import { isEmpty, isNil } from 'lodash'

import { Maybe } from '../../../generated/resolvers'
import { getAmount } from './getAmount'
import { ProviderFee, ProviderFeeType, SourcingMortgageState } from './mapToLutherMortgage.fixtures'

export const deleteRedundantValuationFees = (
  fees?: SourcingMortgageState['providerFee'],
  valuationAmount?: Maybe<number>,
  loanAmount?: Maybe<number>,
): ProviderFee[] => {
  if (isNil(valuationAmount)) {
    return fees?.filter((fee) => fee['providerFee.type'] !== ProviderFeeType.ValuationFee) || []
  }

  const { restFees = [], valuationFees = [] } =
    fees?.reduce(
      (acc, fee) => {
        if (fee['providerFee.type'] === ProviderFeeType.ValuationFee) {
          return {
            valuationFees: [...acc.valuationFees, fee],
            restFees: [...acc.restFees],
          }
        }

        return {
          valuationFees: [...acc.valuationFees],
          restFees: [...acc.restFees, fee],
        }
      },
      {
        valuationFees: [] as ProviderFee[],
        restFees: [] as ProviderFee[],
      },
    ) || {}

  // relevant valuation fees are the ones, where the property valuation
  // falls between the min and max for that fee to be applied
  const valuationFee = valuationFees.reduce((lowestValuationFee, fee) => {
    const minAmount = fee['providerFee.minAmount']
    const maxAmount = fee['providerFee.maxAmount']
    const feeAmount = fee['providerFee.amount']
    const feePercentage = fee['providerFee.percentage']
    const convertedFeeAmount = getAmount({ amount: feeAmount, percentage: feePercentage, loanAmount })

    if (!minAmount || !maxAmount || !convertedFeeAmount) {
      return lowestValuationFee
    }

    if (valuationAmount < maxAmount && valuationAmount > minAmount) {
      if (!lowestValuationFee['providerFee.amount']) {
        return fee
      }

      return lowestValuationFee['providerFee.amount'] > convertedFeeAmount ? fee : lowestValuationFee
    }

    return lowestValuationFee
  }, {} as ProviderFee)

  return !isEmpty(valuationFee) ? [valuationFee, ...restFees] : restFees
}
