import { isSameMonth, parseISO } from 'date-fns'

import {
  ChCompanyInput,
  ChOfficerInput,
  Client,
  ClientInput,
  ClientVersion,
  InputMaybe,
  Maybe,
} from '../generated/resolvers'

export const setPersistedDirector = (
  clientsToSearch: ClientVersion[] | undefined,
  director: InputMaybe<ChOfficerInput>
) => {
  return clientsToSearch?.find((client) => {
    //Exclude middle name from first name to correct the matching between Acre clients and company house clients
    let indexOfFirstSpace = director?.firstNames?.indexOf(' ')
    let extractDirectorFirstName =
      indexOfFirstSpace !== -1 ? director?.firstNames?.substring(0, indexOfFirstSpace) : director?.firstNames
    let lowerCaseExtractDirectorFirstName = extractDirectorFirstName?.toLowerCase()
    let lowerCaseFullDirectorFirstName = director?.firstNames?.toLowerCase()
    let lowerCaseFullClientFirstName = client.details.first_name?.toLowerCase()

    //Exclude middle name from last name to correct the matching between Acre clients and company house clients
    let indexOfLastSpace = director?.lastName?.lastIndexOf(' ')
    let extractDirectorLastName =
      indexOfLastSpace !== -1 ? director?.lastName?.substring(-1, indexOfLastSpace) : director?.firstNames
    let lowerCaseExtractDirectorLastName = extractDirectorLastName?.toLowerCase()
    let lowerCaseFullDirectorLastName = director?.lastName?.toLowerCase()
    let lowerCaseFullClientLastName = client.details.last_name?.toLowerCase()

    return (
      //Get a match either when the full first/last name matches, or the extracted first/last matches
      (lowerCaseFullClientFirstName === lowerCaseExtractDirectorFirstName ||
        lowerCaseFullClientFirstName === lowerCaseFullDirectorFirstName) &&
      (lowerCaseFullClientLastName === lowerCaseExtractDirectorLastName ||
        lowerCaseFullClientLastName === lowerCaseFullDirectorLastName) &&
      client.details.date_of_birth &&
      director?.dateOfBirth &&
      // checking with isSameMonth due to companies house sending just month and year
      isSameMonth(parseISO(client.details.date_of_birth), parseISO(director?.dateOfBirth))
    )
  })
}

const normaliseCompanyName = (name: Maybe<string>) => {
  return name?.toLowerCase().replace(/limited/gi, 'ltd')
}

export const setPersistedCompany = (companiesToSearch: ClientVersion[] | undefined, company: ChCompanyInput) => {
  return companiesToSearch?.find(
    (client) => normaliseCompanyName(client.details.organisation_name) === normaliseCompanyName(company.name)
  )
}

// This function is used to update current client data from company house.
// We want to overwrite the data from company house only if a field is empty,
// and append additional array object in the company house to the current client data.
export const conditionalMerge = (target: any, source: ClientInput) => {
  const merged = { ...target }

  for (const key in source) {
    if (source.hasOwnProperty(key)) {
      const sourceValue = source[key as keyof ClientInput]
      const targetValue = target[key as keyof Client]

      // Check if the sourceValue is an array. E.g. the addresses & relationships fields are an array of objects.
      if (Array.isArray(sourceValue)) {
        if (!Array.isArray(targetValue)) {
          merged[key] = sourceValue
        } else {
          merged[key] = targetValue.map((item, index) => {
            if (index >= sourceValue.length || !item) {
              return item // Retain target array item if index is out of source bounds or falsy
            }
            return conditionalMerge(item, sourceValue[index] as ClientInput)
          })

          //Push additional company house array objects to current client data
          for (let index = targetValue.length; index < sourceValue.length; index++) {
            merged[key].push(sourceValue[index])
          }
        }
      } else if (typeof sourceValue === 'object' && sourceValue !== null) {
        if (!targetValue || typeof targetValue !== 'object') {
          merged[key] = sourceValue
        } else {
          merged[key] = conditionalMerge(targetValue, sourceValue as ClientInput)
        }
      } else {
        if (targetValue === null || targetValue === undefined) {
          merged[key] = sourceValue
        }
      }
    }
  }
  return merged
}
