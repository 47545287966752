import { AxiosError, AxiosResponse } from 'axios'
import qs from 'qs'

import configs from '@acre/config'

import {
  CaseLedgers,
  GetLedgersResponse,
  Ledger,
  LedgerFilters,
  LedgerPayment,
  MutationCreateLedgerPaymentArgs,
  MutationCreateRecurringPaymentArgs,
  MutationEditLedgerPaymentArgs,
  MutationGoCardlessAuthCallbackArgs,
  MutationInitiateGoCardlessPaymentArgs,
  MutationUpdateLedgerPaymentArgs,
  MutationUpdatePaymentArgs,
  MutationUpdateRecurringLedgerPaymentArgs,
  MutationWriteOffLedgerArgs,
  OneOffLedger,
  QueryGetLedgerFiltersArgs,
  QueryGetLedgersArgs,
  QueryGetLedgersByCaseIdArgs,
  QueryHasGoCardlessMandateArgs,
  RecurringLedger,
} from '../generated/resolvers'
import request from '../requesters/default'
import { GraphqlException } from '../resolvers/util'

const { API1_URL } = configs

export const getLedgersByCaseId = async (input: QueryGetLedgersByCaseIdArgs) => {
  const response = await request.get<CaseLedgers>(`/accounting/ledger/case/${input.caseId}`, {
    baseURL: `${API1_URL}/acre`,
  })
  return response.data
}

export const getLedgerFilters = async (input: Partial<QueryGetLedgerFiltersArgs>) => {
  const response: AxiosResponse<LedgerFilters> = await request.get<LedgerFilters>(`/accounting/ledger/filter`, {
    params: { org_id: input.org_ids },
    baseURL: `${API1_URL}/acre`,
  })
  return response.data
}

export const getLedgers = async (input: QueryGetLedgersArgs) => {
  // Org ids is also a type of filter but BE accepts it as a query param instead of body
  const { orgIds = [], regulated_by, ...rest } = input.input.filter || {}

  const queryObj = {
    // If no org ids, then we don't wanna query by orgIds
    org_id: orgIds?.length ? orgIds : undefined,
    regulated_by,
  }

  const query = qs.stringify(queryObj, { arrayFormat: 'repeat' })

  const response = await request.post<GetLedgersResponse>(
    `/accounting/ledger${query ? '?' : ''}${query}`,
    { ...input.input, filter: rest },
    {
      baseURL: `${API1_URL}/acre`,
    },
  )
  return response.data
}

// IDs in accounting are returned as numbers, not strings
export const deleteLedger = async (id: number) => {
  const response = await request.delete(`/accounting/ledger/${id}`, {
    baseURL: `${API1_URL}/acre`,
    validateStatus: function (status) {
      return status >= 200 && status < 500 // default
    },
  })

  // Accounting microservice doesn't throw error on a status < 500,
  // hence the need to throw the error from the data here
  if (response?.data?.error) {
    throw new GraphqlException(response.data.error)
  }

  return id
}

export const writeOffLedger = async (input: MutationWriteOffLedgerArgs) => {
  const response: AxiosResponse<Ledger> = await request.patch<Ledger>(
    `/accounting/ledger/${input.ledgerId}/write_off`,
    input.input,
    {
      baseURL: `${API1_URL}/acre`,
    },
  )
  return response.data
}

export const editLedgerPayment = async (input: MutationEditLedgerPaymentArgs) => {
  const response: AxiosResponse<Ledger> = await request.put<Ledger>(
    `/accounting/ledger/${input.ledgerId}/payment`,
    input.input,
    {
      baseURL: `${API1_URL}/acre`,
    },
  )
  return response.data
}

export const updatePayment = async (input: MutationUpdatePaymentArgs) => {
  const response = await request.patch<LedgerPayment>(
    `/accounting/ledger/${input.ledgerId}/payment/${input.paymentId}`,
    input.input,
    {
      baseURL: `${API1_URL}/acre`,
    },
  )
  return response.data
}

export const updateLedger = async (input: MutationUpdateLedgerPaymentArgs) => {
  const response = await request.patch<Ledger>(`/accounting/ledger/${input.ledgerId}`, input.input, {
    baseURL: `${API1_URL}/acre`,
  })
  return response.data
}

export const createRecurringPayment = async (input: MutationCreateRecurringPaymentArgs) => {
  const response = await request.post<RecurringLedger>(`/accounting/ledger/recurring`, input.input, {
    baseURL: `${API1_URL}/acre`,
  })
  return response.data
}

export const updateRecurringLedgerPayment = async (input: MutationUpdateRecurringLedgerPaymentArgs) => {
  const response = await request.patch<RecurringLedger>(`/accounting/ledger/recurring/${input.ledgerId}`, input.input, {
    baseURL: `${API1_URL}/acre`,
  })
  return response.data
}

export const createLedgerPayment = async (input: MutationCreateLedgerPaymentArgs) => {
  const response = await request.post<OneOffLedger>(`/accounting/ledger/create`, input.input, {
    baseURL: `${API1_URL}/acre`,
  })
  return response.data
}

export const initiateGoCardlessPayment = async (input: MutationInitiateGoCardlessPaymentArgs) => {
  try {
    const response = await request.post(
      `/accounting/go-cardless/initiate-payment?client_id=${input.input?.clientId}&ledger=${input.input?.ledgerID}`,
      undefined,
      {
        baseURL: `${API1_URL}/acre`,
      },
    )
    return response.data
  } catch (e) {
    const ex = e as AxiosError<{ error: string }>
    if (ex.response?.data?.error) {
      throw new Error(ex.response.data?.error)
    }
    throw new Error('Could not initiate payment')
  }
}

export const getGoCardlessAuthURL = async () => {
  try {
    const response = await request.get(`/accounting/go-cardless/generate-auth-url`, {
      baseURL: `${API1_URL}/acre`,
    })
    return response.data
  } catch (e) {
    const ex = e as AxiosError<{ error: string }>
    if (ex.response?.data?.error) {
      throw new Error(ex.response.data?.error)
    }
    throw new Error('Could not generate authorisation URL')
  }
}

export const getGoCardlessAuthCallback = async (input: MutationGoCardlessAuthCallbackArgs) => {
  if (!input.input?.code || !input.input?.state) {
    throw new Error('Invalid parameters')
  }
  try {
    await request.get(`/accounting/go-cardless/auth-callback?code=${input.input?.code}&state=${input.input?.state}`, {
      baseURL: `${API1_URL}/acre`,
    })
    return 'OK'
  } catch (e) {
    const ex = e as AxiosError<{ error: string }>
    if (ex.response?.data?.error) {
      throw new Error(ex.response.data?.error)
    }
    throw new Error('Could not receive callback')
  }
}

export const getGoCardlessAccountStatus = async () => {
  try {
    const response = await request.get(`/accounting/go-cardless/account-status`, {
      baseURL: `${API1_URL}/acre`,
    })
    return response.data
  } catch (e) {
    const ex = e as AxiosError<{ error: string }>
    if (ex.response?.data?.error) {
      throw new Error(ex.response.data?.error)
    }
    throw new Error('Could not get account status')
  }
}

export const hasGoCardlessMandate = async (input: QueryHasGoCardlessMandateArgs) => {
  if (!input.client_id) {
    throw new Error('Invalid parameters')
  }
  try {
    const response = await request.get(`/accounting/go-cardless/has-mandate?client_id=${input.client_id}`, {
      baseURL: `${API1_URL}/acre`,
    })
    return response.data
  } catch (e) {
    const ex = e as AxiosError<{ error: string }>
    if (ex.response?.data?.error) {
      throw new Error(ex.response.data?.error)
    }
    throw new Error('Could not get mandate status')
  }
}
