import { GraphQLError } from 'graphql'

import { CaseLoader, fetchClient, fetchOrganisation, UserLoader } from '../api'
import { addMessage, addNote, fetchAllCaseNotes, fetchCaseNote, fetchFilteredNotes, updateNote } from '../api/notes'
import { QueryGetFilteredNotesArgs, QueryGetNoteArgs, QueryGetNotesArgs, Resolvers } from '../generated/resolvers'

const NoteResolver: Resolvers = {
  Note: {
    client: async (parent) => {
      const { client_id } = parent

      if (!client_id) {
        return null
      }

      return (await fetchClient({ client_ids: [client_id] })) ?? null
    },
    case: async (parent) => {
      const { case_id } = parent
      return case_id ? await CaseLoader.load(case_id) : null
    },
    template: async ({ template_id }) => {
      if (template_id) {
        const currentUser = await UserLoader.load('me')
        UserLoader.clear('me')

        if (currentUser instanceof GraphQLError) {
          throw currentUser
        }

        if (currentUser) {
          const userOrgs = await fetchOrganisation(currentUser.organisation_id)

          if (userOrgs instanceof GraphQLError) {
            throw userOrgs
          }

          // mapover and filter by template_id
          const template = userOrgs?.note_templates?.find((template) => template?.id === template_id)
          return template || null
        }
        return null
      }
      return null
    },
  },
  Query: {
    getFilteredNotes: async (_, input: QueryGetFilteredNotesArgs) => {
      return await fetchFilteredNotes(input)
    },
    getNotes: async (_, input: QueryGetNotesArgs) => {
      const noteResponse = await fetchAllCaseNotes(input)
      return {
        notes: noteResponse,
      }
    },
    getNote: async (_, input: QueryGetNoteArgs) => {
      return await fetchCaseNote(input)
    },
  },
  Mutation: {
    addNote: (_, input) => addNote(input),
    addMessage: (_, input) => addMessage(input),
    updateNote: (_, input) => updateNote(input),
    updateMessage: (_, input) => updateNote(input),
  },
}

export default NoteResolver
