import { ApolloClient, ApolloLink, NormalizedCacheObject } from '@apollo/client'
import { ErrorResponse, onError } from '@apollo/client/link/error'
import { SchemaLink } from '@apollo/client/link/schema'

import { lutherErrorLink } from '@acre/utils'
import { schema } from '@acre/graphql'

import authRedirectErrorLink from './authRedirectErrorLink'
import cache from './cache'

const initApollo = () => {
  const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    link: ApolloLink.from([
      onError(lutherErrorLink),
      onError((error: ErrorResponse) => authRedirectErrorLink(error, client)),
      new SchemaLink({
        schema,
      }),
    ]),
    cache,
  })

  return client
}

export default initApollo
