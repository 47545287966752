import { createReportProblem } from '../api/zendesk'
import { Resolvers } from '../generated/resolvers'

const ZendeskResolver: Resolvers = {
  Mutation: {
    createReportProblem: async (_, params) => await createReportProblem(params.input),
  },
}

export default ZendeskResolver
