import DataLoader from 'dataloader'
import { GraphQLError } from 'graphql'

import config from '@acre/config'

import { Maybe, Organisation, OrganisationInput, OrganisationResponse } from '../generated/resolvers'
import request from '../requesters/default'
import {
  CdmCreateOrganisationResponse,
  CdmGetOrganisationResponse,
  CdmUpdateOrganisationResponse,
} from '../service/luther'
import { UUID } from '../types'
import { formatOrganisationPayload, formatOrganisationResponse } from '../utils/schemaMapping/organisations'

const { CLIENT_PORTAL_API_URL, CLIENT_PORTAL_OATHKEEPER_API_URL } = config

type OrganisationLoaderInput = { id: UUID; useClientApi?: boolean }

type OrganisationLoaderIdsParam = (string | OrganisationLoaderInput)[]

export enum OrgSourceType {
  Insurance = 'INSURANCE',
  Club = 'CLUB',
  Lender = 'LENDER',
}

const fetchOrganisationBatchFunction = async (ids: OrganisationLoaderIdsParam) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const actualIds = ids.map((id) => (typeof id === 'string' ? id : id.id))
  const axiosOpts = ids.some((id) => typeof id !== 'string' && id.useClientApi)
    ? useOathKeeper === 'true'
      ? { baseURL: CLIENT_PORTAL_OATHKEEPER_API_URL }
      : { baseURL: CLIENT_PORTAL_API_URL }
    : { params: { page_size: 1000 } }

  const response = await request.get<CdmGetOrganisationResponse>(
    `/organisation?organisation_ids=${actualIds.join('&organisation_ids=')}&organisation_details=true`,
    axiosOpts
  )

  return actualIds.map((id) => {
    const organisation = response.data.organisations?.find((org) => org.organisation_id === id)
    return organisation
      ? formatOrganisationResponse(organisation)
      : new GraphQLError(`No result for ${id}`, {
          extensions: {
            status: 404,
          },
        })
  })
}

export const OrganisationLoader = new DataLoader(
  (ids: OrganisationLoaderIdsParam) => fetchOrganisationBatchFunction(ids),
  { cacheKeyFn: (key) => (typeof key === 'string' ? key : key.id), maxBatchSize: 30 }
)

export const fetchOrganisation = async (id: UUID, useClientApi?: Maybe<boolean>) => {
  return await OrganisationLoader.load({ id, useClientApi: useClientApi || false })
}

export const fetchOrganisationByExtId = async (id: string) => {
  const response = await request.get<CdmGetOrganisationResponse>('/organisation', {
    params: { organisation_ext_identifiers: id },
  })

  const organisation = response.data.organisations?.[0]

  if (organisation) {
    return formatOrganisationResponse(organisation)
  }

  return null
}

export const fetchOrganisationsByExtId = async (id: string) => {
  const response = await request.get<CdmGetOrganisationResponse>('/organisation', {
    params: { organisation_ext_identifiers: id },
  })

  const organisations = response.data.organisations
  let responseOrganisation: Organisation[] = []
  if (organisations) {
    organisations.map((org) => {
      responseOrganisation.push(formatOrganisationResponse(org))
    })

    return responseOrganisation
  }

  return null
}

export const fetchOrganisationsAdministeredBy = async (administeredBy: string): Promise<Organisation[]> => {
  const response = await request.get<CdmGetOrganisationResponse>('/organisation', {
    params: {
      filter_administered_by_org_id: administeredBy,
      page_size: 1000,
      organisation_details: true,
    },
  })

  if (response.data.organisations && response.data.organisations.length > 0) {
    return response.data.organisations.map(formatOrganisationResponse)
  }

  return []
}

export const fetchOrganisationsBySourceType = async (sourceType: OrgSourceType): Promise<Organisation[]> => {
  const response = await request.get('/local-sourcing/organisations', {
    params: {
      type: sourceType,
    },
  })

  if (response?.data?.organisations?.length > 0) {
    return response.data.organisations.map(formatOrganisationResponse)
  }

  return []
}

export const fetchOrganisationsRepresentativeOf = async (representativeOf: string) => {
  //TODO: Bookmark and page size
  const rawResponse = await request.get<CdmGetOrganisationResponse>('/organisation', {
    params: {
      filter_representative_of_org_id: representativeOf,
      page_size: 1000,
      organisation_details: true,
    },
  })

  const response: OrganisationResponse = {
    organisations: [],
    next_page: rawResponse?.data.next_page,
  }

  if (rawResponse.data.organisations && rawResponse.data.organisations.length > 0) {
    response.organisations = rawResponse.data.organisations.map(formatOrganisationResponse)
  }

  return response
}

export const updateOrganisation = async (id: UUID, input: OrganisationInput) => {
  const response = await request.patch<CdmUpdateOrganisationResponse>(
    `/organisation/${id}`,
    formatOrganisationPayload(input)
  )

  OrganisationLoader.clear({ id, useClientApi: false })

  return formatOrganisationResponse(response.data.organisation!)
}

export const createOrganisation = async (input: OrganisationInput) => {
  const response = await request.post<CdmCreateOrganisationResponse>(`/organisation`, formatOrganisationPayload(input))

  return formatOrganisationResponse(response.data.organisation!)
}
