import { fetchSourcedMortgageProduct, updateMortgageProductHelper } from '../api'
import { associateMortgageWithProductCP, createMortgageProductCP, deleteMortgageProductCP } from '../api_client_portal'
import { Resolvers } from '../generated/resolvers'
import { formatSourcedMortgageProductResponseDetails } from '../utils/schemaMapping/mortgageProduct'

const ProductResolver: Resolvers = {
  Query: {
    product: async (_parent, { productCode, reasonForMortgage, mortgageType }) => {
      const { product_details } = await fetchSourcedMortgageProduct(productCode, reasonForMortgage, mortgageType)
      return product_details ? formatSourcedMortgageProductResponseDetails(product_details) : null
    },
  },
  Mutation: {
    updateMortgageProductCp: async (_parent, { mortgageId, mortgageProductId, mortgageProductInput }) => {
      const newMortgageProduct = await createMortgageProductCP(mortgageProductInput)
      await deleteMortgageProductCP(mortgageId, mortgageProductId)
      // Link mortgage and new product
      await associateMortgageWithProductCP({ product_id: newMortgageProduct.product_id, mortgage_id: mortgageId })
      return newMortgageProduct
    },
    updateMortgageProduct: async (_parent, { mortgageId, mortgageProductId, mortgageProductInput, mortgageInput }) => {
      const mortgage = await updateMortgageProductHelper(
        mortgageId,
        mortgageProductId,
        mortgageProductInput,
        mortgageInput,
      )

      return mortgage
    },
  },
}

export default ProductResolver
