import { styled } from '@mui/material'

export const RedCode = styled('code')`
  padding: 10px;
  color: red;
  margin-top: 10px;
  display: block;
`
export const Code = styled('code')`
  white-space: pre;
  display: block;
  margin-bottom: 20px;
`

export const Container = styled('div')(
  ({ theme }) => `
  width: 100%;
  height: 100%;
  overflow: hidden;
  @media (min-width: ${theme.clientWrapper.large}) {
    padding: ${theme.spacing(16)};
  }
`,
)

export const CardContainer = styled('div')(
  ({ theme }) => `
  margin-left: auto;
  margin-right: auto;
  @media (min-width: ${theme.clientWrapper.large}) {
    max-width: 480px;
    min-width: 400px;
    width: 40%;
  }
`,
)

export const Card = styled('div')(
  ({ theme }) => `
  padding: ${theme.spacing(6)};
  background-color: white;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (min-width: ${theme.clientWrapper.large}) {
    box-shadow: ${theme.boxShadow};
  }
  @media (max-width: ${theme.clientWrapper.large}) {
    height: calc(100vh - 68px);
    justify-content: center;
  }
`,
)
