// Routes
export const dashboardRoute = '/dashboard'
export const login = '/login'
export const scheduleAppointment = '/schedule_appointment'
export const secureMessaging = '/messages'
export const profileRoute = '/profile/:id'
export const personalInfoRoute = '/profile/:id/personal'
export const contactInfoRoute = '/profile/:id/contacts'
export const employmentsInfoRoute = '/profile/:id/employment'

// Onboarding  routes
export const onboardingRoute = '/onboarding'
export const onboardingDetailsRoute = '/onboarding/details'
export const onboardingIddRoute = '/onboarding/idd'

// Credit reporting
export const creditOverviewRoute = '/credit/overview'
export const creditIddRoute = '/credit/disclosure'
export const identityChecksRoute = '/credit/id-checks'
export const eidvRoute = '/credit/id-checks/result'
export const kbaIddRoute = '/credit/security/terms-and-conditions'
export const kbaQuestionsRoute = '/credit/security/questions'

// Credit details
export const creditDetailsRoute = '/credit-details'

// Documents routes
export const documentsRoute = '/documents'
export const documentsModalPreview = '/documents/preview/:id'
export const documentsUploadRoute = '/document-upload/*'
export const documentsProofOfIdORoute = '/documents/id'
export const documentsProofOfAddressRoute = '/documents/address'
export const documentsProofOfIncomeRoute = '/documents/income'
export const documentsBankStatementRoute = '/documents/banking-statement'
export const documentsAdditionalRoute = '/documents/other'
export const documentsPreview = '/preview/:id'

// Helpers
export const emptyVal = '-'

// Requirements routes
export const requirementsRoute = '/requirements'
export const yourSituationRoute = `${requirementsRoute}/situation`
export const aboutPropertyRoute = `${requirementsRoute}/property`
export const preferencesRoute = `${requirementsRoute}/preferences`
export const conveyancerRoute = `${requirementsRoute}/conveyancer`
export const otherPropertiesRoute = `${requirementsRoute}/other-properties`
export const committedExpenditureRoute = `${requirementsRoute}/committed-expenditure`
export const discretionaryExpenditureRoute = `${requirementsRoute}/discretionary-expenditure`

// Protection
export const protectionAdviceRoute = `${requirementsRoute}/protection/advice`

// Cross sales
export const willsRoute = '/wills'
export const surveyingRoute = '/surveying'

// Property Data
export const propertiesRoute = '/properties'
export const propertyInfoRoute = `${propertiesRoute}/property-info`

// Ory Login
export const oryLogin = '/ory-login'
export const createPassword = '/settings'
export const oryVerification = '/verification'
export const oryEmailVerification = '/email_recovery'
export const settings = '/password'
export const phoneSelection = '/phone_selection'
export const oryRecovery = '/recovery'
export const noInvite = '/no_invite'

// Conveyancing
export const conveyancing = '/conveyancing/quote'
export const conveyancingFactFind = '/conveyancing/fact-find'
export const conveyancingFactFindProperty = `${conveyancingFactFind}/property`
export const conveyancingFactFindNewProperty = `${conveyancingFactFind}/new-property`
export const conveyancingFactFindPersonalInfo = `${conveyancingFactFind}/about`
export const conveyancingFactFindMortgage = `${conveyancingFactFind}/mortgage`
export const conveyancingFactFindCompleted = `${conveyancingFactFind}/completed`
// Checklist

export const checklistRoute = '/homebuyers-checklist'

// Go Cardless

export const paymentCompleteRoute = '/go-cardless-payment-return'
export const paymentFailedRoute = '/go-cardless-payment-rejected'

// Case dashboard
export const caseDashboardRoute = '/cases/:caseId/dashboard'

// Settings
export const settingsRoute = '/portal-settings'
export const creditSettings = '/portal-settings/credit'
export const dataPreferences = '/portal-settings/preferences'

//Tradesmen
export const tradesmenRoute = '/tradesmen'
