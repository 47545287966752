import { addManualFlagToCase } from '../api/flags'
import { Resolvers } from '../generated/resolvers'

const FlagResolver: Resolvers = {
  Mutation: {
    addManualFlagToCase: (_parent, { input }) => addManualFlagToCase(input?.case_id, input?.flag_type),
  },
}

export default FlagResolver
