import { getRolesByName } from '../api/role'
import { Resolvers } from '../generated/resolvers'

const RoleResolver: Resolvers = {
  Query: {
    getRolesByName: (_, { roleNames }) => getRolesByName(roleNames),
  },
}

export default RoleResolver
