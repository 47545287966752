import { CanBeAddedToLoan, MortgageProductFeeType } from '../../../generated/resolvers'

export type NonDeprecatedMortgageProductFeeType = Exclude<
  MortgageProductFeeType,
  MortgageProductFeeType.HomebuyersFee | MortgageProductFeeType.ChapsFee | MortgageProductFeeType.DisbursementFee
>
export const mapFeeTypeToCanBeAddedToLoan: { [key in NonDeprecatedMortgageProductFeeType]: CanBeAddedToLoan } = {
  [MortgageProductFeeType.AdministrationFee]: CanBeAddedToLoan.OptionToBeAdded,
  [MortgageProductFeeType.ApplicationFee]: CanBeAddedToLoan.PaySeparately,
  [MortgageProductFeeType.ArrangementFee]: CanBeAddedToLoan.OptionToBeAdded,
  [MortgageProductFeeType.AssessmentFee]: CanBeAddedToLoan.PaySeparately,
  [MortgageProductFeeType.BookingFee]: CanBeAddedToLoan.PaySeparately,
  [MortgageProductFeeType.BrokerFee]: CanBeAddedToLoan.OptionToBeAdded,
  [MortgageProductFeeType.ChapsTelegraphicTransferFee]: CanBeAddedToLoan.OptionToBeAdded,
  [MortgageProductFeeType.CompletionFee]: CanBeAddedToLoan.OptionToBeAdded,
  [MortgageProductFeeType.DeedsReleaseFee]: CanBeAddedToLoan.PaySeparately,
  [MortgageProductFeeType.LegalFee]: CanBeAddedToLoan.PaySeparately,
  [MortgageProductFeeType.EarlyRepaymentCharge]: CanBeAddedToLoan.PaySeparately,
  [MortgageProductFeeType.HigherLendingCharge]: CanBeAddedToLoan.PaySeparately,
  [MortgageProductFeeType.ValuationFee]: CanBeAddedToLoan.PaySeparately,
  [MortgageProductFeeType.InsuranceAdministration]: CanBeAddedToLoan.PaySeparately,
  [MortgageProductFeeType.LandRegistryFee]: CanBeAddedToLoan.PaySeparately,
  [MortgageProductFeeType.LenderConveyancingFee]: CanBeAddedToLoan.PaySeparately,
  [MortgageProductFeeType.MasterBrokerFee]: CanBeAddedToLoan.OptionToBeAdded,
  [MortgageProductFeeType.MortgageAccountFee]: CanBeAddedToLoan.OptionToBeAdded,
  [MortgageProductFeeType.OtherFee]: CanBeAddedToLoan.PaySeparately,
  [MortgageProductFeeType.PackagerFee]: CanBeAddedToLoan.OptionToBeAdded,
  [MortgageProductFeeType.ProductTransferFee]: CanBeAddedToLoan.PaySeparately,
  [MortgageProductFeeType.ReInspectionFee]: CanBeAddedToLoan.PaySeparately,
  [MortgageProductFeeType.RedemptionAdminFee]: CanBeAddedToLoan.PaySeparately,
  [MortgageProductFeeType.TitleDeedsAdminFee]: CanBeAddedToLoan.PaySeparately,
  [MortgageProductFeeType.InvalidFeeType]: CanBeAddedToLoan.InvalidCanBeAddedToLoan,
}
