/**
 * https://github.com/AlonMiz/retry-react-lazy
 */

import { LazyRouteFunction, RouteObject } from 'react-router-dom'

// TODO: Fix types
export function lazyRoute<T extends () => any>(importer: T) {
  // @ts-ignore Couldn't figure it out - LD
  const retryImport: T = async () => {
    try {
      return importer()
    } catch (error: unknown) {
      // retry 5 times with 1 second delay and backoff factor of 2 (2, 4, 8, 16, 32 seconds)
      for (let i = 0; i < 5; i++) {
        await new Promise((resolve) => setTimeout(resolve, 1000 * 2 ** i))
        // this assumes that the error message that the browser exception will contain this specific text.
        // if not, the url will not be able to parse and we'll get an error on that
        const urlOrPath = (error as Error).message.replace('Failed to fetch dynamically imported module: ', '').trim()

        let importString = ''

        // Absolute URL
        if (urlOrPath.startsWith('http')) {
          const url = new URL(urlOrPath)

          // add a timestamp to the url to force a reload the module
          url.searchParams.set('t', `${+new Date()}`)

          importString = url.href
        }
        // Relative URL
        else {
          importString = urlOrPath.replace(/[()]/g, '')

          importString = `${importString}${importString.includes('?') ? '&' : '?'}t=${+new Date()}`
        }

        try {
          return await import(/* @vite-ignore */ importString)
        } catch (e) {
          console.log(`retrying import: ${importString}`)
        }
      }

      throw error as Error
    }
  }

  return retryImport as LazyRouteFunction<RouteObject>
}
