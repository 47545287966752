import { round } from 'lodash'

import { Maybe } from '../../../generated/resolvers'

export const getAmount = ({
  amount,
  percentage,
  loanAmount,
}: {
  amount?: Maybe<number>
  percentage?: Maybe<number>
  loanAmount?: Maybe<number>
}) => {
  if (amount) {
    return amount
  }
  if (percentage && loanAmount) {
    return round((percentage / 100) * loanAmount, 2) // /100, to convert to pounds, as all calcs are done in pounds at this stage
  }
  return null
}
