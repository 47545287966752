import { useCallback, useState } from 'react'

export class WrappedSessionStorage {
  #observers: { [event: string]: ((value: any) => void)[] }
  #values: { [key: string]: string | null | undefined }

  constructor() {
    this.#observers = {}
    this.#values = Object.assign({}, window.sessionStorage)

    this.setItem = this.setItem.bind(this)
    this.getItem = this.getItem.bind(this)
    this.getAll = this.getAll.bind(this)
    this.removeItem = this.removeItem.bind(this)
    this.addEventListener = this.addEventListener.bind(this)
  }

  setItem(key: string, value: string) {
    this.#values[key] = value
    sessionStorage.setItem(key, value)
    this.#emit('change', { key, value })
  }

  removeItem(key: string) {
    delete this.#values[key]
    sessionStorage.removeItem(key)
    this.#emit('change', { key, value: null })
  }

  getItem(key: string) {
    return this.#values[key] || null
  }

  getAll() {
    return this.#values
  }

  addEventListener(event: string, listener: (value: any) => void) {
    if (!this.#observers[event]) this.#observers[event] = []

    if (!this.#observers[event].includes(listener)) {
      this.#observers[event].push(listener)
    }
  }

  #emit(event: string, value: any) {
    if (this.#observers[event]) {
      this.#observers[event].forEach((fn) => {
        fn(value)
      })
    }
  }
}

const StorageInstance = new WrappedSessionStorage()

const { setItem, getItem, removeItem } = StorageInstance

export const useWrappedSessionStorage = () => {
  const [values, setValues] = useState(StorageInstance.getAll())

  const handleStorageChange = useCallback(
    (pair: any) => {
      setValues({
        ...values,
        ...pair,
      })
    },
    [values],
  )

  StorageInstance.addEventListener('change', handleStorageChange)

  return { values, setItem, getItem, removeItem }
}
