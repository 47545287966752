import { useEffect, useMemo, useState } from 'react'
import { CaseContextProvider, ClientProvider, LoginErrorsProvider } from '@client-portal-contexts'
import { DemographicsContext, DemographicsResponse } from '@client-portal-contexts/DemographicsContext'
import { LandRegistryTitleContext, LandRegistryTitleResponse } from '@client-portal-contexts/LandRegistryTitleContext'
import { PropertyContext, PropertyResponse } from '@client-portal-contexts/PropertyContext'
import { CssBaseline, Theme } from '@mui/material'

import {
  Feature,
  FlagProvider,
  flags,
  FlagType,
  isNil,
  mergeConfigFlagsWithOrgMetadata,
  shareSessionStorage,
} from '@acre/utils'
import {
  CaseStatus,
  CaseVersion,
  ClientVerification,
  omitTypename,
  useGetCaseUserLazyQuery,
  useGetClientUserQuery,
  useGetClientVerificationsQuery,
  useGetOrganisationQuery,
  useGetPropertyCpQuery,
  useGetUserQuery,
} from '@acre/graphql'
import {
  FieldDisabledProvider,
  FullPageLoadingSpinner,
  MobileErrorBoundary,
  ThemeProvider,
  UserTracker,
} from '@acre/design-system'

import { areFieldsDisabled, renderDynamicTheme } from './App.helpers'
import ClientPortalRouter from './bootstrap/ClientPortalRouter'
import { useWrappedSessionStorage } from './pages/LoginNew/helpers/WrappedSessionStorage.ts'
import { loadData } from './pages/Properties/helpers/PropertyInfo.helpers'
import { scheduleAppointment } from './pages/shared/constants'
import theme from './theme/theme.mui'
import { inputGlobalStyles } from './index.styles'

const App = shareSessionStorage(() => {
  const [propertyState, setPropertyState] = useState<PropertyResponse>()
  const [demographicsState, setDemographicsState] = useState<DemographicsResponse>()
  const [landRegistryState, setLandRegistryState] = useState<LandRegistryTitleResponse>()

  const { loading: loadingClient, data } = useGetClientUserQuery({
    skip: window.location.pathname.endsWith(scheduleAppointment),
  })

  const { values, setItem } = useWrappedSessionStorage()
  const caseId = sessionStorage.getItem('case_id')
  const [loadCaseData, { data: caseData, loading: loadingCase }] = useGetCaseUserLazyQuery()

  useEffect(() => {
    if (caseId) {
      loadCaseData({ variables: { id: caseId! } })
    }
  }, [caseId, loadCaseData])

  const { data: propertyData } = useGetPropertyCpQuery({
    variables: {
      id: caseData?.caseUser.details.new_property_cp?.property_id!,
    },
    skip: isNil(caseData?.caseUser.details?.new_property_cp?.property_id),
    fetchPolicy: 'cache-first',
  })

  if (!propertyState?.property) {
    loadData(propertyData, setPropertyState, setDemographicsState, caseData?.caseUser?.id, setLandRegistryState)
  }

  const state = propertyState ?? {}

  const { data: clientVerifications, loading: verificationsLoading } = useGetClientVerificationsQuery({
    variables: {
      id: data?.clientUser?.id!,
      useClientApi: true,
      show_pass: true,
    },
    skip: !data?.clientUser?.id,
    fetchPolicy: 'network-only',
  })

  const clientContext = data?.clientUser && {
    ...omitTypename(data?.clientUser),
    verifications:
      !verificationsLoading &&
      clientVerifications &&
      clientVerifications.clientVerifications &&
      (omitTypename(clientVerifications.clientVerifications) as ClientVerification),
  }
  const { data: userData, loading: userDataLoading } = useGetUserQuery({
    variables: {
      id: caseData?.caseUser?.details.owner_user_id!,
      useClientApi: true,
    },
    skip: loadingCase || !caseData?.caseUser?.details?.owner_user_id,
  })

  const { data: organisationData, loading: orgDataLoading } = useGetOrganisationQuery({
    variables: { id: userData?.user?.organisation?.id!, useClientApi: true },
    skip: !userData?.user?.organisation?.id || userDataLoading,
    onCompleted: (data) => {
      const { primary_colour } = data.organisation?.client_portal_settings || {}
      const overriddenFlagsOrg = mergeConfigFlagsWithOrgMetadata(flags, data.organisation.metadata)
      const displayDashboard3 = overriddenFlagsOrg[FlagType.Feature][Feature.Dashboard3Cp]

      const primaryColour = displayDashboard3 ? theme.colours.portalOptions.gray : primary_colour

      return primaryColour && setItem('primary_colour', primaryColour)
    },
  })

  const currentOrganisationMetadata = organisationData ? organisationData.organisation.metadata : []
  const overriddenFlagsOrg = mergeConfigFlagsWithOrgMetadata(flags, currentOrganisationMetadata)
  const displayDashboard3 = overriddenFlagsOrg[FlagType.Feature][Feature.Dashboard3Cp]

  const selectedColour = useMemo(() => {
    if (displayDashboard3) {
      return theme.colours.portalOptions.gray
    } else if (values?.primary_colour) {
      return values.primary_colour
    } else if (organisationData?.organisation?.client_portal_settings?.primary_colour) {
      return organisationData?.organisation?.client_portal_settings?.primary_colour
    } else {
      return theme.colours.portalOptions.blue
    }
  }, [organisationData, values.primary_colour, displayDashboard3])

  const dynamicTheme = useMemo(() => renderDynamicTheme(selectedColour, theme), [selectedColour])

  if (loadingClient || loadingCase || orgDataLoading) {
    return (
      <ThemeProvider theme={dynamicTheme}>
        <CssBaseline>
          {inputGlobalStyles(dynamicTheme as Theme)}
          <FullPageLoadingSpinner />
        </CssBaseline>
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={dynamicTheme}>
      <CssBaseline>
        {inputGlobalStyles(dynamicTheme as Theme)}
        <MobileErrorBoundary>
          <FlagProvider value={overriddenFlagsOrg}>
            <FieldDisabledProvider value={areFieldsDisabled(caseData?.caseUser.details.status as CaseStatus)}>
              <CaseContextProvider value={caseData?.caseUser as CaseVersion}>
                <ClientProvider value={clientContext || null}>
                  <PropertyContext.Provider value={state}>
                    <DemographicsContext.Provider value={demographicsState}>
                      <LandRegistryTitleContext.Provider value={landRegistryState}>
                        <UserTracker user={data?.clientUser || null}>
                          <LoginErrorsProvider>
                            <ClientPortalRouter />
                          </LoginErrorsProvider>
                        </UserTracker>
                      </LandRegistryTitleContext.Provider>
                    </DemographicsContext.Provider>
                  </PropertyContext.Provider>
                </ClientProvider>
              </CaseContextProvider>
            </FieldDisabledProvider>
          </FlagProvider>
        </MobileErrorBoundary>
      </CssBaseline>
    </ThemeProvider>
  )
})

export default App
