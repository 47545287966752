import { ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { Box, BoxProps, ContainerProps } from '@mui/material'

import { UserContext } from '@acre/utils'

import useToolbarInfo from '../../hooks/useToolbarInfo'
import theme from '../../theme/theme.mui'
import CaseSidebarDrawer from '../CaseSidebarDrawer'
import { setParamsForDrawerOpenLocalStorage } from '../CaseSidebarDrawer/CaseSidebarDrawer.helpers'
import type { PageLayoutVariant } from './PageLayout.types'
import PageLayoutContainer from './PageLayoutContainer'
import { PageLayoutRoot } from './PageLayout.styles'

export interface PageLayoutProps extends Omit<BoxProps, 'maxWidth'>, Pick<ContainerProps, 'maxWidth'> {
  variant?: PageLayoutVariant
  containerProps?: ContainerProps
  warningChildren?: ReactNode
  sidebarChildren?: ReactNode
}

const PageLayout = (props: PageLayoutProps) => {
  const { shouldRenderGhost, height: toolbarHeight } = useToolbarInfo()
  const { variant, maxWidth, children, warningChildren, sidebarChildren, containerProps, ...boxProps } = props

  const [open, setOpen] = useState(false)
  const [shouldTransition, setShouldTransition] = useState(true)

  const toggleDrawerOpen = () => {
    setOpen(!open)
  }

  const user = useContext(UserContext)
  const { key } = setParamsForDrawerOpenLocalStorage(user)

  const sideBarOpenLocalStorage = localStorage.getItem(key)

  // Content should not transition on mount if the drawer is open
  useEffect(() => {
    if (sideBarOpenLocalStorage !== 'false') setShouldTransition(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  setTimeout(() => setShouldTransition(true), theme.transitions.duration.enteringScreen)

  const containerMaxWidth = useMemo(() => {
    if (variant === 'two-column' || variant === 'two-column-with-sidebar') {
      return 'lg'
    } else if (maxWidth) {
      return maxWidth
    } else {
      return false
    }
  }, [maxWidth, variant])

  return (
    <>
      <PageLayoutRoot
        component="main"
        variant={variant}
        rightDrawerOpen={open}
        shouldTransition={shouldTransition}
        {...boxProps}
        sx={{ marginTop: shouldRenderGhost ? 0 : theme.spacing(6) }}
      >
        <Box mt={shouldRenderGhost ? toolbarHeight : 0}>
          <Box mr={theme.spacing(-4)}>{warningChildren}</Box>
          <PageLayoutContainer
            variant={variant}
            rightDrawerOpen={open}
            disableGutters={containerProps?.disableGutters}
            maxWidth={containerMaxWidth}
          >
            <Box display="flex" flexDirection="column" width="100%">
              {children}
            </Box>
          </PageLayoutContainer>
        </Box>
      </PageLayoutRoot>
      {variant === 'two-column-with-sidebar' && (
        <CaseSidebarDrawer open={open} toggleDrawerOpen={toggleDrawerOpen}>
          {sidebarChildren}
        </CaseSidebarDrawer>
      )}
    </>
  )
}

export default PageLayout
