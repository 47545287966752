import DataLoader from 'dataloader'

import {
  CaseInput,
  CaseVersion,
  Mortgage,
  MortgageAndCase,
  MortgageInput,
  MortgageReason,
  PropertyInput,
  PropertyVersion,
  Scalars,
} from '../../generated/resolvers'
import {
  CdmGetMortgageRequest,
  CdmGetMortgageResponse,
  CdmGetPropertyRequest,
  CdmGetPropertyResponse,
} from '../../service/luther/model'
import { UUID } from '../../types'

export const addPropertyOnCase = async ({
  input,
  caseId,
  propertyType,
  CaseLoader,
  addProperty,
  updateCase,
}: {
  input: PropertyInput
  caseId: Scalars['ID']
  propertyType: Scalars['String']
  CaseLoader: DataLoader<string, CaseVersion | null>
  addProperty: (input: PropertyInput) => Promise<PropertyVersion | null>
  getProperty: (params: CdmGetPropertyRequest) => Promise<CdmGetPropertyResponse>
  updateProperty: (input: PropertyInput, id: string) => Promise<PropertyVersion | null>
  fetchMortgages?: (params: CdmGetMortgageRequest) => Promise<Mortgage[] | null>
  fetchMortgagesForCase?: (case_id: UUID) => Promise<CdmGetMortgageResponse | null>
  updateCase: (input: CaseInput, id: string) => Promise<CaseVersion | null>
  updateMortgage: (mortgage_id: UUID, input: MortgageInput) => Promise<Mortgage | null>
  addMortgageToCase?: (mortgage_id: string, case_id: string) => Promise<MortgageAndCase>
}) => {
  const caseEntity = await CaseLoader.load(caseId)

  if (!caseEntity) {
    return null
  }

  const { change_of_preference_reason, preference_mortgage_reason } = caseEntity.details

  const propertyInput = input

  // when adding target property on btl, house move or ftb case registered_owners_details should no be set
  // on all other cases registered_owners_details should be included
  if (
    (preference_mortgage_reason === MortgageReason.ReasonBtl ||
      preference_mortgage_reason === MortgageReason.ReasonHouseMove ||
      preference_mortgage_reason === MortgageReason.ReasonFtb) &&
    propertyType === 'preference_target_property'
  ) {
    propertyInput.registered_owners_details = undefined
  }

  const newProperty = await addProperty(propertyInput)

  const caseInput = {
    [propertyType]: newProperty?.id,
    change_of_preference_reason: change_of_preference_reason ? [...change_of_preference_reason] : [],
  }

  return await updateCase(caseInput, caseId)
}
