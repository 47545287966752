import React from 'react'

import Block from '../Block'
import PageLayout from '../PageLayout'
import { LoadingSpinner, Props } from './styles'

const CenteredLoadingSpinner = (props: Props) => (
  <Block small={12} flex>
    <LoadingSpinner {...props} />
  </Block>
)

const FullPageLoadingSpinner = (props: Props) => (
  <PageLayout component="main">
    <CenteredLoadingSpinner {...props} />
  </PageLayout>
)

export default LoadingSpinner
export { CenteredLoadingSpinner, FullPageLoadingSpinner }
