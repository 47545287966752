import { Maybe } from 'graphql/jsutils/Maybe'
import { cloneDeep, set } from 'lodash'

import { Metadata, Organisation, User, UserMetadata } from '@acre/graphql'

import { Flags, flags, Untracked } from './flags'
import type { FlagInput } from './lib'

// We configure some app-level flags in flags.ts, but may need to override these on
// a per-user basis. This only applies to two users currently. Our local user (the
// one used for development and CI), and the one we use for our user testing
export const mergeConfigFlagsWithUserMetadata = <F extends FlagInput>(flags: F, metadata: Maybe<UserMetadata[]>) => {
  // Filter any metadata that looks like a feature flag
  const userFlags = (metadata || []).filter(({ name }) => {
    return (name || '').includes('feature.') || (name || '').includes('automation.') || (name || '').includes('club.')
  })

  // Override any flags set at a user-level
  const flagsCopy = cloneDeep(flags)
  return userFlags.reduce((acc, flag) => {
    const { name, string_value, bool_value } = flag
    const enabled = string_value === 'true' || bool_value === true
    const disabled = string_value === 'false' || bool_value === false
    if (!name) return acc
    if (enabled) return set(acc, name, true)
    if (disabled) return set(acc, name, false)
    return acc
  }, flagsCopy)
}

// List of relevant flag prefixes and categories.(UI cards corelating to this )
// Extend this list when new flag categories or specific flags are introduced.
const RELEVANT_FLAG_IDENTIFIERS = ['feature.', 'automation.', 'untracked.', 'club']

// Filter relevant metadata flags
const filterRelevantFlags = (metadata: Metadata[]) => {
  return metadata.filter(({ name }) => {
    return (
      RELEVANT_FLAG_IDENTIFIERS.some((keyword) => (name || '').startsWith(keyword)) ||
      Object.values(Untracked).includes(name as Untracked)
    )
  })
}

export const mergeConfigFlagsWithOrgMetadata = (flags: Flags, metadata: Maybe<Metadata[]>): Flags => {
  // If metadata is null or undefined, return the original flags
  if (!metadata) {
    return flags
  }

  // Filter out metadata that are relevant to flag settings
  const orgFlags = filterRelevantFlags(metadata)

  // Deep clone the original flags to avoid mutation
  const flagsCopy = cloneDeep(flags)

  // Merge the org flags into the copy of the original flags
  return orgFlags.reduce((acc, { name, bool_value, string_value }) => {
    if (name && bool_value !== null) {
      const enabled = string_value === 'true' || bool_value === true
      const disabled = string_value === 'false' || bool_value === false
      // Check if the flag is one of the untracked flags(ie doesn't have prefix), and if so, place it under the 'untracked' category
      if (Object.values(Untracked).includes(name as Untracked)) {
        name = `untracked.${name}`
      }
      if (enabled) set(acc, name, true)
      if (disabled) set(acc, name, false)
    }
    return acc
  }, flagsCopy)
}

export const calculateFlags = (organisationData: Maybe<Organisation>, currentUser: Maybe<User>) => {
  const parentOrganisationMetadata = organisationData?.representative_of_org
    ? organisationData.representative_of_org.metadata
    : []
  const currentOrganisationMetadata = organisationData ? organisationData.metadata : []

  // Configure feature flags
  const { metadata: userMetadata } = currentUser || { metadata: [] }
  const overriddenFlagsParent = mergeConfigFlagsWithOrgMetadata(flags, parentOrganisationMetadata)
  const overriddenFlagsOrg = mergeConfigFlagsWithOrgMetadata(overriddenFlagsParent, currentOrganisationMetadata)
  const overriddenFlagsUser = mergeConfigFlagsWithUserMetadata(overriddenFlagsOrg, userMetadata)
  return overriddenFlagsUser
}
