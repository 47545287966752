import envConfig from '@acre/config'

import { CreateMandateResponse, GetNeedsMandateResponse, GetPaymentsResponse } from '../generated/resolvers'
import request from '../requesters/clientPortal'

export const getPayments = async () => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const ACCOUNTING_API_URL =
    useOathKeeper === 'true'
      ? `${envConfig.CLIENT_PORTAL_OATHKEEPER_API_URL}/accounting`
      : `${envConfig.CLIENT_PORTAL_API_URL}/accounting`
  const response = await request.get<GetPaymentsResponse>(`/go-cardless/awaited-payments`, {
    baseURL: ACCOUNTING_API_URL,
  })
  return response.data
}

export const getNeedsMandate = async () => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const ACCOUNTING_API_URL =
    useOathKeeper === 'true'
      ? `${envConfig.CLIENT_PORTAL_OATHKEEPER_API_URL}/accounting`
      : `${envConfig.CLIENT_PORTAL_API_URL}/accounting`
  const response = await request.get<GetNeedsMandateResponse>(`/go-cardless/need-mandate`, {
    baseURL: ACCOUNTING_API_URL,
  })
  return response.data
}

export const createMandate = async () => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const ACCOUNTING_API_URL =
    useOathKeeper === 'true'
      ? `${envConfig.CLIENT_PORTAL_OATHKEEPER_API_URL}/accounting`
      : `${envConfig.CLIENT_PORTAL_API_URL}/accounting`
  const response = await request.post<CreateMandateResponse>(`/go-cardless/create-mandate`, undefined, {
    baseURL: ACCOUNTING_API_URL,
  })
  return response.data
}
