import { addMonths, format } from 'date-fns'

/**
 * Gets the ERC Bands or Rate Tiers array and transforms the period_fixed_length_months for every item
 * in that array into period_fixed_length_months (so that it can be used for display)
 * but both are preserved (since we need the original value to be saved on the BE, for more accurate calcs down the line)
 * @param arr ERC Bands or Rate Tiers array
 * @param mortgageStartDate The date on which the mortgage should start
 */
export const addPeriodFixedEndDate = (
  arr?: ({ period_fixed_length_months?: number | null; period_fixed_end_date?: string | null } & object)[] | null,
  startDate?: string | null,
) => {
  const mortgageStartDate = startDate || format(new Date(), 'yyyy-MM-dd')

  return arr?.reduce(
    (acc, { period_fixed_length_months, period_fixed_end_date, ...rest }) => {
      const prevEndDate = acc.length > 0 ? acc[acc.length - 1].period_fixed_end_date : mortgageStartDate

      const currentEndDate = (() => {
        if (period_fixed_end_date) {
          return period_fixed_end_date
        }

        if (prevEndDate && period_fixed_length_months) {
          return format(addMonths(new Date(prevEndDate), period_fixed_length_months), 'yyyy-MM-dd')
        }

        return null
      })()

      return [
        ...acc,
        {
          period_fixed_length_months: null,
          ...rest,
          period_fixed_end_date: currentEndDate,
        },
      ]
    },
    [] as typeof arr,
  )
}
