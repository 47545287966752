import {
  BuilderRegistrationSchemeType,
  Occupant,
  OccupantRelationship,
  PropertyLocation,
  PropertyRoofType,
  PropertyTenure,
  PropertyType,
  PropertyUse,
  PropertyVersion,
  PropertyWallType,
  SharedOwnershipType,
  TenancyType,
  TenantType,
} from '../../generated/resolvers'
import { CdmOccupant, CdmProperty } from '../../service/luther'

export const formatPropertyOccupant = (occupants: CdmOccupant[]): Occupant[] => {
  return occupants.map((occupant) => ({
    name: occupant.name,
    is_minor: occupant.is_minor,
    date_of_birth: occupant.date_of_birth,
    tenant_type: occupant.tenant_type as unknown as TenantType,
    tenancy_type: occupant.tenancy_type as unknown as TenancyType,
    relationship: occupant.relationship as unknown as OccupantRelationship,
  }))
}

export const formatProperty = (property: CdmProperty): PropertyVersion => {
  return {
    ...property,
    id: String(property.property_id),
    details: {
      ...property.details,
      id: String(property.property_id),
      property_id: String(property.details?.property_id || property.property_id),
      address: property.details?.address,
      type: property.details?.property_type as unknown as PropertyType,
      price: property.details?.property_price,
      valuation: property.details?.property_valuation,
      monthly_management_expenses: property.details?.monthly_management_expenses,
      tenure: property.details?.property_tenure as unknown as PropertyTenure,
      property_type: property.details?.property_type as unknown as PropertyType,
      property_tenure: property.details?.property_tenure as unknown as PropertyTenure,
      attributes: {
        num_floors_in_block: property.details?.num_floors_in_block,
        num_flats_in_block: property.details?.num_flats_in_block,
        floor_of_flat: property.details?.floor_of_flat,
        years_left_on_lease: property.details?.years_left_on_lease,
        leasehold_ground_rent: property.details?.leasehold_ground_rent,
        property_converted_in_last_ten_years: property.details?.property_converted_in_last_ten_years,
        does_the_property_have_a_lift: property.details?.does_the_property_have_a_lift,
        is_property_over_retail: property.details?.is_property_over_retail,

        // @ts-ignore until generation of swagger schema works again
        number_of_kitchens: property.details?.number_of_kitchens,
        // @ts-ignore until generation of swagger schema works again
        number_of_reception_rooms: property.details?.number_of_reception_rooms,
        // @ts-ignore until generation of swagger schema works again
        has_annex: property.details?.has_annex,
        // @ts-ignore until generation of swagger schema works again
        property_parking: property.details?.property_parking,
        // @ts-ignore until generation of swagger schema works again
        solar_panels_on_property: property.details?.solar_panels_on_property,
        does_the_property_have_deck_access: property.details?.does_the_property_have_deck_access,
      },
      occupants: property.details?.occupants ? formatPropertyOccupant(property.details.occupants) : [],
      property_location: property.details?.property_location as unknown as PropertyLocation,
      builder_registration_scheme: property.details
        ?.builder_registration_scheme as unknown as BuilderRegistrationSchemeType,
      wall_type: property.details?.wall_type as unknown as PropertyWallType,
      roof_type: property.details?.roof_type as unknown as PropertyRoofType,
      property_use: property.details?.property_use as unknown as PropertyUse,
      shared_ownership_type: property.details?.shared_ownership_type as unknown as SharedOwnershipType,
    },
  }
}
