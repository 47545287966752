import DataLoader from 'dataloader'
import { GraphQLError } from 'graphql'

import {
  PermissionAssignment,
  PermissionAssignmentBulkInput,
  PermissionAssignmentInput,
  PermissionAssignmentResponse,
  PermissionResolution,
  PermissionResolutionInput,
  PermissionResponse,
} from '../generated/resolvers'
import request from '../requesters/default'

export const PermissionResolutionLoader = new DataLoader(async (resolutionRequests: PermissionResolutionInput[]) => {
  return await fetchPermissionResolution(resolutionRequests)
})

export const fetchPermissionResolution = async (input: PermissionResolutionInput[]) => {
  const { data } = await request.post<{ permission_resolution_response: PermissionResolution[] }>(
    '/resolve_permission',
    {
      permission_resolution_requests: input,
    },
  )

  return data.permission_resolution_response
}

export const fetchPermissions = async (params: {
  ids?: string | string[]
  names?: string | string[]
  show_allowlist?: boolean | null
}) => {
  const response = await request.get<PermissionResponse>('/permission', {
    params: {
      permission_ids: params.ids || [],
      permission_names: params.names || [],
      show_allowlist: params.show_allowlist || false,
    },
  })

  return response.data
}

const fetchPermissionAssignment = async (params: {
  filter_user_id?: string
  filter_group_id?: string
  page_size?: number
  bookmark?: string | null
}): Promise<PermissionAssignmentResponse> => {
  const defaultParams = {
    permission_assignment_details: true,
    show_disabled: true,
    page_size: params.page_size,
    bookmark: params.bookmark,
    ...params,
  }

  const response = await request.get<PermissionAssignmentResponse>('/permission_assignment', { params: defaultParams })

  return response.data
}

export const fetchPermissionAssignments = async (params: {
  filter_user_id?: string
  filter_group_id?: string
}): Promise<{ permission_assignments: PermissionAssignmentResponse['permission_assignments'] }> => {
  const pageSize = 50
  let allAssignments: PermissionAssignment[] = []
  let nextPage: string | undefined | null

  do {
    const response = await fetchPermissionAssignment({
      ...params,
      page_size: pageSize,
      bookmark: nextPage,
    })

    if (response.permission_assignments) {
      allAssignments = allAssignments.concat(response.permission_assignments)
    }
    nextPage = response?.next_page?.bookmark
  } while (nextPage)

  return { permission_assignments: allAssignments || [] }
}

export const updatePermissionAssignment = async (id: string, input: PermissionAssignmentInput) => {
  const response = await request.patch<{ permission_assignment: PermissionAssignment }>(
    `/permission_assignment/${id}`,
    input,
  )

  return { permission_assignments: [response.data.permission_assignment] }
}

export const createPermissionAssignment = async (
  input: PermissionAssignmentInput,
): Promise<PermissionAssignmentResponse> => {
  const response = await request.post<{ permission_assignment: PermissionAssignment }>(`/permission_assignment`, input)

  return { permission_assignments: [response.data.permission_assignment] }
}

export const createPermissionAssignmentBulk = async (
  input?: PermissionAssignmentBulkInput | null,
): Promise<PermissionAssignmentResponse> => {
  if (!input) {
    new GraphQLError('Request is missing an input', {
      extensions: {
        code: 'INPUT_NOT_FOUND',
        status: 400,
      },
    })
  }
  const response = await request.post<{ permission_assignments: PermissionAssignment[] }>(
    `/permission_assignment/bulk`,
    input,
  )

  return { permission_assignments: response.data.permission_assignments }
}

export const updatePermissionAssignmentBulk = async (
  input?: PermissionAssignmentBulkInput | null,
): Promise<PermissionAssignmentResponse> => {
  if (!input) {
    new GraphQLError('Request is missing an input', {
      extensions: {
        code: 'INPUT_NOT_FOUND',
        status: 400,
      },
    })
  }
  const response = await request.patch<{ permission_assignments: PermissionAssignment[] }>(
    `/permission_assignment/bulk`,
    input,
  )

  return { permission_assignments: response.data.permission_assignments }
}
