import { ClientRelationship, ClientVersion } from '../../generated/resolvers'
import { isNaturalClient } from '../clients'

export const getDirectors = (clients: ClientVersion[]) => {
  const nonNaturalClients = clients.filter((client) => !isNaturalClient(client.details)) as ClientVersion[]

  return nonNaturalClients.reduce((clientIds: string[], company) => {
    const relationships = company.details.relationships

    if (!relationships) return clientIds

    const directors = relationships
      .filter((relationship) =>
        [ClientRelationship.DirectorTrustee, ClientRelationship.DirectorTrusteeAndPsc, ClientRelationship.Psc].includes(
          relationship.relationship_type as ClientRelationship,
        ),
      )
      .map((director) => director.relationship_reference!)

    return [...clientIds, ...directors]
  }, [])
}
