import React, { ReactNode, useEffect, useState } from 'react'

import testHandle from '../../utils/testHandle'
import Icon, { IconTypes } from '../Icon'
import { AlertButton, CloseButton } from './Alert.styles'

export type AlertProps = {
  id: string
  label: string | ReactNode
  onClick?: () => void
  isSuccess?: boolean
}

const Alert = ({ label, onClick, id, isSuccess }: AlertProps) => {
  const [isVisible, setIsVisible] = useState(true)

  // Needed so that we can re-use the same alert component
  // at least until we feature out the alert 'queue' solution
  useEffect(() => {
    if (!isVisible) setIsVisible(true)
  }, [isVisible])

  const handleClick = () => {
    setIsVisible(false)
    onClick && onClick()
  }

  return isVisible ? (
    <AlertButton role="alert" data-testid={testHandle(id)} isSuccess={!!isSuccess}>
      {label}
      <CloseButton type="button" onClick={handleClick}>
        <Icon name={IconTypes.Close} />
      </CloseButton>
    </AlertButton>
  ) : null
}

export default Alert
