import { AddressSummary } from '../../generated/resolvers'

export type PWCAddressFindSuggestions = {
  id: string
  type: string
  summaryline: string
  locationsummary: string
  count: number
}

export const normaliseAddressFind = (results: PWCAddressFindSuggestions[]): AddressSummary[] => {
  const normalisedAddressList = results.map(({ id, count, summaryline, locationsummary }) => ({
    id,
    count,
    summary: summaryline,
    location: locationsummary,
  }))
  return normalisedAddressList
}
