import {
  GeneralInsuranceInput,
  GeneralInsuranceProvider,
  GetGeneralInsuranceProductsInput,
  GetGeneralInsuranceQuoteResponse,
  GetGeneralInsuranceQuotesResponse,
  InputMaybe,
} from '../generated/resolvers'
import request from '../requesters/default'
import giRequest from '../requesters/generalInsurance'
import {
  CdmAddGeneralInsuranceProductCaseRequest,
  CdmCreateGeneralInsuranceProductRequest,
} from '../service/luther/model'

export enum GiProvider {
  Uinsure = 'UINSURE',
  Paymentshield = 'PAYMENTSHIELD',
}

export type GiCheckAuthBody = {
  encrypted_username: string
  encrypted_password: string
  provider: GeneralInsuranceProvider
}

export const createGeneralInsuranceProduct = async (input: CdmCreateGeneralInsuranceProductRequest) => {
  const response = await request.post('/general_insurance_product', input)
  return response?.data
}

export const updateGeneralInsuranceProduct = async (id: string, input: CdmCreateGeneralInsuranceProductRequest) => {
  const response = await request.patch(`/general_insurance_product/${id}`, input)
  return response?.data
}

export const addGeneralInsuranceProductCase = async (input: CdmAddGeneralInsuranceProductCaseRequest) => {
  const response = await request.post(`/general_insurance_product/${input.general_insurance_product_id}/case`, input)
  return response?.data
}

export const removeGeneralInsuranceProduct = async (general_insurance_product_id: string, case_id: string) => {
  const response = await request.delete(`/general_insurance_product/${general_insurance_product_id}/case/${case_id}`)
  return response?.data
}

export const getGeneralInsuranceProduct = async (input: InputMaybe<GetGeneralInsuranceProductsInput>) => {
  const response = await request.get('/general_insurance_product', {
    params: input,
  })
  return response?.data
}

export const fetchQuotes = async (input: InputMaybe<GeneralInsuranceInput>) => {
  const response = await giRequest.post<GetGeneralInsuranceQuotesResponse>('/quotes', input)
  return response?.data
}

export const fetchQuote = async (case_id: string, quote_id: string) => {
  const response = await giRequest.get<GetGeneralInsuranceQuoteResponse>(`/quotes/${case_id}/${quote_id}`)
  return response?.data
}

export const checkAuthGi = async (body: GiCheckAuthBody): Promise<{ credentials_valid: boolean } | undefined> => {
  const response = await giRequest.post('/check_auth', body)
  return response?.data
}
