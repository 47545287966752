import { createContext } from 'react'

import { Property } from './PropertyContext'

export type DemographicsResponse = {
  searches?: {
    demographics: {
      [key: string]: DemographicsWrappedResponse
    }
  }
  property?: Property
}

export type DemographicsWrappedResponse = {
  title: string
  narrative: string
  table: {
    [key: string]: number
  }
  summary_table: {
    [key: string]: number
  }
}

export const DemographicsContext = createContext<DemographicsResponse | undefined>(undefined)
