import { CheckClientResponse, InputMaybe } from '../main'
import request from '../requesters/search'

export const checkClientExistsWithEmailOrPhone = async (
  client_id: string,
  phone_number: InputMaybe<string>,
  email_address: InputMaybe<string>,
): Promise<CheckClientResponse> => {
  const response = await request.get<CheckClientResponse>('/check_client', {
    validateStatus: () => true,
    params: {
      client_id: client_id,
      phone_number: phone_number,
      email_address: email_address,
    },
  })

  if (response.status === 400 || response.data.found === null) {
    return { found: false }
  }

  return response.data
}
