import React, { PropsWithChildren, useEffect, useMemo } from 'react'
import { createInstance, MatomoProvider, useMatomo } from '@jonkoops/matomo-tracker-react'
import { TrackEventParams } from '@jonkoops/matomo-tracker-react/es/types'
import { Location } from 'history'
import { HelmetData } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'

import AcreConfig from '@acre/config'

export const getInstance = (config: { [key: string]: string }, userId?: string) => {
  if (!config.MATOMO_BASE_URL) {
    return
  }
  return createInstance({
    urlBase: config.MATOMO_BASE_URL,
    siteId: parseInt(config.MATOMO_SITE_ID),
    userId: userId,
    disabled: false,
    heartBeat: {
      active: true,
      seconds: 10,
    },
    linkTracking: true,
    configurations: {
      setSecureCookie: true,
      setRequestMethod: 'POST',
    },
  })
}

export type UserTrackerProps = PropsWithChildren<{
  user?: { id?: string | null } | null
}>

const UserTracker = ({ user, children }: UserTrackerProps) => {
  // NB we can't just use `userProvider` here as we might be in a non broker-crm app
  const instance = useMemo(() => {
    return getInstance(AcreConfig, user?.id || '')
  }, [user])

  if (!instance) {
    return <>{children}</>
  }
  return <MatomoProvider value={instance}>{children}</MatomoProvider>
}

const guidRegex = /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/g

export const filterPageHref = (location: Location) => {
  return location.pathname.replace(guidRegex, '_guid_')
}

const generateDimensions = (location: Location) => {
  const guids = [] as Array<{
    id: number
    value: string
  }>
  for (const id of location.pathname.matchAll(guidRegex)) {
    guids.push({
      id: 1,
      value: id[0],
    })
  }
  return guids
}

export type CustomDimension = {
  id: string
  value: string | number
}

export const trackEventFiltered = (
  trackEvent: (params: TrackEventParams) => void | undefined,
  location?: Location,
  category?: string,
  action?: string,
  name?: string,
  value?: number,
) => {
  if (location) {
    const path = filterPageHref(location)
    trackEvent({
      href: path,
      category: category || 'unknown',
      action: action || 'unknown',
      name: name || undefined,
      value: value,
    })
  }
}

export const getFilteredPageTitle = ({ helmetData }: { helmetData: HelmetData }) => {
  const title = helmetData?.context.helmet?.title.toString()
  return title ? title.replace(/<.*?>/g, '').replace(/\(.*\)/g, '') : ''
}

export type PageTrackerProps = PropsWithChildren<{ helmetData: HelmetData }>

export const PageTracker = ({ children, helmetData }: PageTrackerProps) => {
  const location = useLocation() as Location
  const { trackPageView } = useMatomo()
  useEffect(() => {
    if (location) {
      const path = filterPageHref(location)
      const title = getFilteredPageTitle({ helmetData })
      trackPageView({
        href: path,
        documentTitle: title,
        customDimensions: generateDimensions(location),
      })
    }
  }, [helmetData, location, trackPageView])
  return <>{children}</>
}

export default UserTracker
