import { NewFee } from '../generated/resolvers'
import { CdmFee, CdmFeeFeeType } from '../service/luther'
import {
  convertPenceToPounds,
  convertPercentageToLutherFormat,
  convertPoundsToPence,
  convertToPercentageValue,
} from '../utils/formatNumber'

const isFeeFixed = (fee_type: CdmFeeFeeType) =>
  Boolean(
    fee_type === 'LEGAL_COMPLETION_FIXED' ||
      fee_type === 'LEGAL_EXCHANGE_FIXED' ||
      fee_type === 'OFFER_FIXED' ||
      fee_type === 'PREADVICE_FIXED' ||
      fee_type === 'PREAPPLICATION_FIXED' ||
      fee_type === 'PRERECOMMENDATION_FIXED',
  )

export const formatFeeAsLuther = ({ fee_type, value }: NewFee) => {
  let valueFormatted = value

  // If it's a fixed amount, format it to the frontend standard of £1 = 100
  if (isFeeFixed(fee_type)) {
    valueFormatted = parseFloat(convertPenceToPounds(value) || '0').toFixed(2)
  } else {
    valueFormatted = convertPercentageToLutherFormat(value)
  }

  return {
    fee_type,
    value: valueFormatted,
  }
}

export const formatFeeAsAcre = ({ fee_type, value }: CdmFee) => {
  let valueFormatted = value

  // If it's a fixed amount, format it to the frontend standard of 100 = £1
  if (fee_type && isFeeFixed(fee_type)) {
    valueFormatted = convertPoundsToPence(value) as string
  } else {
    valueFormatted = convertToPercentageValue(value)
  }

  return {
    fee_type,
    value: valueFormatted,
  }
}
