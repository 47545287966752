import DataLoader from 'dataloader'
import { v4 as generateUniqueId } from 'uuid'

import { MortgageAndCase, MortgageProductActualInput } from '../generated/resolvers'
import request from '../requesters/clientPortal'
import oathKeeper from '../requesters/oathKeeper'
import { CdmCreateAcreMortgageProductResponse, CdmGetAcreMortgageProductResponse } from '../service/luther/model'

export const createMortgageProductCP = async (input: MortgageProductActualInput) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.post<CdmCreateAcreMortgageProductResponse>(
    '/mortgage_product',
    // TODO FRON-955 Product code is required by Luther. Generating a
    // unique ID is a temporary workaround until this issue is resolved
    {
      product_details: {
        ...input,
        product_code: generateUniqueId(),
      },
    },
  )
  return response.data
}

export const deleteMortgageProductCP = async (mortgageId: string, productId: string) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.delete<MortgageAndCase>(`/mortgage/${mortgageId}/product/${productId}`)
  return response.data
}

export const MortgageProductLoaderCp = new DataLoader((ids: string[]) =>
  Promise.all(ids.map((id) => fetchMortgageProductByIdCp(id))),
)

// This is used for mortgage products that have either been created
// by the user (existing mortgages, product transfers, etc) or sourced
// mortgage products that have been linked to a case via
// an instantiated mortgage
export const fetchMortgageProductByIdCp = async (productId: string) => {
  const useOathKeeper = sessionStorage.getItem('useOathKeeper')

  const requester = useOathKeeper === 'true' ? oathKeeper : request

  const response = await requester.get<CdmGetAcreMortgageProductResponse>(`/mortgage_product/${productId}`)
  return response.data
}
