import { getProperty } from '../api/property'
import { Case, CaseStatus, TransitionPropertyVersion } from '../generated/resolvers'
import { CdmGetPropertyRequest } from '../service/luther/model'
import { formatProperty } from '../utils/schemaMapping/property'
import { fetchVersionedCaseChildEntities } from '../utils/versionedCaseChildEntities'

export const fetchPropertiesForCase = async ({
  caseDetails,
  excludeIds,
  queryParams,
}: {
  caseDetails: Case
  excludeIds?: string[]
  queryParams?: CdmGetPropertyRequest
}) => {
  if (!caseDetails) {
    return []
  }

  const properties =
    (await fetchVersionedCaseChildEntities('property', {
      status: caseDetails.status as CaseStatus,
      transitionVersions: caseDetails.transition_property_versions as TransitionPropertyVersion[],
    })) || []

  const completedPropertyIds = properties.map((property) => property.property_id)

  if (
    caseDetails.preference_target_property &&
    !completedPropertyIds?.includes(caseDetails.preference_target_property) &&
    !excludeIds?.includes(caseDetails.preference_target_property)
  ) {
    const propertyResponse = await getProperty({
      ...queryParams,
      property_ids: [caseDetails.preference_target_property],
    })

    if (propertyResponse.properties?.length) {
      properties.push(formatProperty(propertyResponse.properties[0]))
    }
  }

  if (
    caseDetails.preference_related_property_sale &&
    !completedPropertyIds?.includes(caseDetails.preference_related_property_sale) &&
    !excludeIds?.includes(caseDetails.preference_related_property_sale)
  ) {
    const propertyResponse = await getProperty({
      ...queryParams,
      property_ids: [caseDetails.preference_related_property_sale],
    })

    if (propertyResponse.properties?.length) {
      properties.push(formatProperty(propertyResponse.properties[0]))
    }
  }

  return properties
}
